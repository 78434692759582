import React from "react"
import PropTypes from "prop-types"

const TriangleIcon = props => {
  const { width, textColor, iconClasses } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="100%"
      viewBox="0 0 61.7119 120.6392"
      className={iconClasses}
    >
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <polygon
            points="60.997 60.32 0.5 1.188 0.5 119.451 60.997 60.32"
            className={`fill-current ${textColor}`}
            strokeMiterlimit="10"
          />
        </g>
      </g>
    </svg>
  )
}

TriangleIcon.propTypes = {
  width: PropTypes.string,
  textColor: PropTypes.string, // Alters logo text and stroke color (must be set to `text-{color}`
  iconClasses: PropTypes.string,
}

export default TriangleIcon
