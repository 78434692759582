import React, { useState } from "react"
import DataProvider from "../../utils/DataProvider"
import { DateTime } from "luxon"
import { HorizontalSpinner } from "../ui/spinner"
import NavigationUtil from "../../utils/navigationUtil"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"

const Weather = props => {
  const queryVariables = {}
  return (
    <div>
      <span className="tw-sr-only">Today's weather:</span>
      <DataProvider queryName="weatherfeed">
        <GetWeatherFeedItems {...props} queryVariables={queryVariables} />
      </DataProvider>
    </div>
  )
}

const GetWeatherFeedItems = props => {
  const [forecastView, setForecastView] = useState(false)
  const { error, loading, data, mobileNavOpen } = props

  if (!loading && !error) {
    const weatherFeedItems = Object.entries(data.result.data)
    let weatherFeedItemsArray = []

    for (let index = 0; index < weatherFeedItems.length; index++) {
      weatherFeedItemsArray[weatherFeedItems[index][0]] =
        weatherFeedItems[index][1]
    }

    const beginForecastWindow = DateTime.fromISO(
      weatherFeedItemsArray.created
    ).toISODate()
    const endForecastWindow = DateTime.fromISO(weatherFeedItemsArray.created)
      .plus({ days: 3 })
      .toISODate()

    const forecastContent = weatherFeedItemsArray.forecast
      .filter(forecastPeriod => {
        const forecastDate = DateTime.fromISO(
          forecastPeriod.startTime
        ).toISODate()
        if (
          forecastDate >= beginForecastWindow &&
          forecastDate <= endForecastWindow
        ) {
          return true
        } else {
          return false
        }
      })
      .reduce((result, value, index, array) => {
        let key = value["friendlyDate"]
        if (!result[key]) {
          result[key] = []
        }
        result[key].push(value)
        return result
      }, {})

    return (
      <div id="weather-status">
        <button
          id="weather-status-toggle"
          data-type="toggle"
          data-context="weather forecast"
          data-action={forecastView ? "collapse" : "expand"}
          data-label="Weather Forecast"
          type="button"
          className={
            "group relative -ml-1 rounded p-1 text-base transition  " +
            (forecastView
              ? "shadow-inner bg-primary-light bg-opacity-20 hover:bg-opacity-20"
              : "bg-transparent hover:bg-primary-light hover:bg-primary-light hover:bg-opacity-30")
          }
          onClick={() => setForecastView(!forecastView)}
        >
          <span className="pointer-events-none flex items-center justify-between gap-2">
            <span className="text-sm">
              {weatherFeedItemsArray.current.temp}
              {`\u2109`}
            </span>
            <span>
              <i
                className={
                  "weather-icon forecastfont mr-1.5 h-4 w-4 text-xl leading-none " +
                  `weather-${weatherFeedItemsArray.current.weatherImage}`
                }
                aria-hidden="true"
              ></i>
            </span>
            <span
              className={
                "text-white transition group-hover:text-gray-200 " +
                (forecastView && "text-gray-300")
              }
            >
              <FontAwesomeIcon
                icon={faCaretDown}
                className="pointer-events-none"
              />
            </span>
          </span>
        </button>
        <GetForecastItems
          forecastData={forecastContent}
          currentDate={beginForecastWindow}
          forecastView={forecastView}
          mobileNavOpen={mobileNavOpen}
        />
        {forecastView === true ? (
          <NavigationUtil
            closeAction={() => {
              setForecastView(false)
            }}
            menuSelector="#weather-status-body"
            excludeElement="#weather-status-toggle"
          />
        ) : null}
      </div>
    )
  } else {
    return (
      <div className="mt-2">
        <HorizontalSpinner width="w-2" height="h-2" color="bg-primary-light" />
      </div>
    )
  }
}

const GetForecastItems = props => {
  const { forecastData, currentDate, forecastView, mobileNavOpen } = props
  const forecastVisibility = !forecastView ? "tw-sr-only" : ""
  const forecastItems = Object.entries(forecastData).map(([key, value], i) => {
    const printedDate =
      key === currentDate
        ? "Today"
        : DateTime.fromISO(key).toLocaleString({
            month: "short",
            day: "numeric",
          })
    const icon = (
      <i
        className={
          "weather-icon forecastfont mx-auto h-6 w-6 text-3xl leading-none " +
          `weather-${value[0].iconLinks[0]}`
        }
        aria-hidden="true"
      >
        <span className="tw-sr-only">{value[0].weather}</span>
      </i>
    )
    const availableTemps = value.map((element, i) => {
      const isHigh = element.tempLabel.toLowerCase() === "high"
      const isToday = key === currentDate

      return (
        <span key={i}>
          <span className="tw-sr-only">{element.tempLabel}:</span>
          <span
            className={
              isHigh
                ? "text-sm font-bold"
                : "text-xs text-gray-600 dark:text-gray-200"
            }
          >
            {isToday ? (isHigh ? "H:" : "L:") : ""}
            {element.temp}
            {`\u2109`}
          </span>
        </span>
      )
    })
    return key !== currentDate ? (
      <div className="flex w-1/3 flex-col gap-3 pt-1 pb-2 text-center" key={i}>
        <span className="flex-1 text-base font-bold">{printedDate}</span>
        <span className="flex flex-1 justify-center">{icon}</span>
        <span className="center-items flex flex-1 flex-col">
          {availableTemps}
        </span>
      </div>
    ) : (
      <div key={i} className="flex flex-col">
        <div className="dark:text-white-100 flex justify-center rounded-t-md bg-gray-100 py-0.5 dark:bg-gray-700">
          <span className="text-xs font-semibold">Today's Forecast</span>
        </div>
        <div className="flex items-center justify-between gap-3 px-3 py-2">
          <span className="text-left text-xs line-clamp-2">
            {value[0].weather}
          </span>
          <span className="text-lg">{icon}</span>
          <span className="flex flex-col items-center">{availableTemps}</span>
        </div>
      </div>
    )
  })
  return (
    <div
      id="weather-status-body"
      className={
        "absolute z-40 mx-auto mt-1.5 h-auto w-[14.5rem] rounded-md bg-white text-xs text-black shadow-md dark:bg-gray-600 dark:text-gray-100 md:right-1.5 " +
        forecastVisibility +
        (mobileNavOpen ? " md-max:left-2" : "")
      }
    >
      <div>{forecastItems[0]}</div>
      <div className="flex h-full justify-evenly divide-x divide-gray-200 border-t border-gray-200 dark:divide-gray-400 dark:border-gray-400">
        {forecastItems.slice(1, forecastItems.length)}
      </div>
    </div>
  )
}

Weather.propTypes = {}

export default Weather
