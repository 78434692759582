import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import ChevronToggle from "../../ui/chevron-toggle"

const MoreMenu = ({ navbarCollapsed }) => {
  const [moreMenuToggle, setMoreMenuToggle] = useState(true)
  const moreVisibility = !moreMenuToggle ? "tw-sr-only" : ""

  const data = useStaticQuery(graphql`
    query MoreMenuItems {
      allMenuItems(
        filter: { menu_name: { eq: "more" }, enabled: { eq: true } }
      ) {
        edges {
          node {
            id
            url
            title
            description
            options {
              external
              attributes {
                target
              }
            }
          }
        }
      }
    }
  `)
  if (data.allMenuItems.edges.length) {
    return (
      <div
        className={`my-3 px-3.5 ${navbarCollapsed ? "md:hidden" : ""}`}
        id="more-menu"
      >
        <nav aria-labelledby="more-heading">
          <h2
            id="more-heading"
            className="mb-1 flex items-center py-1 text-sm font-bold uppercase tracking-wider text-gray-600 dark:text-gray-300"
          >
            <button
              aria-expanded={moreMenuToggle}
              data-type="toggle"
              data-context="more menu"
              data-action={moreMenuToggle ? "collapse" : "expand"}
              data-label="More Links"
              className="text-sm font-bold uppercase tracking-wider text-gray-600 dark:text-gray-300"
              type="button"
              onClick={() => setMoreMenuToggle(!moreMenuToggle)}
            >
              <span className="pointer-events-none flex items-center">
                More
                <span className="tw-sr-only"> Links</span>
                <ChevronToggle
                  chevronClasses={[
                    "inline-block",
                    "ml-1",
                    "text-gray-500",
                    "dark:text-gray-300",
                  ]}
                  toggle={moreMenuToggle}
                />
              </span>
            </button>
          </h2>
          <ul className={`pl-1 text-sm ${moreVisibility}`}>
            {data.allMenuItems.edges.map(({ node }, index) => {
              return (
                <li key={index} className="hover:underline">
                  {node.options.external === true ? (
                    <a
                      href={node.url}
                      target={
                        node.options.attributes.target !== null
                          ? node.options.attributes.target
                          : undefined
                      }
                      rel={
                        node.options.attributes.target === "_blank"
                          ? "noreferrer"
                          : undefined
                      }
                      title={
                        node.description !== null ? node.description : undefined
                      }
                    >
                      {node.title}
                    </a>
                  ) : (
                    <Link to={node.url}>{node.title}</Link>
                  )}
                </li>
              )
            })}
          </ul>
        </nav>
      </div>
    )
  } else {
    return null
  }
}

export default MoreMenu
