import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

const ToggleCollapseBtn = props => {
  const { toggle, handleClick } = props
  return (
    <button
      className={`fixed top-[35%] z-[11] px-[7px] hover:shadow-lg hover:scale-125 rounded-full text-primary transition bg-primary text-white dark:text-white md-max:hidden ${
        toggle ? "left-[4.2rem]" : "left-[15.25rem]"
      }`}
      onClick={() => handleClick()}
      title={`${toggle ? "Expand" : "Collapse"} navigation`}
      data-context="navbar"
      data-type="toggle"
      data-action={toggle ? "expand" : "collapse"}
      data-label="Toggle Navigation Collapse"
    >
      <span className="tw-sr-only">
        {toggle ? "Expand" : "Collapse"} navigation
      </span>
      <span
        className={`inline-block text-sm transition-transform ${
          !toggle ? "mr-0.5 rotate-180" : "ml-[0.135rem]"
        }`}
      >
        <FontAwesomeIcon
          icon={faChevronRight}
          size="sm"
          className={`translate-x-[-1px] ${toggle ? "" : "translate-y-[1px]"}`}
        />
      </span>
    </button>
  )
}

export default ToggleCollapseBtn
