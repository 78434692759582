import * as React from "react"
import ScrollspyNav from "react-scrollspy-nav"
import { titleTemplate } from "../page-layout/header/title-bar"

let spyCurrent = null // The current element being observed
const spyClassName = "scroll-active"
let defaultTitle // The default title to be displayed

let spyActive = target => {
  document.querySelectorAll(".section-link").forEach(link => {
    link.parentElement.classList.remove(
      "bg-amber-400",
      "text-gray-800",
      "hover:!bg-amber-300",
      "hover:bg-opacity-90"
      // "dark:hover:text-gray-100"
    )
  })
  //hide all jump menu checkmarks
  document.querySelectorAll(".jump-menu-link > span").forEach(link => {
    link.classList.add("hidden")
  })
  //show jump menu checkmark for current section
  document
    .querySelectorAll(
      'a.jump-menu-link[href="' + target.attributes.href.value + '"] > span'
    )
    .forEach(link => {
      link.classList.remove("hidden")
    })
  target.parentElement.classList.add(
    "bg-amber-400",
    "text-gray-800",
    "hover:!bg-amber-300",
    "hover:bg-opacity-90"
    // "dark:hover:text-gray-100"
  )
  document.querySelectorAll(".title-bar").forEach(title => {
    title.innerHTML = titleTemplate(target.innerText)
  })
}

let spyInactive = () => {
  document.querySelectorAll(".section-link").forEach(link => {
    link.parentElement.classList.remove(
      "bg-gray-600",
      "text-white",
      "hover:bg-gray-500",
      "hover:bg-opacity-90",
      "dark:hover:text-gray-700"
    )
  })
  //hide all jump menu checkmarks
  document.querySelectorAll(".jump-menu-link > span").forEach(link => {
    link.classList.add("hidden")
  })
  document.querySelectorAll(".title-bar").forEach(title => {
    title.innerHTML = defaultTitle
  })
}

const spyObserver =
  typeof window !== "undefined"
    ? new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (mutation.attributeName === "class") {
            const { target } = mutation
            const currentClassState = target.classList.contains(spyClassName)
            if (currentClassState && spyCurrent !== target.href) {
              spyCurrent = target.href
              spyActive(target)
            }
            if (
              spyCurrent !== null &&
              !document.querySelectorAll(`.${spyClassName}`).length
            ) {
              spyCurrent = null
              spyInactive()
            }
          }
        })
      })
    : {
        observe: () => {},
      }

export const ScrollSpyObserver = ({
  children,
  spyHref,
  activeCallback,
  inActiveCallback,
}) => {
  spyActive = activeCallback || spyActive
  spyInactive = inActiveCallback || spyInactive
  React.useEffect(() => {
    const link = document.querySelector('a[href="' + spyHref + '"]')
    spyObserver.observe(link, {
      attributes: true,
      attributeFilter: ["class"],
    })
  }, [])
  return children
}

export const ScrollSpy = ({
  children,
  scrollTargetIds,
  wrapperClassName,
  criticalAnnouncementOffset,
}) => {
  const [scrollOffset, setScrollOffset] = React.useState(
    -Math.abs(criticalAnnouncementOffset + 75)
  )
  const [useWindow, setUseWindow] = React.useState(false)
  React.useEffect(() => {
    defaultTitle = document.querySelector(".title-bar").innerHTML
  }, [])
  React.useEffect(() => {
    setUseWindow(typeof window !== "undefined")
  })
  React.useEffect(() => {
    setScrollOffset(-Math.abs(criticalAnnouncementOffset + 75))
  }, [criticalAnnouncementOffset])
  return (
    <>
      {useWindow ? (
        <ScrollspyNav
          key={scrollOffset}
          scrollTargetIds={scrollTargetIds}
          offset={scrollOffset}
          activeNavClass={spyClassName}
          scrollDuration="10"
        >
          <ul className={wrapperClassName}>{children}</ul>
        </ScrollspyNav>
      ) : (
        <ul className={wrapperClassName}>{children}</ul>
      )}
    </>
  )
}
