import React from "react"
import PropTypes from "prop-types"

const HiddenSection = ({ nodeData }) => {
  return (
    <section
      id={nodeData.title.replace(/\s+/g, "-")}
      className="hidden"
      hidden
    />
  )
}

HiddenSection.propTypes = {
  nodeData: PropTypes.object,
}

export default HiddenSection
