import React from "react"
import PropTypes from "prop-types"

const InterfolioIcon = props => {
  const { width, iconClasses } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="100%"
      className={iconClasses}
      viewBox="0 0 113.0811 113.2813"
    >
      <g id="a2476838-bcab-4586-8ffd-e4f06d7ef5da" data-name="Layer 2">
        <g id="b40bff2e-0a06-4b3b-a584-9660d63970f1" data-name="Layer 1">
          <g id="bef99b2c-e5e5-4004-929c-64a90fcef673" data-name="d93c2ff">
            <path
              d="M45.6141,1.0414A56.4618,56.4618,0,1,1,61.073,112.7092c-2.579.8414-5.3123.5277-7.9759.453-.4232-.6572-1.2746-.4281-1.9168-.5526A56.4271,56.4271,0,0,1,45.6141,1.0414m7.2291,15.5734a10.9568,10.9568,0,1,0,11.0776,2.41,11.0035,11.0035,0,0,0-11.0776-2.41M37.28,51.61c1.4538-.6273,3.0768-1.5086,4.6451-.6622,3.0918,1.4239,3.983,5.148,4.3166,8.23.3087,6.1139.04,12.2477.1643,18.3666a16.9207,16.9207,0,0,0,2.3,7.4979c4.207,6.8458,12.4567,10.4653,20.2932,10.5947-.06-11.3315,0-22.6631-.03-34a16.9046,16.9046,0,0,0-5.96-12.8451A19.0757,19.0757,0,0,0,47.67,45.3172,18.8781,18.8781,0,0,0,37.28,51.61Z"
              fill="#3d93c2"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

InterfolioIcon.propTypes = {
  width: PropTypes.string,
  iconClasses: PropTypes.string,
}

export default InterfolioIcon
