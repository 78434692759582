import * as React from "react"
import JumpMenu from "./jump-menu"
import { Settings } from "../nav/settings"
import { UserContext } from "../../utils/UserContext"
import numHiddenSections from "../../utils/numHiddenSections"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSort, faEyeSlash } from "@fortawesome/free-solid-svg-icons"

// Standard JS template for use with non-React dom updates
export const titleTemplate = title => {
  return `<div class="text-xl md:text-3xl font-bold">${title}</div>`
}

export const TitleBar = ({
  showAnnouncementsLink,
  mobileSearchOpen,
  midDesktopSearch,
  title = "Welcome!",
  subtitle = "The Dash is your source for news and information about campus life at Swarthmore College.",
}) => {
  const [jumpMenuState, jumpMenuDispatch] = React.useState("hidden")
  const [userState, userStateUpdate] = React.useContext(UserContext)
  return (
    <>
      <div
        // id="title-bar"
        className={
          "title-bar hidden shrink md:block md:grow " +
          (midDesktopSearch ? "md:hidden lg:block " : "")
        }
      >
        <div dangerouslySetInnerHTML={{ __html: titleTemplate(title) }}></div>
        <div className="hidden text-sm font-semibold text-gray-500 dark:text-gray-200 lg:block">
          {subtitle}
        </div>
      </div>
      <div className={"md:hidden " + (mobileSearchOpen ? "hidden" : "")}>
        <button
          id="jump-menu-toggle"
          className={
            "-ml-2 flex items-center gap-3 rounded-md px-2 " +
            (jumpMenuState === "show"
              ? "bg-gray-300 px-2 shadow-inner dark:bg-gray-600"
              : "hover:bg-gray-200 hover:px-2 hover:shadow-inner dark:hover:bg-gray-700")
          }
          onClick={() => {
            jumpMenuDispatch(jumpMenuState === "hidden" ? "show" : "hidden")
          }}
        >
          <div className="title-bar">
            <div
              dangerouslySetInnerHTML={{ __html: titleTemplate(title) }}
            ></div>
          </div>
          <FontAwesomeIcon icon={faSort} />
          <span className="tw-sr-only">Jump to Section</span>
        </button>
        <JumpMenu
          showAnnouncementsLink={showAnnouncementsLink}
          jumpMenuDispatch={jumpMenuDispatch}
          jumpMenuState={jumpMenuState}
        />
      </div>
      {numHiddenSections() > 0 ? (
        <div
          className={
            "ml-auto -mr-2 flex items-center text-sm text-black dark:text-white dark:hover:text-black md:hidden " +
            (mobileSearchOpen ? "hidden" : "")
          }
          title="View hidden sections"
        >
          <Settings editAccess={userState?.editAccess || []} icon={faEyeSlash}>
            <span className="mr-1">{numHiddenSections()}</span>
          </Settings>
        </div>
      ) : null}
    </>
  )
}
