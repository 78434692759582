import * as React from "react"
// Components
import PillToggle from "../../page-layout/ui/pill-toggle"
// Utils
import filterChange from "../../utils/filterChange"

const EventsFilters = props => {
  const {
    filterSettings,
    setFilterSettings,
    selectOptions,
    filterVisibility,
    setSliceEnd,
    defaultNumEventsToShow,
  } = props
  const rangeOptions = ["Today", "Week", "Next 30 Days"]
  const handleFilterChange = ({ target }) => {
    const filterType = target.dataset.filter
    let newValue
    switch (filterType) {
      case "range":
        newValue = target.dataset.value
        break
      case "category":
        newValue = target.value
        break
      case "allday":
        newValue = target.checked
        break
      default:
        break
    }
    setFilterSettings(prevState => ({
      ...prevState,
      [filterType]: newValue,
    }))
    filterChange(target)
    setSliceEnd(defaultNumEventsToShow)
  }

  const rangePills = rangeOptions.map((element, i) => {
    return (
      <PillToggle
        key={i}
        isActive={element === filterSettings.range}
        onClick={handleFilterChange}
        additionalAttr={{
          "data-filter": "range",
          "data-value": element,
          "data-type": "filter",
          "data-context": "events filters",
          "data-action": "select range",
          type: "button",
        }}
      >
        {element}
      </PillToggle>
    )
  })

  let optionMarkup = selectOptions.map((option, i) => (
    <option key={i} value={option}>
      {option}
    </option>
  ))
  if (
    !selectOptions.includes(filterSettings.category) &&
    filterSettings.category !== "All"
  ) {
    optionMarkup.push(
      <option
        key={optionMarkup.length}
        value={filterSettings.category}
        disabled
      >
        {filterSettings.category} - no results for current filters
      </option>
    )
  }
  optionMarkup = optionMarkup.sort((a, b) => {
    const titleA = a.props.value
    const titleB = b.props.value
    return titleA.localeCompare(titleB)
  })

  const categorySelect = (
    <div>
      <label htmlFor="events-type" className="tw-sr-only">
        Event Type
      </label>
      <select
        id="events-type"
        data-type="filter"
        data-context="events filters"
        data-action="select category"
        className="filter-select"
        data-filter="category"
        onChange={handleFilterChange}
        disabled={selectOptions.length ? false : true}
        value={filterSettings.category}
      >
        <option value="All">All</option>
        {optionMarkup}
      </select>
    </div>
  )

  const alldayToggle = (
    <div className="filter-toggle">
      <label htmlFor="events-all-day" className="filter-toggle--label">
        <div className="filter-toggle--label-text">All Day Events</div>
        <div className="filter-toggle--input-wrapper">
          <input
            id="events-all-day"
            data-type="filter"
            data-context="events filters"
            data-action={`${
              filterSettings.allday ? "hide" : "show"
            } all-day events`}
            type="checkbox"
            className="filter-toggle--input"
            data-filter="allday"
            onChange={handleFilterChange}
            checked={filterSettings.allday}
          />
          <div className="filter-toggle--background"></div>
          <div className="filter-toggle--dot"></div>
        </div>
      </label>
    </div>
  )

  return (
    <form
      id="events-filters"
      className={filterVisibility.events ? "block" : "hidden"}
    >
      <fieldset
        className="mt-3 mb-5 flex flex-row flex-wrap items-center gap-3 text-sm"
        aria-label="Filtering options for the Events section"
      >
        <div className="mt-2 flex flex-row justify-start gap-3">
          {rangePills}
        </div>
        <div className="flex flex-row flex-wrap gap-1">
          {categorySelect}
          {alldayToggle}
        </div>
      </fieldset>
    </form>
  )
}

export default EventsFilters
