import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useQuery, gql } from "@apollo/client"

import { Settings } from "./settings"
import Button from "../../buttons/button"

import { UserContext } from "../../utils/UserContext"
import { CircularSpinner } from "../ui/spinner"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"

const Login = () => {
  const [userState, userStateUpdate] = React.useContext(UserContext)
  const { loading, error, data } = useQuery(gql`
    query {
      user: dashusersession {
        userName: display_name
        editAccess {
          calendarId
          calendarName
        }
      }
    }
  `)
  React.useEffect(() => {
    if (data !== undefined) {
      userStateUpdate(data.user) // set the UserContext
    }
  }, [data])
  if (error) console.error(error)
  if (loading) {
    return (
      <CircularSpinner
        width="w-5"
        height="h-5"
        color="border-primary dark:border-primary-light"
      />
    )
  }
  if (data === undefined || data.user === null) {
    return (
      <>
        <LoginButton />
        <span className="mr-2 text-xl text-black dark:text-white dark:hover:text-black">
          <Settings editAccess={[]} />
        </span>
      </>
    )
  }
  const { userName, editAccess } = data.user
  const userInitials =
    userName.substr(0, 1).toUpperCase() +
    userName
      .replace(/^.+\s(\S+)$/, "$1")
      .substr(0, 1)
      .toUpperCase()
  return (
    <>
      <LogoutButton
        userName={userName}
        userInitials={userInitials}
        editAccess={editAccess}
      />
      <span className="mr-2 text-lg text-black dark:text-white dark:hover:text-black">
        <Settings editAccess={editAccess} />
      </span>
    </>
  )
}

const LoginButton = () => (
  <Button
    color="gray-900"
    url="/login"
    icon="login"
    additionalClasses={["text-xs"]}
    additionalAttr={{ "data-context": "user info" }}
  >
    Editor Login
  </Button>
)

const LogoutButton = ({ userName, userInitials, editAccess }) => {
  const [viewMenu, setViewMenu] = React.useState(false)

  return (
    <div className="flex">
      <button
        onClick={() => setViewMenu(!viewMenu)}
        className={
          "text-md mr-2 flex flex-none items-center rounded p-1 font-semibold text-gray-900 transition hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700 " +
          (viewMenu
            ? "bg-gray-300 bg-opacity-50 shadow-inner dark:bg-gray-700"
            : "")
        }
      >
        {userInitials}
        <span
          className={
            "text-md ml-2 transition " + (viewMenu ? "-rotate-90" : "")
          }
        >
          <FontAwesomeIcon icon={faCaretDown} className="pointer-events-none" />
        </span>
      </button>
      {viewMenu && (
        <div className="my-auto flex flex-col text-sm text-black dark:text-gray-100">
          {editAccess?.length && (
            <span className="text-xs">
              <Settings editAccess={editAccess} displayStyle="link">
                Edit Calendars
              </Settings>
            </span>
          )}
          <Link
            to="/logout"
            data-type="call to action"
            data-context="user info"
            className="text-xs hover:underline"
            state={{ userName: userName }}
          >
            Log out
          </Link>
        </div>
      )}
    </div>
  )
}

const UserInfo = ({ navbarCollapsed, setNavbarCollapsed }) => (
  <div className="user-info border-t border-gray-200 bg-primary-light px-3 py-2 text-black dark:border-gray-700 dark:bg-gray-800 dark:text-white pointer-events-auto">
    <div className="flex w-full items-center justify-between">
      {navbarCollapsed ? (
        <>
          <Button
            additionalClasses={["text-xs", "md-max:hidden"]}
            color="gray-900"
            onClick={() => setNavbarCollapsed(!navbarCollapsed)}
            additionalAttr={{
              "data-context": "user info",
              "data-type": "toggle",
              "data-action": "expand",
              "data-label": "Expand navigation and view settings",
              title: "Click to expand navigation and view settings/options",
            }}
          >
            <span className="tw-sr-only">Expand Navigation and View</span>{" "}
            Settings
          </Button>
          <span className="flex w-full items-center justify-between md:hidden">
            <Login />
          </span>
        </>
      ) : (
        <Login />
      )}
    </div>
  </div>
)

LogoutButton.propTypes = {
  userName: PropTypes.string,
  userInitials: PropTypes.string,
}

LogoutButton.defaultProps = {
  userName: ``,
  userInitials: ``,
}

export default UserInfo
