import React from "react"
import PropTypes from "prop-types"
import isEqual from "react-fast-compare"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons"

const ResetFilterOptions = props => {
  const {
    filterDefaults,
    filterSettings,
    setFilterSettings,
    setHoursOffset,
    sectionType,
    sectionData,
  } = props

  const isDefault = isEqual(filterDefaults, filterSettings)

  switch (sectionType) {
    case "Around Campus":
      const postedText = "posted in the"

      if (isDefault) {
        return null
      } else {
        const itemCount =
          sectionData.stickyPosts.length + sectionData.standardPosts.length
        return (
          <div className="mb-3 flex flex-row flex-wrap gap-2 text-xs">
            <div>
              <span className="font-bold">{itemCount}</span> results for{" "}
              <RemoveFilterButton
                filterType="feedSource"
                filterDefaults={filterDefaults}
                filterSettings={filterSettings}
                setFilterSettings={setFilterSettings}
                setHoursOffset={setHoursOffset}
                sectionType={sectionType}
              />{" "}
              {postedText}{" "}
              <RemoveFilterButton
                filterType="range"
                filterDefaults={filterDefaults}
                filterSettings={filterSettings}
                setFilterSettings={setFilterSettings}
                setHoursOffset={setHoursOffset}
                sectionType={sectionType}
              />{" "}
              sorted by{" "}
              <RemoveFilterButton
                filterType="order"
                filterDefaults={filterDefaults}
                filterSettings={filterSettings}
                setFilterSettings={setFilterSettings}
                setHoursOffset={setHoursOffset}
                sectionType={sectionType}
              />{" "}
            </div>
            <ResetAllFiltersButton
              filterDefaults={filterDefaults}
              setFilterSettings={setFilterSettings}
              setHoursOffset={setHoursOffset}
              sectionType={sectionType}
            />
          </div>
        )
      }
    case "Hours":
      if (isDefault) {
        return null
      } else {
        const itemCount = sectionData.itemCount
        return (
          <div className="mb-3 flex flex-row flex-wrap gap-2 text-xs">
            <div>
              <span className="font-bold">{itemCount}</span> results for{" "}
              <RemoveFilterButton
                filterType="category"
                filterDefaults={filterDefaults}
                filterSettings={filterSettings}
                setFilterSettings={setFilterSettings}
                setHoursOffset={setHoursOffset}
                sectionType={sectionType}
              />{" "}
              sorted{" "}
              <RemoveFilterButton
                filterType="order"
                filterDefaults={filterDefaults}
                filterSettings={filterSettings}
                setFilterSettings={setFilterSettings}
                setHoursOffset={setHoursOffset}
                sectionType={sectionType}
              />
            </div>
            <ResetAllFiltersButton
              filterDefaults={filterDefaults}
              setFilterSettings={setFilterSettings}
              setHoursOffset={setHoursOffset}
              sectionType={sectionType}
            />
          </div>
        )
      }
    case "Events":
      if (isDefault) {
        return null
      } else {
        const itemCount = sectionData.itemCount
        return (
          <div className="mb-3 flex flex-row flex-wrap gap-2 text-xs">
            <div>
              <span className="font-bold">{itemCount}</span> results for{" "}
              <RemoveFilterButton
                filterType="category"
                filterDefaults={filterDefaults}
                filterSettings={filterSettings}
                setFilterSettings={setFilterSettings}
                setHoursOffset={setHoursOffset}
                sectionType={sectionType}
              />
            </div>
            <ResetAllFiltersButton
              filterDefaults={filterDefaults}
              setFilterSettings={setFilterSettings}
              setHoursOffset={setHoursOffset}
              sectionType={sectionType}
            />
          </div>
        )
      }
    default:
      return null
  }
}

const RemoveFilterButton = props => {
  const {
    filterSettings,
    setFilterSettings,
    setHoursOffset,
    filterDefaults,
    filterType,
    sectionType,
  } = props
  const disabled = filterDefaults[filterType] === filterSettings[filterType]
  const filterClasses = [
    "font-bold",
    "text-black",
    "rounded-xl",
    "whitespace-nowrap",
    "px-2",
    "py-1",
    disabled ? "bg-gray-100" : "bg-primary-light",
  ]

  const handleFilterChange = ({ target }) => {
    setFilterSettings(prevState => ({
      ...prevState,
      [filterType]: filterDefaults[filterType],
    }))
    if (sectionType === "Hours") setHoursOffset([])
  }

  return (
    <button
      data-type="toggle"
      data-context="filter options"
      data-action="clear"
      disabled={disabled}
      type="button"
      className={filterClasses.join(" ")}
      onClick={handleFilterChange}
    >
      <span className="pointer-events-none">
        <span className="tw-sr-only">Clear</span> {filterSettings[filterType]}{" "}
        <span className="tw-sr-only">Filter</span>
        {!disabled && (
          <FontAwesomeIcon
            icon={faTimes}
            className="ml-2 inline-block text-primary"
          />
        )}
      </span>
    </button>
  )
}

const ResetAllFiltersButton = props => {
  const {
    setFilterSettings,
    setHoursOffset,
    filterDefaults,
    sectionType,
  } = props

  const filterClasses = [
    "font-bold",
    "text-gray-500",
    "dark:text-gray-300",
    "py-1",
  ]

  const handleFilterReset = () => {
    setFilterSettings(prevState => filterDefaults)
    if (sectionType === "Hours") setHoursOffset([])
  }

  return (
    <button
      data-type="toggle"
      data-context="filter options"
      data-action="clear"
      className={filterClasses.join(" ")}
      type="button"
      onClick={handleFilterReset}
    >
      <span className="pointer-events-none">
        <FontAwesomeIcon icon={faTrashAlt} className="mr-1 inline-block" />
        Reset all filters
      </span>
    </button>
  )
}

ResetFilterOptions.propTypes = {
  filterSettings: PropTypes.object,
  setFilterSettings: PropTypes.func,
  setHoursOffset: PropTypes.func,
  sectionType: PropTypes.string,
  sectionData: PropTypes.object,
}

export default ResetFilterOptions
