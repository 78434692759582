import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBook,
  faBookOpen,
  faBus,
  faCalendarAlt,
  faClock,
  faEdit,
  faTachometerAlt,
  faGraduationCap,
  faHome,
  faIdCard,
  faInfoCircle,
  faLaptop,
  faNewspaper,
  faQuestionCircle,
  faVideo,
  faList,
  faFile,
  faFolderOpen,
  faBuilding,
  faImage,
  faLayerGroup,
  faTag,
  faGlobe,
  faUser,
  faUsers,
  faBell,
  faLocationArrow,
  faChartLine,
  faMicrophone,
  faHeadphones,
  faTools,
} from "@fortawesome/free-solid-svg-icons"
import GmailIcon from "../../icons/gmail"
import GoogleDrive from "../../icons/google-drive"
import InterfolioIcon from "../../icons/interfolio"

const IconLink = ({ linkData, navbarCollapsed, setMobileNavOpen }) => {
  const { title, description, url, options } = linkData
  const { external, attributes } = options
  const icon = attributes.icon
  const serviceIconClasses = [
    "inline-block",
    `${navbarCollapsed ? "mr-3 md:mx-auto" : "mr-3"}`,
    "px-1.5",
    "py-1.5",
    "h-8",
    "w-8",
    "rounded-full",
    "bg-white",
    "dark:bg-gray-600",
    "text-center",
    "text-primary",
    "dark:text-white",
    "group-hover:bg-gray-100",
    "dark:group-hover:text-primary",
  ]
  let brandClasses = []
  let serviceIcon

  switch (icon) {
    case "swat-central":
      brandClasses.push("brand-swat-central")
      serviceIcon = (
        <FontAwesomeIcon icon={faCalendarAlt} className="inline-block" />
      )
      break
    case "faculty-services":
      brandClasses.push("brand-faculty-services")
      serviceIcon = <FontAwesomeIcon icon={faEdit} className="inline-block" />
      break
    case "gmail":
      brandClasses.push("brand-gmail")
      serviceIcon = <GmailIcon width="17" iconClasses="inline-block" />
      break
    case "google-drive":
      brandClasses.push("brand-google-drive")
      serviceIcon = <GoogleDrive width="17" iconClasses="inline-block" />
      break
    case "interfolio":
      brandClasses.push("brand-interfolio")
      serviceIcon = <InterfolioIcon width="17" iconClasses="inline-block" />
      break
    case "its":
      brandClasses.push("brand-its")
      serviceIcon = <FontAwesomeIcon icon={faLaptop} className="inline-block" />
      break
    case "moodle":
      brandClasses.push("brand-moodle")
      serviceIcon = (
        <FontAwesomeIcon icon={faGraduationCap} className="inline-block" />
      )
      break
    case "myswat":
      brandClasses.push("brand-myswat")
      serviceIcon = (
        <FontAwesomeIcon icon={faTachometerAlt} className="inline-block" />
      )
      break
    case "onecard":
      brandClasses.push("brand-onecard")
      serviceIcon = <FontAwesomeIcon icon={faIdCard} className="inline-block" />
      break
    case "tripod":
      brandClasses.push("brand-tripod")
      serviceIcon = (
        <FontAwesomeIcon icon={faBookOpen} className="inline-block" />
      )
      break
    case "zoom":
      brandClasses.push("brand-zoom")
      serviceIcon = <FontAwesomeIcon icon={faVideo} className="inline-block" />
      break
    // Generic icon options below
    case "faBook":
      serviceIcon = <FontAwesomeIcon icon={faBook} className="inline-block" />
      break
    case "faBus":
      serviceIcon = <FontAwesomeIcon icon={faBus} className="inline-block" />
      break
    case "faClock":
      serviceIcon = <FontAwesomeIcon icon={faClock} className="inline-block" />
      break
    case "faHome":
      serviceIcon = <FontAwesomeIcon icon={faHome} className="inline-block" />
      break
    case "faInfoCircle":
      serviceIcon = (
        <FontAwesomeIcon icon={faInfoCircle} className="inline-block" />
      )
      break
    case "faNewspaper":
      serviceIcon = (
        <FontAwesomeIcon icon={faNewspaper} className="inline-block" />
      )
      break
    case "faQuestionCircle":
      serviceIcon = (
        <FontAwesomeIcon icon={faQuestionCircle} className="inline-block" />
      )
      break
    case "faList":
      serviceIcon = <FontAwesomeIcon icon={faList} className="inline-block" />
      break
    case "faFile":
      serviceIcon = <FontAwesomeIcon icon={faFile} className="inline-block" />
      break
    case "faFolderOpen":
      serviceIcon = (
        <FontAwesomeIcon icon={faFolderOpen} className="inline-block" />
      )
      break
    case "faBuilding":
      serviceIcon = (
        <FontAwesomeIcon icon={faBuilding} className="inline-block" />
      )
      break
    case "faImage":
      serviceIcon = <FontAwesomeIcon icon={faImage} className="inline-block" />
      break
    case "faLayerGroup":
      serviceIcon = (
        <FontAwesomeIcon icon={faLayerGroup} className="inline-block" />
      )
      break
    case "faTag":
      serviceIcon = <FontAwesomeIcon icon={faTag} className="inline-block" />
      break
    case "faGlobe":
      serviceIcon = <FontAwesomeIcon icon={faGlobe} className="inline-block" />
      break
    case "faUser":
      serviceIcon = <FontAwesomeIcon icon={faUser} className="inline-block" />
      break
    case "faUsers":
      serviceIcon = <FontAwesomeIcon icon={faUsers} className="inline-block" />
      break
    case "faBell":
      serviceIcon = <FontAwesomeIcon icon={faBell} className="inline-block" />
      break
    case "faLocationArrow":
      serviceIcon = (
        <FontAwesomeIcon icon={faLocationArrow} className="inline-block" />
      )
      break
    case "faChartLine":
      serviceIcon = (
        <FontAwesomeIcon icon={faChartLine} className="inline-block" />
      )
      break
    case "faMicrophone":
      serviceIcon = (
        <FontAwesomeIcon icon={faMicrophone} className="inline-block" />
      )
      break
    case "faHeadphones":
      serviceIcon = (
        <FontAwesomeIcon icon={faHeadphones} className="inline-block" />
      )
      break
    case "faTools":
      serviceIcon = <FontAwesomeIcon icon={faTools} className="inline-block" />
      break
    default:
      break
  }

  return external ? (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="flex items-center px-3.5"
      title={description !== null ? description : title}
      onClick={() => setMobileNavOpen(false)}
    >
      <div className={serviceIconClasses.concat(brandClasses).join(" ")}>
        {serviceIcon}
      </div>
      <span className={navbarCollapsed ? "md:tw-sr-only" : ""}>{title}</span>
    </a>
  ) : (
    <Link
      to={url}
      className="flex items-center"
      title={description !== null ? description : title}
      onClick={() => setMobileNavOpen(false)}
    >
      <div className={serviceIconClasses.concat(brandClasses).join(" ")}>
        {serviceIcon}
      </div>
      <span className={navbarCollapsed ? "md:tw-sr-only" : ""}>{title}</span>
    </Link>
  )
}

IconLink.propTypes = {
  linkData: PropTypes.object,
}

IconLink.defaultProps = {
  linkData: {},
}

export default IconLink
