import React from "react"
import SwarthmoreLogo from "../logos/swarthmore-logo"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"

/**
 * A simple button that links to the Swarthmore website.
 */
const SwatHomeButton = ({ navbarCollapsed }) => {
  return (
    <a
      href="https://swarthmore.edu"
      data-type="call to action"
      data-context="site header"
      data-action="visit swarthmore.edu"
      className="flex items-center justify-center gap-3 bg-transparent px-1 py-2 text-sm text-swat-red transition hover:bg-gray-600 hover:text-white dark:text-white dark:hover:bg-swat-red"
      role="button"
      target="__blank"
      rel="noopener"
      title="Link to Swarthmore College Website"
    >
      <span className="tw-sr-only">Link to Swarthmore College Website</span>
      <span className={navbarCollapsed ? "md:hidden" : ""}>
        <FontAwesomeIcon icon={faHome} className="pointer-events-none" />
      </span>
      <SwarthmoreLogo width="110" additionalClasses={["pointer-events-none"]} />
    </a>
  )
}

export default SwatHomeButton
