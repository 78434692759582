import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import NavigationUtil from "../../../components/utils/navigationUtil"
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons"

const MoreSearchOptions = mobileSearchOpen => {
  const data = useStaticQuery(graphql`
    query SearchMoreMenuItems {
      allMenuItems(filter: { menu_name: { eq: "search-more" } }) {
        edges {
          node {
            id
            url
            title
            options {
              external
            }
          }
        }
      }
    }
  `)
  const [moreSearchToolsState, setMoreSearchToolsState] = useState("hidden")
  const items = data.allMenuItems.edges.map(({ node }, index) => ({
    searchName: node.title,
    searchUrl: node.url,
  }))
  const lastItemIndex = items.length - 1

  return (
    <div id="more-search-tools" className="relative">
      <button
        id="more-search-tools-button"
        data-type="toggle"
        data-context="search options"
        data-action="toggle search options"
        className={
          "relative rounded-md px-1 py-2 text-xs transition " +
          (moreSearchToolsState === "show"
            ? "bg-gray-300 shadow-inner dark:bg-gray-700 dark:text-white md-max:bg-gray-400 md-max:text-gray-900"
            : "hover:bg-gray-200 dark:text-gray-200 dark:hover:bg-gray-600 md-max:hover:bg-gray-300")
        }
        type="button"
        aria-expanded={moreSearchToolsState === "show" ? "true" : "false"}
        onClick={() => {
          setMoreSearchToolsState(
            moreSearchToolsState === "hidden" ? "show" : "hidden"
          )
        }}
      >
        <span className="pointer-events-none flex flex-row items-center">
          <FontAwesomeIcon
            icon={faEllipsisV}
            className="mr-1 inline-block text-base "
          />
          <span className={mobileSearchOpen ? "xxs-max:hidden" : null}>
            More <span className="tw-sr-only">off-site search options</span>
          </span>
        </span>
      </button>
      <div
        id="more-search-tools-body"
        className={`absolute top-9 right-0 z-50 h-auto w-64 rounded-md bg-white p-0 text-sm shadow-md dark:bg-gray-700 dark:text-white ${moreSearchToolsState}`}
      >
        <nav className="m-0 p-0" aria-labelledby="more-search-tools-heading">
          <header className="rounded-t-md bg-primary py-1 px-2 text-white">
            <h1
              id="more-search-tools-heading"
              className="inline-block font-bold"
            >
              More Search Tools
            </h1>
          </header>
          <ul className="list-none" aria-labelledby="more-search-tools-heading">
            {items.map((item, index) => {
              const defaultClasses = [
                "px-2",
                "py-1",
                "hover:bg-gray-100",
                "dark:hover:bg-gray-800",
                "last:rounded-b-md",
              ]
              let conditionalClasses = []
              if (lastItemIndex !== index)
                conditionalClasses.push(
                  "border-b dark:border-gray-600 border-gray-100"
                )

              return (
                <li
                  className={defaultClasses
                    .concat(conditionalClasses)
                    .join(" ")}
                  key={index}
                >
                  <a
                    href={item.searchUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="inline-block w-full cursor-pointer whitespace-nowrap"
                  >
                    {item.searchName}
                  </a>
                </li>
              )
            })}
          </ul>
        </nav>
      </div>
      {moreSearchToolsState === "show" ? (
        <NavigationUtil
          closeAction={() => {
            setMoreSearchToolsState("hidden")
          }}
          menuSelector="#more-search-tools"
          excludeElement="#more-search-tools-button"
        />
      ) : null}
    </div>
  )
}

MoreSearchOptions.propTypes = {
  mobileSearchOpen: PropTypes.bool,
}

export default MoreSearchOptions
