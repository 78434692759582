const sortNavItems = (items, contentSort) => {
  return items.map(({ node }, index) => {
    let hidden = 0
    let prevPosition =
      contentSort.find(s => s.drupal_id === node.drupal_id)?.prevPosition ||
      null
    let weight = contentSort.findIndex(s => s.drupal_id === node.drupal_id)
    if (weight !== -1) {
      hidden =
        contentSort.find(s => s.drupal_id === node.drupal_id)?.hidden || 0
    } else {
      weight = node.field_weight
    }
    return {
      id: node.id,
      drupal_id: node.drupal_id,
      title: node.title,
      type: node.field_section_type,
      weight: weight,
      hidden: hidden,
      ...(prevPosition !== null && { prevPosition: prevPosition }),
    }
  })
  .sort((a, b) => {
    if (a.hidden === b.hidden) {
      return a.weight - b.weight
    }
    //puts hidden items at the bottom
    return a.hidden - b.hidden
  })
}

export default sortNavItems