import * as React from "react"
import SwarthmoreLogo from "../logos/swarthmore-logo"

const Footer = () => {
  return (
    <>
      <footer className="inset-x-0 bottom-0 bg-white p-6 text-center text-black dark:bg-gray-900 dark:text-white">
        <div className="flex flex-col items-center text-xs">
          <a
            data-type="call to action"
            data-context="site footer"
            data-action="visit swarthmore.edu"
            href="https://www.swarthmore.edu"
            className="mx-auto my-1 flex max-w-max"
            title="Swarthmore College Website"
          >
            <span className="tw-sr-only">
              Link to Swarthmore College Website
            </span>
            <SwarthmoreLogo
              width="250px"
              additionalClasses={["text-primary", "dark:text-white"]}
            />
          </a>
          <ul className="flex flex-wrap justify-center text-blue-700 dark:text-blue-400">
            <li className="mr-3 hover:underline">
              <a href="mailto:dash@swarthmore.edu?subject=Dash Feedback">
                Feedback
              </a>
            </li>
            <li className="mr-3 hover:underline">
              <a href="https://www.swarthmore.edu/swarthmore-college-privacy-notice">
                Privacy Policy
              </a>
            </li>
            <li className="mr-3 hover:underline">
              <a href="https://www.swarthmore.edu/accessibility/ict-accessibility-policy">
                Accessibility Policy
              </a>
            </li>
            <li className="hover:underline">
              <a href="https://www.swarthmore.edu/equal-opportunity/non-discrimination-and-equal-opportunity">
                Non-Discrimination and Equal Opportunity Policy
              </a>
            </li>
          </ul>
          <span>
            © {new Date().getFullYear()} Swarthmore College. All rights
            reserved.
          </span>
        </div>
      </footer>
    </>
  )
}

export default Footer
