import * as React from "react"
import PropTypes from "prop-types"
import { ScrollSpyObserver } from "../../utils/ScrollSpy"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBus,
  faClock,
  faExclamationCircle,
  faUtensils,
  faNewspaper,
} from "@fortawesome/free-solid-svg-icons"
import {
  faSmile,
  faCalendarAlt,
  faCommentDots,
} from "@fortawesome/free-regular-svg-icons"

const SectionLinkWrapper = ({
  children,
  hidden,
  showAnnouncementsLink,
  type,
}) => {
  let wrapperClasses =
    hidden === 1
      ? ["hidden"]
      : ["hover:bg-gray-300", "dark:hover:text-black", "transition"]
  if (type === "announcements" && !showAnnouncementsLink) {
    wrapperClasses.push("hidden")
  }
  return (
    <li
      className={wrapperClasses.join(" ")}
      hidden={
        (type === "announcements" && !showAnnouncementsLink) || hidden === 1
      }
    >
      {children}
    </li>
  )
}

const SectionLink = ({
  linkData,
  showAnnouncementsLink,
  navbarCollapsed,
  setMobileNavOpen,
}) => {
  const { title, type, hidden } = linkData
  const iconStyles = "text-lg inline-block"
  let menuIcon

  switch (type) {
    case "around_campus":
      menuIcon = <FontAwesomeIcon icon={faNewspaper} className={iconStyles} />
      break
    case "announcements":
      menuIcon = (
        <FontAwesomeIcon icon={faExclamationCircle} className={iconStyles} />
      )
      break
    case "events":
      menuIcon = <FontAwesomeIcon icon={faCalendarAlt} className={iconStyles} />
      break
    case "hours":
      menuIcon = <FontAwesomeIcon icon={faClock} className={iconStyles} />
      break
    case "menus":
      menuIcon = <FontAwesomeIcon icon={faUtensils} className={iconStyles} />
      break
    case "mind_candy":
      menuIcon = <FontAwesomeIcon icon={faSmile} className={iconStyles} />
      break
    case "transportation":
      menuIcon = <FontAwesomeIcon icon={faBus} className={iconStyles} />
      break
    default:
      //TODO: Consider removing this conditionoal after Beta period
      if (title === "Feedback")
        menuIcon = (
          <FontAwesomeIcon icon={faCommentDots} className={iconStyles} />
        )
      break
  }
  if (hidden === 0 || hidden === undefined) {
    return (
      <ScrollSpyObserver spyHref={`#${title.replace(/\s+/g, "-")}`}>
        <SectionLinkWrapper
          hidden={hidden}
          showAnnouncementsLink={showAnnouncementsLink}
          type={type}
        >
          <a
            href={"#" + title.replace(/\s+/g, "-")}
            onClick={() => setMobileNavOpen(false)}
            className={`py-2 px-3.5 ${
              hidden !== 1 ? " section-link block" : ""
            }`}
          >
            <span
              className={`flex items-center gap-3 ${
                navbarCollapsed ? "md:justify-center" : ""
              }`}
            >
              <span className="flex h-6 w-6 items-center justify-center">
                {menuIcon}
              </span>
              <span
                className={navbarCollapsed ? "md:tw-sr-only" : ""}
              >{`${title} ${hidden === 1 ? " (hidden)" : ""}`}</span>
            </span>
          </a>
        </SectionLinkWrapper>
      </ScrollSpyObserver>
    )
  } else {
    return (
      <SectionLinkWrapper
        hidden={hidden}
        showAnnouncementsLink={showAnnouncementsLink}
        type={type}
      >
        <a
          href={"#" + title.replace(/\s+/g, "-")}
          onClick={() => setMobileNavOpen(false)}
          className={`py-2 px-5 ${hidden !== 1 ? " section-link block" : ""}`}
        >
          <span className="flex items-center gap-3">
            <span className="flex h-6 w-6 items-center">{menuIcon}</span>
            <span
              className={navbarCollapsed ? "md:tw-sr-only" : ""}
            >{`${title} ${hidden === 1 ? " (hidden)" : ""}`}</span>
          </span>
        </a>
      </SectionLinkWrapper>
    )
  }
}

SectionLink.propTypes = {
  linkData: PropTypes.object,
  showAnnouncementsLink: PropTypes.bool,
}

SectionLink.defaultProps = {
  linkData: {},
  showAnnouncementsLink: false,
}

export default SectionLink
