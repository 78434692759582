/**
 * Distributes an array of objects into two columns, attempting to maintain a balanced distribution based on the number of items each object contains.
 * The order of objects is preserved during the distribution process.
 *
 * @param {Array} arr - An array of objects where each object contains a `props.children` array.
 *                      The length of `props.children` is used to determine the number of items in each object.
 * @returns {Array} - An array containing two subarrays representing the two columns.
 *                    Each column is an array of objects, and the order of objects within each column is preserved.
 */
const chunk = arr => {
  const numItems = arr.reduce(
    (total, obj) => total + obj.props.children.length,
    0
  )
  const avgItemsPerColumn = Math.ceil(numItems / 2)

  let currentColumn = []
  let currentSize = 0
  let columnIndex = 0

  const chunks = [[], []]

  for (const obj of arr) {
    const objSize = obj.props.children.length

    if (currentSize + objSize <= avgItemsPerColumn) {
      currentColumn.push(obj)
      currentSize += objSize
    } else {
      chunks[columnIndex].push(...currentColumn)
      currentColumn = [obj]
      currentSize = objSize
      columnIndex = 1 - columnIndex // Switch column index
    }
  }

  // Add remaining items to the last column
  chunks[1 - columnIndex].push(...currentColumn)

  return chunks
}

export default chunk
