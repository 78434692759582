import * as React from "react"
// Utils
import filterChange from "../../utils/filterChange"

const AroundCampusFilters = props => {
  const {
    selectOptions,
    filterSettings,
    setFilterSettings,
    filterVisibility,
    setSliceEnd,
    defaultNumStoriesToShow,
  } = props

  let sourceSelect = selectOptions.map((option, i) => (
    <option key={i} value={option}>
      {option}
    </option>
  ))
  if (
    !selectOptions.includes(filterSettings.feedSource) &&
    filterSettings.feedSource !== "All"
  ) {
    sourceSelect.push(
      <option
        key={sourceSelect.length}
        value={filterSettings.feedSource}
        disabled
      >
        {filterSettings.feedSource} - no results for current filters
      </option>
    )
  }
  sourceSelect = sourceSelect.sort((a, b) => {
    const titleA = a.props.value
    const titleB = b.props.value
    return titleA.localeCompare(titleB)
  })

  const handleFilterChange = ({ target }) => {
    const filterType = target.dataset.filter
    const newValue = target.value
    setFilterSettings(prevState => ({
      ...prevState,
      [filterType]: newValue,
    }))
    filterChange(target)
    setSliceEnd(defaultNumStoriesToShow)
  }
  return (
    <form
      id="around-campus-filters"
      className={filterVisibility.around_campus ? "block" : "hidden "}
    >
      <fieldset
        className="mb-5 flex flex-row flex-wrap justify-start gap-1"
        aria-label="Filtering options for the Around Campus section"
      >
        <label htmlFor="around-campus-source" className="tw-sr-only">
          Feed Source
        </label>
        <select
          id="around-campus-source"
          data-type="filter"
          data-context="around campus filters"
          data-action="select feed source"
          className="filter-select"
          data-filter="feedSource"
          value={filterSettings.feedSource}
          onChange={handleFilterChange}
        >
          <option value="All">All</option>
          {sourceSelect}
        </select>
        <label htmlFor="around-campus-date-posted" className="tw-sr-only">
          Date Posted
        </label>
        <select
          id="around-campus-date-posted"
          data-type="filter"
          data-context="around campus filters"
          data-action="select range"
          className="filter-select"
          data-filter="range"
          value={filterSettings.range}
          onChange={handleFilterChange}
        >
          <option>Past 3 Days</option>
          <option>Past Week</option>
          <option>Past 2 Weeks</option>
        </select>
        <label htmlFor="around-campus-sort-by" className="tw-sr-only">
          Sort By
        </label>
        <select
          id="around-campus-sort-by"
          data-type="filter"
          data-context="around campus filters"
          data-action="select order"
          className="filter-select"
          data-filter="order"
          value={filterSettings.order}
          onChange={handleFilterChange}
        >
          <option>Most Recent</option>
          <option>Source (A-Z)</option>
        </select>
      </fieldset>
    </form>
  )
}

export default AroundCampusFilters
