import * as React from "react"
import { DateTime } from "luxon"

import DashLogoCircular from "../../logos/dash-logo-circular"
import Weather from "./weather"
import swatDatesUtil from "../../utils/swatDatesUtil"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

const SiteHeader = props => {
  const { mobileNavOpen, setMobileNavOpen, navbarCollapsed } = props
  const [currentDate, setCurrentDate] = React.useState(DateTime.now())

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(DateTime.now())
    }, 1000 * 60) // Update every minute
    return () => clearInterval(interval)
  }, [])

  return (
    <div className="flex h-full items-center px-1.5 py-2.5 text-white">
      <div
        className={`hidden md:block ${
          navbarCollapsed
            ? "animate-fade-in md:w-auto md-max:w-2/5"
            : "w-2/5 animate-fade-in"
        }`}
        onAnimationEnd={e => e.target.classList.toggle("animate-fade-in")}
      >
        <h1 className="tw-sr-only">The Dash</h1>
        <DashLogoCircular
          width="100%"
          bgColor="text-secondary"
          textColor="text-white"
        />
      </div>

      <div
        className={`ml-3 mr-auto w-3/5 p-1 ${
          navbarCollapsed ? "md:hidden" : ""
        }`}
      >
        <div>
          <span className="tw-sr-only">Today's date:</span>
          <span className="text-lg font-bold">
            <time dateTime={currentDate.toISODate()}>
              <span className="block">
                {swatDatesUtil(currentDate.weekdayLong + ",")}
              </span>
              {swatDatesUtil(currentDate.monthLong + " " + currentDate.day)}
            </time>
          </span>
        </div>
        <Weather mobileNavOpen={mobileNavOpen} />
      </div>
      <div className="block md:hidden">
        <button
          aria-label="Close Primary Navigation"
          aria-haspopup="true"
          aria-controls="primary-navigation"
          aria-expanded={mobileNavOpen}
          data-type="toggle"
          data-context="mobile navigation"
          data-action="close"
          className="navbar-icon navbar-icon--square text-white hover:text-black"
          onClick={() => setMobileNavOpen(false)}
        >
          <span className="pointer-events-none">
            <span className="px-0.5">
              <FontAwesomeIcon
                icon={faTimes}
                className="inline-block text-lg"
              />
            </span>
            <span className="tw-sr-only">Hide Mobile Navigation</span>
          </span>
        </button>
      </div>
    </div>
  )
}

export default SiteHeader
