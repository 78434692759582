import React from "react"
import PropTypes from "prop-types"

/**
 * Horizontal Swarthmore logo. Fill color is inherited from parent.
 */
const SwarthmoreLogo = props => {
  const { width, additionalClasses, title } = props
  const defaultClasses = ["fill-current"]

  return (
    <svg
      width={width}
      height="100%"
      viewBox="0 0 208 22"
      className={defaultClasses?.concat(additionalClasses).join(" ")}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      {title && <title>{title}</title>}
      <path d="M3.97913043,5.5 C3.97913043,3.48333333 5.96869565,2.75 7.59652174,2.75 C9.22434783,2.75 10.8521739,3.48333333 11.9373913,4.58333333 L11.9373913,1.28333333 C10.6713043,0.916666667 9.22434783,0.55 7.7773913,0.55 C4.34086957,0.55 0.723478261,2.01666667 0.723478261,5.68333333 C0.723478261,11.9166667 10.4904348,11.1833333 10.4904348,15.7666667 C10.4904348,17.7833333 8.13913043,18.8833333 6.51130435,18.8833333 C4.34086957,18.8833333 1.98956522,17.9666667 0.36173913,16.5 L1.26608696,20.1666667 C2.89391304,20.7166667 4.52173913,21.0833333 6.33043478,21.0833333 C10.6713043,21.0833333 13.9269565,18.15 13.9269565,15.0333333 C13.5652174,9.53333333 3.97913043,9.53333333 3.97913043,5.5"></path>
      <path d="M37.44,1.28333333 C39.6104348,2.01666667 38.706087,4.4 38.1634783,6.23333333 L34.9078261,17.05 L30.9286957,4.21666667 C30.5669565,2.93333333 30.386087,2.01666667 30.5669565,1.28333333 L26.5878261,1.28333333 C28.2156522,2.2 27.4921739,4.03333333 26.9495652,5.86666667 L23.693913,17.05 L19.533913,3.3 C19.3530435,2.75 19.1721739,1.83333333 19.1721739,1.1 L15.0121739,1.1 C16.4591304,2.01666667 16.8208696,4.03333333 17.3634783,5.68333333 L20.6191304,16.5 C21.5234783,19.6166667 21.8852174,19.25 24.4173913,21.2666667 L28.7582609,6.41666667 L31.8330435,16.5 C32.7373913,19.6166667 32.9182609,19.4333333 35.6313043,21.2666667 L41.6,0.916666667 L37.44,1.28333333 Z"></path>
      <path d="M58.9634783,16.3166667 L53.1756522,1.28333333 L48.653913,1.28333333 L48.653913,1.46666667 C49.92,1.83333333 49.5582609,3.11666667 49.0156522,4.21666667 L44.3130435,16.5 C43.7704348,18.15 43.2278261,19.8 41.6,20.7166667 L46.8452174,20.7166667 C45.5791304,20.1666667 45.76,19.25 46.3026087,17.6 L47.7495652,13.3833333 L54.8034783,13.3833333 L56.2504348,17.6 C56.973913,19.6166667 57.1547826,19.9833333 55.8886957,20.7166667 L61.6765217,20.7166667 C60.2295652,19.6166667 59.6869565,17.9666667 58.9634783,16.3166667 Z M48.4730435,11.1833333 L51.3669565,3.66666667 L54.08,11.1833333 L48.4730435,11.1833333 Z"></path>
      <path d="M75.6034783,16.5 L71.6243478,11.1833333 C73.9756522,10.6333333 76.146087,8.61666667 76.146087,6.05 C76.146087,3.3 74.1565217,1.28333333 70.3582609,1.28333333 L63.666087,1.28333333 C65.1130435,1.83333333 64.9321739,4.03333333 64.9321739,5.31666667 L64.9321739,16.5 C64.9321739,17.7833333 65.1130435,19.9833333 63.666087,20.5333333 L69.0921739,20.5333333 C67.6452174,19.9833333 67.826087,17.7833333 67.826087,16.5 L67.826087,3.3 C68.5495652,3.11666667 69.0921739,3.11666667 69.8156522,3.11666667 C71.6243478,3.11666667 73.4330435,4.21666667 73.4330435,6.41666667 C73.4330435,9.16666667 70.72,10.2666667 68.5495652,10.6333333 L71.0817391,14.85 L74.5182609,19.25 C75.4226087,20.35 76.3269565,20.7166667 77.773913,20.7166667 L80.306087,20.7166667 C78.6782609,19.4333333 76.8695652,17.9666667 75.6034783,16.5"></path>
      <path d="M93.1478261,1.1 L82.6573913,1.1 L81.933913,1.1 C81.5721739,1.1 81.2104348,1.1 80.8486957,0.916666667 L79.9443478,4.4 C80.6678261,3.66666667 81.933913,3.3 83.2,3.3 L85.7321739,3.3 L85.7321739,16.5 C85.7321739,17.7833333 85.9130435,19.9833333 84.466087,20.5333333 L90.0730435,20.5333333 C88.626087,19.6166667 88.626087,18.5166667 88.626087,16.8666667 L88.626087,3.3 L91.1582609,3.3 C92.0626087,3.3 93.1478261,3.48333333 93.5095652,4.4 L94.413913,0.916666667 C94.0521739,1.1 93.6904348,1.1 93.1478261,1.1"></path>
      <path d="M111.777391,1.28333333 C113.224348,1.83333333 113.043478,4.03333333 113.043478,5.31666667 L113.043478,8.98333333 L102.914783,8.98333333 L102.914783,5.31666667 C102.914783,4.03333333 102.733913,1.83333333 104.18087,1.28333333 L98.7547826,1.28333333 C100.201739,1.83333333 100.02087,4.03333333 100.02087,5.31666667 L100.02087,16.5 C100.02087,17.7833333 100.201739,19.9833333 98.7547826,20.5333333 L104.18087,20.5333333 C102.733913,19.9833333 102.914783,17.7833333 102.914783,16.5 L102.914783,11.1833333 L113.043478,11.1833333 L113.043478,16.5 C113.043478,17.7833333 113.224348,19.9833333 111.777391,20.5333333 L117.203478,20.5333333 C115.575652,19.9833333 115.756522,17.7833333 115.756522,16.5 L115.756522,5.31666667 C115.756522,4.03333333 115.575652,1.83333333 117.022609,1.28333333 L111.777391,1.28333333 Z"></path>
      <path d="M146.504348,17.4166667 L144.695652,2.93333333 C144.695652,2.2 144.876522,1.46666667 145.6,1.28333333 L140.716522,1.28333333 C140.716522,1.83333333 140.535652,2.56666667 140.354783,3.11666667 L135.652174,15.5833333 L130.226087,2.56666667 C130.045217,2.2 129.864348,1.65 129.864348,1.28333333 L124.98087,1.28333333 C126.246957,2.01666667 125.885217,3.11666667 125.885217,4.03333333 L124.257391,16.3166667 C124.076522,17.9666667 123.895652,19.4333333 122.629565,20.5333333 L127.151304,20.5333333 C125.885217,19.9833333 126.066087,18.7 126.246957,16.8666667 L127.874783,4.21666667 L133.30087,17.4166667 C134.024348,19.0666667 134.386087,19.8 135.833043,20.9 L142.163478,4.03333333 L143.791304,18.15 C143.791304,19.0666667 143.610435,19.9833333 142.706087,20.35 L148.132174,20.35 C146.866087,19.6166667 146.685217,18.8833333 146.504348,17.4166667"></path>
      <path d="M161.878261,0.733333333 C155.909565,0.55 151.387826,5.31666667 151.387826,10.8166667 C151.387826,16.8666667 156.090435,21.0833333 161.697391,21.0833333 C167.304348,21.0833333 172.006957,16.6833333 172.006957,10.6333333 C172.006957,5.31666667 167.846957,0.733333333 161.878261,0.733333333 M161.878261,18.8833333 C157.537391,18.8833333 154.643478,14.6666667 154.643478,10.45 C154.643478,6.05 157.356522,2.75 161.878261,2.75 C166.4,2.75 168.932174,6.96666667 168.932174,11.1833333 C168.751304,15.5833333 166.038261,18.8833333 161.878261,18.8833333"></path>
      <path d="M188.285217,16.5 L184.306087,11.1833333 C186.657391,10.6333333 188.827826,8.61666667 188.827826,6.05 C188.827826,3.3 186.838261,1.28333333 183.04,1.28333333 L176.347826,1.28333333 C177.794783,1.83333333 177.613913,4.03333333 177.613913,5.31666667 L177.613913,16.5 C177.613913,17.7833333 177.794783,19.9833333 176.347826,20.5333333 L181.773913,20.5333333 C180.326957,19.9833333 180.507826,17.7833333 180.507826,16.5 L180.507826,3.3 C181.231304,3.11666667 181.773913,3.11666667 182.497391,3.11666667 C184.306087,3.11666667 186.114783,4.21666667 186.114783,6.41666667 C186.114783,9.16666667 183.582609,10.2666667 181.231304,10.6333333 L183.763478,14.85 L187.2,19.25 C188.104348,20.35 189.008696,20.7166667 190.455652,20.7166667 L192.987826,20.7166667 C191.17913,19.4333333 189.370435,17.9666667 188.285217,16.5"></path>
      <path d="M202.573913,18.5166667 L198.956522,18.15 L198.956522,11.1833333 L201.126957,11.1833333 C202.031304,11.1833333 203.116522,11.1833333 203.65913,11.9166667 L203.65913,8.8 C203.297391,8.98333333 202.935652,8.98333333 202.393043,8.98333333 L198.956522,8.98333333 L198.956522,3.48333333 C199.86087,3.3 200.765217,3.3 201.850435,3.3 C203.116522,3.3 204.382609,3.48333333 205.106087,4.4 L205.106087,1.1 C204.744348,1.28333333 204.201739,1.28333333 203.84,1.28333333 L194.796522,1.28333333 C196.243478,1.83333333 196.062609,4.03333333 196.062609,5.31666667 L196.062609,16.5 C196.062609,17.7833333 196.243478,19.9833333 194.796522,20.5333333 L206.553043,20.5333333 L207.81913,16.6833333 C206.372174,17.9666667 204.382609,18.7 202.573913,18.5166667"></path>
    </svg>
  )
}

SwarthmoreLogo.propTypes = {
  /** Inserts `<title>` element into SVG element */
  title: PropTypes.string,
  /** Sets logo width using passed unite (eg, `<svg width={"85px"} />)`. Height has been hardcoded to 100% to avoid scaling issues. */
  width: PropTypes.string,
  /** Applies additional classes to the default logo classes as an array of classNames */
  additionalClasses: PropTypes.array,
}

SwarthmoreLogo.defaultProps = {
  additionalClasses: [],
}

export default SwarthmoreLogo
