import React, { useState, useEffect, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { DateTime } from "luxon"

import DataProvider from "../utils/DataProvider"
import { CalendarModalContext } from "../calendar-modal/calendar-modal"
import RangedTimeElement from "../utils/RangedTimeElement"
import swatDatesUtil from "../utils/swatDatesUtil"
import chunk from "../utils/chunk"
import HoursFilters from "./filters/hours-filters"
import ResetFilterOptions from "./filters/reset-filter-options"
import { HoursKey, getHoursSymbol } from "./keys-legends/hours-key"
import ChevronToggle from "../page-layout/ui/chevron-toggle"
import Button from "../buttons/button"
import Skeleton from "../page-layout/ui/skeleton"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEye,
  faExclamationCircle,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons"

const HoursFeeds = ({ filterVisibility }) => {
  const filterDefaults = {
    category: "All",
    order: "A-Z",
  }
  const [filterSettings, setFilterSettings] = useState(filterDefaults)
  const [calendarModalState, calendarModalDispatch] = useContext(
    CalendarModalContext
  )

  const data = useStaticQuery(graphql`
    query HoursCollections {
      allNodeHours(sort: { fields: title, order: ASC }) {
        edges {
          node {
            id
            drupal_id
            title
            relationships {
              field_hours_feeds {
                ... on paragraph__libcal_feed {
                  id
                  field_libcal_feed_name
                  internal {
                    type
                  }
                }
                ... on paragraph__calendar_feed {
                  id
                  relationships {
                    field_google_calendar {
                      field_calendar_id
                    }
                  }
                  internal {
                    type
                  }
                }
                ... on paragraph__cbord_feed {
                  id
                  field_cbord_location
                  internal {
                    type
                  }
                }
              }
            }
            field_additional_info_link {
              uri
            }
            field_location_url {
              uri
            }
            field_location_text
            field_hours_announcement {
              value
              format
            }
            field_hours_category
            field_hours_override
          }
        }
      }
    }
  `)
  const hoursFeeds = data.allNodeHours.edges
  const [hoursOffset, setHoursOffset] = useState([])
  const selectOptions = data.allNodeHours.edges
    .filter(
      // Remove duplicate categories
      (v, i, a) =>
        a.findIndex(
          t => t.node.field_hours_category === v.node.field_hours_category
        ) === i
    )
    .map(e => {
      return e.node.field_hours_category
    })
  const filteredHours = applyFilters(
    hoursFeeds,
    filterSettings,
    hoursOffset,
    setHoursOffset,
    calendarModalDispatch
  )

  return (
    <>
      <HoursFilters
        selectOptions={selectOptions}
        filterSettings={filterSettings}
        filterVisibility={filterVisibility}
        setFilterSettings={setFilterSettings}
        setHoursOffset={setHoursOffset}
      />
      <ResetFilterOptions
        filterDefaults={filterDefaults}
        filterSettings={filterSettings}
        setFilterSettings={setFilterSettings}
        setHoursOffset={setHoursOffset}
        sectionType="Hours"
        sectionData={{
          itemCount: hoursOffset
            ? filteredHours.length - hoursOffset.length
            : filteredHours.length,
        }}
      />

      <HoursKey />
      <div className="mt-4 flex flex-row flex-wrap">
        {/* Just stick with a single column if there are 5 or fewer hours to display */}
        {filteredHours.length <= 5 ? (
          <div>{filteredHours}</div>
        ) : (
          chunk(filteredHours).map((group, index) => (
            <div
              className={"mr-auto " + (index === 0 ? "sm:pr-2" : "")}
              key={index}
            >
              {group}
            </div>
          ))
        )}
      </div>
    </>
  )
}

// Takes array of hours objects and returns object of hours organized by category
const categorizeHours = items => {
  const categorizedHours = {}
  items.forEach(item => {
    if (item.node.field_hours_category) {
      if (!categorizedHours[item.node.field_hours_category]) {
        categorizedHours[item.node.field_hours_category] = []
      }
      categorizedHours[item.node.field_hours_category].push(item)
    }
  })
  return categorizedHours
}

const applyFilters = (
  a,
  filterSettings,
  hoursOffset,
  setHoursOffset,
  calendarModalDispatch
) => {
  let filteredItems = a.filter(e => {
    return filterSettings.category === "All"
      ? true
      : filterSettings.category === e.node.field_hours_category
  })
  if (filterSettings.order === "Z-A") {
    filteredItems = filteredItems.reverse()
  }

  // Sort categorized hours by category title
  const categorizedHoursArr = Object.entries(categorizeHours(filteredItems))
  const sortedCategorizedHours =
    filterSettings.order === "Z-A"
      ? categorizedHoursArr.sort((a, b) => b[0].localeCompare(a[0]))
      : categorizedHoursArr.sort((a, b) => a[0].localeCompare(b[0]))

  return sortedCategorizedHours.map(([category, hours], index) => {
    return (
      <div key={index}>
        <h2 className="mt-3 pb-0.5 text-lg font-bold text-gray-800 underline decoration-primary decoration-2 underline-offset-4 dark:text-gray-200">
          {category}
        </h2>
        {hours.map(({ node }, index) => (
          <HoursFeedItems
            {...node}
            key={index}
            setHoursOffset={setHoursOffset}
            hoursOffset={hoursOffset}
            calendarModalDispatch={calendarModalDispatch}
          />
        ))}
      </div>
    )
  })
}

const HoursFeedItems = props => {
  const setHoursOffset = props.setHoursOffset
  const calendarType = props.relationships.field_hours_feeds.internal.type
  const timeWindow = "4 weeks"
  const calendarId =
    calendarType === "paragraph__calendar_feed"
      ? props.relationships.field_hours_feeds.relationships
          .field_google_calendar.field_calendar_id
      : calendarType === "paragraph__libcal_feed"
      ? props.relationships.field_hours_feeds.field_libcal_feed_name
      : props.relationships.field_hours_feeds.field_cbord_location
  const queryName =
    calendarType === "paragraph__calendar_feed"
      ? "googlecalfeedhours"
      : calendarType === "paragraph__libcal_feed"
      ? "libcalfeed"
      : "cbordnetmenufeedhours"
  const queryVariables = {
    calendarId: calendarId,
    order: "ASC",
    timeWindow: timeWindow,
    // editAccess: paragraphData.editAccess,
  }

  return (
    <DataProvider
      queryName={queryName}
      queryVariables={queryVariables}
      key={props.id}
    >
      <HoursFeedItem
        {...props}
        override={props.field_hours_override}
        setHoursOffset={setHoursOffset}
        queryName={queryName}
      />
    </DataProvider>
  )
}

const HoursFeedItem = props => {
  const { error, loading, data, override, setHoursOffset } = props
  const [hoursToggle, setHoursToggle] = useState(false)
  const currentDate = DateTime.now()
  let openStatus
  let allday = false
  let friendlyOpenStatus = ""
  let hoursByDayArray

  useEffect(() => {
    if (data !== undefined && data.result.data.length === 0)
      setHoursOffset(prevState => {
        if (
          prevState !== undefined &&
          !prevState.includes(data.result.calendarId)
        )
          return [...prevState, data.result.calendarId]
      })
  }, [data, setHoursOffset])

  if (!loading && !error) {
    if (override === true) {
      openStatus = "by_appointment"
      friendlyOpenStatus = "Open by appointment."
      hoursByDayArray = []
    } else {
      let hoursByDay = []
      data.result.data
        .filter((e, i) => {
          return e.status !== "past"
        })
        .forEach(calEvents => {
          const dayName = DateTime.fromISO(calEvents.startdate).weekdayLong
          const dayNumber = DateTime.fromISO(calEvents.startdate).day
          const dayId = dayName + dayNumber
          if (!(dayId in hoursByDay)) {
            hoursByDay[dayId] = {
              id: calEvents.id,
              day: dayName,
              date: dayNumber,
              hours: [calEvents],
              shortdate: DateTime.fromISO(calEvents.startdate).toFormat(
                "yyyy-MM-dd"
              ),
            }
          } else {
            hoursByDay[dayId].hours.push(calEvents)
          }
        })
      hoursByDayArray = Object.keys(hoursByDay).map(function (key) {
        return hoursByDay[key]
      })

      // An array of the current day's complete hours details
      const nextAvailableHoursInfo = hoursByDayArray
        .map((element, i) => {
          return element.hours
        })
        .reduce((previous, current) => previous.concat(current), [])
        .filter(
          // Remove duplicate organizations/categories
          (v, i, a) => a.findIndex(t => t === v) === i
        )
      // An array of the current day's status details
      const nextAvailableHoursStatus = nextAvailableHoursInfo.map(e => e.status)

      if (nextAvailableHoursStatus.includes("open")) {
        openStatus = "open"
        let statusData = []
        let futureOpenHours
        statusData.push(
          nextAvailableHoursInfo.find(({ status }) => status === "open")
        )
        if (nextAvailableHoursStatus.includes("future")) {
          futureOpenHours = nextAvailableHoursInfo.filter(
            ({ status, startdate }) =>
              status === "future" &&
              currentDate.day === DateTime.fromISO(startdate).day
          )
          statusData = statusData.concat(futureOpenHours)
        }
        statusData = statusData.map((e, i) => {
          const startDate = DateTime.fromISO(e.startdate)
          const endDate = DateTime.fromISO(e.enddate)
          return (
            <React.Fragment key={i}>
              {i ? ", " : ""}
              <RangedTimeElement start={startDate} end={endDate} />
            </React.Fragment>
          )
        })

        friendlyOpenStatus = <>Open {statusData}</>
      } else if (nextAvailableHoursStatus.includes("closing")) {
        openStatus = "closing"
        let statusData = []
        let futureOpenHours
        statusData.push(
          nextAvailableHoursInfo.find(({ status }) => status === "closing")
        )
        const friendlyClosingTime = swatDatesUtil(
          DateTime.fromISO(statusData[0].enddate).toLocal().toFormat("h:mm a")
        )
        if (nextAvailableHoursStatus.includes("future")) {
          futureOpenHours = nextAvailableHoursInfo
            .filter(
              ({ status, startdate }) =>
                status === "future" &&
                currentDate.day === DateTime.fromISO(startdate).day
            )
            .map((e, i) => {
              const startDate = DateTime.fromISO(e.startdate)
              const endDate = DateTime.fromISO(e.enddate)
              return (
                <React.Fragment key={i}>
                  {i ? ", " : ""}
                  <RangedTimeElement start={startDate} end={endDate} />
                </React.Fragment>
              )
            })
        }
        friendlyOpenStatus = (
          <>
            Open, closes at{" "}
            <time dateTime={statusData[0].enddate}>{friendlyClosingTime}</time>
            {(friendlyClosingTime === "noon" ||
              friendlyClosingTime === "midnight") && <>.</>}
            {futureOpenHours?.length > 0 && <> Reopens {futureOpenHours}</>}
          </>
        )
      } else if (nextAvailableHoursStatus.includes("by_appointment")) {
        const statusData = nextAvailableHoursInfo.find(
          ({ status }) => status === "by_appointment"
        )
        currentDate.day === DateTime.fromISO(statusData.startdate).day
          ? (friendlyOpenStatus = "Open by appointment")
          : (friendlyOpenStatus = (
              <>
                Closed, opens by appointment{" "}
                <time dateTime={statusData.startdate}>
                  {currentDate.day ===
                  DateTime.fromISO(statusData.startdate).day
                    ? "today"
                    : swatDatesUtil(
                        DateTime.fromISO(statusData.startdate).toFormat(
                          "cccc',' MMM'.' d"
                        )
                      )}
                </time>
              </>
            ))
      } else if (
        nextAvailableHoursStatus.includes("closed") ||
        nextAvailableHoursStatus.includes("future")
      ) {
        const statusData = nextAvailableHoursInfo.find(
          ({ status }) => status === "future"
        )
        openStatus = "closed"
        if (statusData !== undefined) {
          friendlyOpenStatus = (
            <>
              Closed, opens{" "}
              <time dateTime={statusData.startdate}>
                {currentDate.day === DateTime.fromISO(statusData.startdate).day
                  ? "today"
                  : swatDatesUtil(
                      DateTime.fromISO(statusData.startdate).toFormat(
                        "cccc',' MMM'.' d"
                      )
                    )}{" "}
                at{" "}
                {swatDatesUtil(
                  DateTime.fromISO(statusData.startdate)
                    .toLocal()
                    .toFormat("h:mm a")
                )}
              </time>
            </>
          )
        } else {
          friendlyOpenStatus = "Closed"
        }
      } else if (nextAvailableHoursStatus.includes("allday")) {
        const statusData = nextAvailableHoursInfo.find(
          ({ status }) => status === "allday"
        )
        openStatus = "open"
        allday = true
        friendlyOpenStatus =
          statusData.source === "google-calendar"
            ? "Open 24 hours."
            : statusData?.description
      }
      // End location open/closing/closed status check
      hoursByDayArray = hoursByDayArray
        .filter(e => {
          // Filter out days that are beyond a week from today
          return (
            DateTime.fromFormat(e.shortdate, "yyyy-MM-dd") <=
            currentDate.plus({ days: 6 })
          )
        })
        .map(sortedEvents => {
          const { day, date } = sortedEvents
          const hours = sortedEvents.hours
            .filter(hourBlock => {
              return currentDate > DateTime.fromISO(hourBlock.enddate)
                ? false
                : true
            })
            .map((hourBlock, i) => {
              if (hourBlock.status === "allday") {
                return (
                  <li key={i}>
                    <span>24 hours</span>
                  </li>
                )
              } else if (hourBlock.status === "by_appointment") {
                return (
                  <li key={i}>
                    <span>By appointment</span>
                  </li>
                )
              } else {
                const startDate = DateTime.fromISO(hourBlock.startdate)
                const endDate = DateTime.fromISO(hourBlock.enddate)
                return (
                  <li key={i}>
                    <RangedTimeElement start={startDate} end={endDate} />
                  </li>
                )
              }
            })

          if (hours.length) {
            return (
              <li
                className={
                  "flex flex-row justify-between" +
                  (currentDate.day === sortedEvents.date ? " font-bold" : "")
                }
                key={day + date}
              >
                <time dateTime={sortedEvents.shortdate}>{day}</time>
                <ul key={sortedEvents.id} className="mb-0.5 text-right">
                  {hours}
                </ul>
              </li>
            )
          } else {
            return null
          }
        })
    }
    return (
      <div className="mt-3">
        <h3>
          <button
            data-type="toggle"
            data-context="hours listing"
            data-action={hoursToggle ? "collapse" : "expand"}
            data-label={props.title}
            className="text-left"
            type="button"
            onClick={() => setHoursToggle(!hoursToggle)}
          >
            <span className="pointer-events-none">
              <span className="text-md break-words font-bold">
                {props.title}
              </span>
              <span
                className={
                  "ml-1 inline-block " +
                  "hours-label--" +
                  (openStatus ? openStatus : "other")
                }
              >
                {getHoursSymbol(openStatus)}
              </span>
              <ChevronToggle
                chevronClasses={["inline-block", "ml-2"]}
                toggle={hoursToggle}
              />
              <br />
              <span className="prose text-sm dark:prose-dark">
                {data.result.data.length > 0 || override === true
                  ? friendlyOpenStatus
                  : "No hours listed at this time."}
              </span>
            </span>
          </button>
        </h3>
        {(data.result.data.length < 1 ||
          allday === true ||
          override === true) &&
          props.field_additional_info_link !== null && (
            <span className="prose text-sm dark:prose-dark">
              {" "}
              Visit the{" "}
              <a
                className="hover:underline"
                href={props.field_additional_info_link.uri}
                target="_blank"
                rel="noreferrer"
              >
                <span className="tw-sr-only">{props.title} </span>website
              </a>{" "}
              for more information.
            </span>
          )}
        <HoursFeedDisplay
          {...props}
          hoursByDayArray={hoursByDayArray}
          toggle={hoursToggle}
        />
      </div>
    )
  } else {
    return <Skeleton />
  }
}

const HoursFeedDisplay = props => {
  const {
    override,
    hoursByDayArray,
    toggle,
    field_additional_info_link,
    field_location_text,
    field_location_url,
    field_hours_announcement,
    variables,
    calendarModalDispatch,
    queryName,
    data,
  } = props
  const hoursVisibility = !toggle ? ["tw-sr-only"] : []
  const defaultContainerClasses = ["max-w-xs", "mt-2"]
  const combinedContainerClasses = defaultContainerClasses
    .concat(hoursVisibility)
    .join(" ")
  return (
    <div className={combinedContainerClasses}>
      {field_location_text && (
        <div className="prose text-sm dark:prose-dark -mt-1">
          <span className="tw-sr-only">Address: </span>
          <span className="mr-1">
            <FontAwesomeIcon icon={faLocationDot} className="inline-block" />
          </span>
          {field_location_url ? (
            <a href={field_location_url.uri} target="_blank">
              {field_location_text}
            </a>
          ) : (
            { field_location_text }
          )}
        </div>
      )}
      {field_hours_announcement !== null && (
        <div className="relative pl-5 py-3 pr-3 rounded-md border-l-4 border-orange-300 bg-yellow-100 dark:bg-gray-800 mt-1.5 mb-2 prose text-sm dark:prose-dark">
          <div className="-top-1 -left-2 hidden h-6 w-6 items-center justify-center rounded-full bg-white p-2 text-orange-300 dark:bg-gray-900 sm:absolute sm:flex">
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="inline-block"
            />
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: field_hours_announcement.value,
            }}
            className="hours-announcement"
          ></div>
        </div>
      )}
      {hoursByDayArray.length >= 1 && (
        <ul className="p-3 rounded-md bg-gray-100 dark:bg-gray-800 mt-1.5 mb-2">
          {hoursByDayArray}
        </ul>
      )}
      <div className="mt-1 flex flex-row flex-wrap gap-2">
        {field_additional_info_link && (
          <Button
            color="primary"
            url={field_additional_info_link.uri}
            additionalAttr={{
              "data-context": "hours listing",
              target: "_blank",
              rel: "noreferrer",
            }}
            additionalClasses={["flex-1"]}
          >
            Website
          </Button>
        )}
        {override !== true && queryName === "googlecalfeedhours" && (
          <Button
            color="primary"
            url={
              `https://calendar.google.com/calendar/u/0/r?cid=` +
              encodeURIComponent(variables.calendarId)
            }
            icon="plus"
            additionalAttr={{
              "data-context": "hours listing",
              target: "_blank",
              rel: "noreferrer",
            }}
            additionalClasses={["flex-1"]}
          >
            Subscribe
          </Button>
        )}
        {data.result.editAccess ? (
          override !== true ? (
            <Button
              color="dash-blue"
              icon="pencil"
              additionalAttr={{
                "data-context": "hours listing",
                target: "_blank",
                rel: "noreferrer",
              }}
              additionalClasses={["w-full"]}
              onClick={() =>
                calendarModalDispatch({
                  calendarId: variables.calendarId,
                })
              }
            >
              Edit Calendar
            </Button>
          ) : (
            <div className="rounded-md bg-gray-100 p-2 dark:bg-gray-800 dark:text-gray-100">
              <h3 className="mb-1 text-xs font-semibold text-gray-600 dark:text-gray-400">
                <span className="mr-1 text-xs">
                  <FontAwesomeIcon icon={faEye} className="inline-block" />
                </span>
                Only visible to you
              </h3>
              <span className="prose text-sm dark:prose-dark">
                To turn off the "by appointment" message and resume managing
                hours through Google Calendar, please contact{" "}
                <a className="hover:underline" href="dash@swarthmore.edu">
                  dash@swarthmore.edu
                </a>
                .
              </span>
            </div>
          )
        ) : null}
      </div>
    </div>
  )
}

export default HoursFeeds
