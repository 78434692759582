import React, { useState } from "react"

import DataProvider from "../utils/DataProvider"
import swatDatesUtil from "../utils/swatDatesUtil"
import ChevronToggle from "../page-layout/ui/chevron-toggle"

// Icons
import TriangleIcon from "../icons/triangle-icon"
import { DateTime } from "luxon"
import Skeleton from "../page-layout/ui/skeleton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

const SeptaFeeds = () => {
  const [septaToggle, setSeptaToggle] = useState(true)
  const septaVisibility = !septaToggle ? "tw-sr-only" : ""
  // TODO: Pull SEPTA feed config values from Drupal so that they can be managed through CMS
  const toPhilly = {
    friendlyArrivalTitle: "Philly",
    friendlyDepartureTitle: "Swarthmore",
    queryVariables: {
      arrivalStation: "30th%20Street%20Station",
      departureStation: "Swarthmore",
      maxResults: 4,
    },
  }
  const toSwarthmore = {
    friendlyArrivalTitle: "Swarthmore",
    friendlyDepartureTitle: "30th St. Station",
    queryVariables: {
      arrivalStation: "Swarthmore",
      departureStation: "30th%20Street%20Station",
      maxResults: 4,
    },
  }
  const toMedia = {
    friendlyArrivalTitle: "Media/Wawa",
    friendlyDepartureTitle: "Swarthmore",
    queryVariables: {
      arrivalStation: "Media",
      departureStation: "Swarthmore",
      maxResults: 4,
    },
  }

  return (
    <div className="mt-3 mb-6">
      <h2>
        <button
          data-type="toggle"
          data-context="transportation section"
          data-action={septaToggle ? "collapse" : "expand"}
          data-label="SEPTA"
          className="mb-3 text-xl font-bold"
          type="button"
          onClick={() => setSeptaToggle(!septaToggle)}
        >
          <span className="pointer-events-none flex flex-row items-center justify-between">
            SEPTA
            <span className="text-base">
              <ChevronToggle chevronClasses={["ml-2"]} toggle={septaToggle} />
            </span>
          </span>
        </button>
      </h2>
      <div className={septaVisibility}>
        <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
          <SeptaFeedResults septaVariables={toPhilly} />
          <SeptaFeedResults septaVariables={toSwarthmore} />
          <SeptaFeedResults septaVariables={toMedia} />
        </div>
      </div>
    </div>
  )
}

const SeptaFeedResults = props => {
  const {
    friendlyArrivalTitle,
    friendlyDepartureTitle,
    queryVariables,
  } = props.septaVariables
  return (
    <div className="relative block overflow-hidden rounded-md border border-gray-100 dark:border-gray-800">
      <div className="bg-gray-100 dark:bg-gray-800">
        <h3 className="py-2 px-3 text-lg font-bold text-black dark:text-primary-light">
          {friendlyDepartureTitle}
          <span className="mx-2 text-sm text-primary dark:text-primary-light">
            <FontAwesomeIcon icon={faArrowRight} className="inline-block" />
            <span className="tw-sr-only">to</span>
          </span>
          {friendlyArrivalTitle}
        </h3>
      </div>
      <div className="px-3 pt-1.5 pb-3">
        <DataProvider
          queryName="septatrainfeed"
          queryVariables={queryVariables}
        >
          <SeptaFeedItems {...props} />
        </DataProvider>
      </div>
    </div>
  )
}

const SeptaFeedItems = props => {
  const { error, loading, data } = props
  const processedSeptaTimes =
    !loading && !error && data.result.data.length > 0
      ? data.result.data.map((processedSeptaTimes, i) => {
          let delay = false
          let timeClasses = "text-base"
          switch (i) {
            case 0:
              timeClasses = "text-lg font-bold"
              break
            default:
              break
          }
          const meridiems = ["AM", "PM"]
          let departureTime = {
            hours: null,
            minutes: null,
          }
          if (
            meridiems.some(el =>
              processedSeptaTimes.orig_departure_time.includes(el)
            )
          ) {
            processedSeptaTimes.orig_departure_time.includes(meridiems[0])
              ? (departureTime = {
                  hours: parseInt(
                    processedSeptaTimes.orig_departure_time.match(/[^:]*/i)[0]
                  ),
                  minutes: parseInt(
                    processedSeptaTimes.orig_departure_time.match(/([^:])[0-9]/)
                  ),
                })
              : (departureTime = {
                  hours:
                    parseInt(
                      processedSeptaTimes.orig_departure_time.match(/[^:]*/i)[0]
                    ) + 12,
                  minutes: parseInt(
                    processedSeptaTimes.orig_departure_time.match(/([^:])[0-9]/)
                  ),
                })
          }
          if (processedSeptaTimes.orig_delay !== "On time") {
            if (processedSeptaTimes.orig_delay.match(/(min.*)/g) !== null) {
              // If the delay is in minutes, provide the correct abbreviation.
              const regx = /(^[0-9]+)/g
              const delayValue = parseInt(
                processedSeptaTimes.orig_delay.replace(regx, "$1")
              )
              delay = (
                <>
                  {delayValue}{" "}
                  <abbr title={delayValue > 1 ? "Minutes" : "Minute"}>min</abbr>
                </>
              )
            } else {
              delay = <>{processedSeptaTimes.orig_delay}</>
            }
          }
          return (
            <li
              key={processedSeptaTimes.orig_train}
              className={
                "flex flex-row items-center p-2" + (i === 0 ? " pl-0" : "")
              }
            >
              {i === 0 && (
                <TriangleIcon
                  iconClasses="mr-1"
                  textColor="text-gray-700 dark:text-gray-100"
                  width="5px"
                />
              )}
              {processedSeptaTimes.orig_train && (
                <a
                  role="button"
                  target="__blank"
                  rel="noopener"
                  title={`View train #${processedSeptaTimes.orig_train} on SEPTA real-time map`}
                  className="mr-2 inline-block rounded-md bg-gray-300 py-1 px-2 text-center text-xs font-semibold text-black dark:bg-gray-700 dark:text-white"
                  // href="https://www.septa.org/realtime-map/MED?fullscreen=true"
                  href={`https://ng-realtime.septa.org/trip/detail/${processedSeptaTimes.orig_train}`}
                >
                  #{processedSeptaTimes.orig_train}
                </a>
              )}
              <span className={timeClasses}>
                {departureTime.hours !== null &&
                  processedSeptaTimes.orig_departure_time.length && (
                    <time
                      dateTime={DateTime.now()
                        .startOf("day")
                        .plus({
                          hours: departureTime.hours,
                          minutes: departureTime.minutes,
                        })
                        .toISO()}
                    >
                      {swatDatesUtil(processedSeptaTimes.orig_departure_time)}
                    </time>
                  )}
              </span>
              {delay !== false ? (
                <span className="px-1.5 text-sm font-semibold text-dash-red dark:text-red-400">
                  <span className="tw-sr-only">Estimated delay: </span>
                  <span aria-hidden="true">{"+"}</span>
                  <span>{delay}</span>
                </span>
              ) : (
                ""
              )}
            </li>
          )
        })
      : "No times available."
  if (!loading && !error) {
    return <ul>{processedSeptaTimes}</ul>
  } else {
    return <Skeleton />
  }
}

export default SeptaFeeds
