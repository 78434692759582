const numHiddenSections = () => {
  // Grab the # of hidden sections
  if (typeof window !== "undefined") {
    const userLocalSort = JSON.parse(
      window.localStorage.getItem("userLocalSort")
    )
    return userLocalSort
      ? userLocalSort.filter(item => item.hidden === 1).length
      : 0
  }
}

export default numHiddenSections
