import React, { useState } from "react"
import PropTypes from "prop-types"

import Downshift from "downshift"
import { Buffer } from "buffer"
import AxiosDirectorySearch from "../../utils/AxiosDirectorySearch"
import FocusTrap from "focus-trap-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronUp, faTimes } from "@fortawesome/free-solid-svg-icons"

const DirectorySearch = props => {
  const {
    query,
    setQuery,
    isDirectoryTrapActive,
    setIsDirectoryTrapActive,
  } = props
  const [directoryQuery, setDirectoryQuery] = useState("")
  const [queryType, setQueryType] = useState("general")
  const baseEndpoint = "https://www.swarthmore.edu/directory/autocomplete/"
  const handleDirectorySubmit = e => {
    e.preventDefault()
    let searchData = {}
    let dataKey = queryType
    searchData[queryType] = directoryQuery !== "" ? directoryQuery : query
    searchData =
      "a:1:{s:" +
      dataKey.length +
      ':"' +
      dataKey +
      '";s:' +
      searchData[dataKey].length +
      ':"' +
      searchData[dataKey] +
      '";}'
    const hash = Buffer.from(searchData, "utf-8").toString("base64")
    window.open(
      `https://www.swarthmore.edu/directory/${encodeURIComponent(hash).replace(
        /%20/g,
        "+"
      )}#campus-directory-results`,
      "_blank"
    )
  }

  return (
    <FocusTrap
      active={isDirectoryTrapActive}
      focusTrapOptions={{
        allowOutsideClick: () => setIsDirectoryTrapActive(false),
        escapeDeactivates: () => setIsDirectoryTrapActive(false),
      }}
    >
      <Downshift
        inputValue={query}
        itemToString={item => (item ? item.value : "")}
        onStateChange={(changes, selectedItem) => {
          if (
            changes.hasOwnProperty("selectedItem") &&
            changes.selectedItem !== null
          ) {
            setDirectoryQuery(changes.selectedItem[0])
            setQuery(changes.selectedItem[1])
            setQueryType("general_callback")
          } else if (
            changes.hasOwnProperty("inputValue") &&
            changes.inputValue !== undefined
          ) {
            setQuery(changes.inputValue)
            setDirectoryQuery(changes.inputValue)
            setQueryType("general")
          }
        }}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          getToggleButtonProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          clearSelection,
          getRootProps,
        }) => (
          <form
            role="search"
            className="relative"
            onSubmit={handleDirectorySubmit}
          >
            <label {...getLabelProps()} className="tw-sr-only">
              Search the campus directory:
            </label>
            <div {...getRootProps({}, { suppressRefError: true })}>
              <DownshiftInput
                {...getInputProps(
                  {
                    onKeyDown: event => {
                      if (event.key === "Escape") {
                        event.nativeEvent.preventDownshiftDefault = true
                        clearSelection()
                        setIsDirectoryTrapActive(false)
                      }
                    },
                    isOpen,
                    placeholder: "Search the Directory",
                    inputValue: query,
                  },
                  { suppressRefError: true }
                )}
                type="text"
                className="mt-2 block w-full rounded-md border-gray-300 text-xs shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:border-none dark:bg-gray-700 dark:placeholder-gray-100"
                value={query}
              />
              {selectedItem ? (
                <button
                  {...getToggleButtonProps()}
                  aria-label="clear selection"
                  data-type="toggle"
                  data-context="directory search"
                  data-action="clear"
                  className="absolute right-0 top-0 flex h-full w-12 cursor-pointer flex-col items-center justify-center border-none bg-transparent dark:text-gray-100 "
                  onClick={clearSelection}
                >
                  <span className="pointer-events-none">
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="mr-1 inline-block text-base text-gray-500 dark:text-gray-100"
                    />
                    <span className="tw-sr-only">Clear Search Input</span>
                  </span>
                </button>
              ) : (
                <button
                  {...getToggleButtonProps()}
                  className="absolute right-0 top-0 flex h-full w-12 cursor-pointer flex-col items-center justify-center border-none bg-transparent dark:text-gray-100"
                >
                  <FontAwesomeIcon
                    icon={faChevronUp}
                    className={
                      "mr-1 inline-block text-base text-gray-500 transition dark:text-gray-100 " +
                      (isOpen && "rotate-180")
                    }
                  />
                </button>
              )}
            </div>
            <ul
              {...getMenuProps({
                onKeyDown: event => {
                  if (event.key === "Escape") {
                    clearSelection()
                    setIsDirectoryTrapActive(false)
                  }
                },
              })}
              className={
                "absolute mt-0 max-h-80 w-full overflow-y-auto overflow-x-hidden rounded-md rounded-t-none border-t-0 border-gray-300 bg-white p-0 outline-none transition-opacity dark:border-gray-700 dark:bg-gray-700 " +
                (isOpen &&
                  "border-r border-b border-l text-sm text-gray-800 dark:-mt-[0.23rem] dark:text-gray-100")
              }
            >
              {(() => {
                if (!isOpen) {
                  return null
                }

                if (!inputValue) {
                  return (
                    <li disabled className="px-2.5 py-1">
                      Enter a search query
                    </li>
                  )
                }

                return (
                  <AxiosDirectorySearch
                    url={baseEndpoint}
                    params={{ query: inputValue }}
                  >
                    {({ loading, error, data }) => {
                      if (loading) {
                        return (
                          <DownshiftItem disabled className="px-2.5 py-1">
                            Loading...
                          </DownshiftItem>
                        )
                      }

                      if (error) {
                        return (
                          <DownshiftItem disabled>
                            Error! ${error}
                          </DownshiftItem>
                        )
                      }

                      if (data === null || data === undefined) {
                        return (
                          <DownshiftItem disabled>
                            No matches found
                          </DownshiftItem>
                        )
                      }
                      return Object.entries(data).map((item, index) => {
                        return (
                          <DownshiftItem
                            key={item[0]}
                            data-query={item[0]}
                            {...getItemProps({
                              item,
                              index,
                              isActive: highlightedIndex === index,
                              isSelected: selectedItem === item,
                            })}
                          >
                            {item[1]}
                          </DownshiftItem>
                        )
                      })
                    }}
                  </AxiosDirectorySearch>
                )
              })()}
            </ul>
          </form>
        )}
      </Downshift>
    </FocusTrap>
  )
}
// DownshiftInputs accepts the `getInputProps` provided by Downshift
const DownshiftInput = props => {
  const { isOpen, inputValue, ...rest } = props
  return <input {...rest} />
}
// DownshiftItem accepts the `getItemProps` provided by Downshift
const DownshiftItem = props => {
  const { isActive, isSelected, ...rest } = props
  const defaultClasses = [
    "relative",
    "cursor-pointer",
    "block",
    "border-none",
    "h-auto",
    "text-left",
    "border-t-0",
    "py-0.5",
    "px-1",
    "whitespace-normal",
  ]
  let conditionalClasses = []
  if (props.isActive)
    conditionalClasses.push("bg-gray-300", "dark:text-gray-900")
  return (
    <li
      {...rest}
      className={defaultClasses.concat(conditionalClasses).join(" ")}
    />
  )
}

DirectorySearch.propTypes = {
  query: PropTypes.string,
  setQuery: PropTypes.func,
}

export default DirectorySearch
