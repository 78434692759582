import * as React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { Settings } from "./settings"
import { UserContext } from "../../utils/UserContext"
import CriticalAnnouncementFeed from "../header/critical-announcement"

import DashLogoCircularMobile from "../../logos/dash-logo-circular-mobile"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTh, faSearch } from "@fortawesome/free-solid-svg-icons"

const MobileNavBar = props => {
  const [userState, userStateUpdate] = React.useContext(UserContext)

  const {
    setMobileNavOpen,
    mobileNavOpen,
    mobileSearchOpen,
    setMobileSearchOpen,
    criticalAnnouncementOffset,
    setCriticalAnnouncementOffset,
  } = props

  return (
    <>
      <CriticalAnnouncementFeed
        location="mobile"
        criticalAnnouncementOffset={criticalAnnouncementOffset}
        setCriticalAnnouncementOffset={setCriticalAnnouncementOffset}
      />
      <nav
        id="mobile-navigation-toggle"
        className={
          "sticky top-0 z-30 grid grid-cols-3 items-center bg-primary px-1.5 dark:bg-gray-900 md:hidden"
        }
        style={{ height: "var(--mobile-nav-height)" }}
      >
        <h1 className="tw-sr-only">Mobile Navigation</h1>
        <button
          aria-label="Open Primary Navigation"
          aria-haspopup="true"
          aria-controls="primary-navigation"
          aria-expanded={mobileNavOpen}
          data-type="toggle"
          data-context="mobile navigation"
          data-action="open"
          className="navbar-icon navbar-icon--square justify-self-start text-white hover:text-black xs-max:-mr-4"
          onClick={() => setMobileNavOpen(true)}
        >
          <span className="pointer-events-none">
            <FontAwesomeIcon icon={faBars} className="inline-block text-lg" />
            <span className="tw-sr-only">Show Mobile Navigation</span>
          </span>
        </button>
        <div className="z-50 m-auto">
          <DashLogoCircularMobile width="75px" textColor="text-white" />
        </div>
        <div className="flex items-center justify-end gap-2 xs-max:gap-0">
          <Link
            data-type="toggle"
            data-context="mobile navigation"
            data-action={`${mobileNavOpen ? "close" : "open"} services menu`}
            to="#services-menu"
            aria-label="Services Menu"
            onClick={() => setMobileNavOpen(true)}
            className="navbar-icon navbar-icon--circular text-white hover:text-black"
          >
            <span className="pointer-events-none">
              <span>
                <FontAwesomeIcon icon={faTh} className="inline-block text-lg" />
              </span>
              <span className="tw-sr-only">View Services</span>
            </span>
          </Link>
          <button
            data-type="toggle"
            data-context="mobile navigation"
            data-action={`${
              mobileSearchOpen ? "close" : "open"
            } search options`}
            aria-label="Search options"
            onClick={() => setMobileSearchOpen(!mobileSearchOpen)}
            className={
              "navbar-icon navbar-icon--circular text-white hover:text-black " +
              (mobileSearchOpen ? "navbar-icon--active" : "")
            }
          >
            <span className="pointer-events-none">
              <span>
                <FontAwesomeIcon
                  icon={faSearch}
                  className="inline-block text-lg"
                />
              </span>
              <span className="tw-sr-only">View Search options</span>
            </span>
          </button>
          <div aria-label="Settings" className="text-white hover:text-black">
            <Settings
              editAccess={userState?.editAccess || []}
              displayStyle="mobile navigation"
            />
          </div>
        </div>
      </nav>
    </>
  )
}

MobileNavBar.propTypes = {
  setMobileNavOpen: PropTypes.func,
  mobileNavOpen: PropTypes.bool,
}

export default MobileNavBar
