import React, { useState, useRef } from "react"
import { DateTime } from "luxon"
import sanitizeHtml from "sanitize-html"

import DataProvider from "../utils/DataProvider"
import hexToRGBA from "../utils/hexToRGBA"
import RangedTimeElement from "../utils/RangedTimeElement"
import EventsFilters from "./filters/events-filters"
import ResetFilterOptions from "./filters/reset-filter-options"
import Button from "../../components/buttons/button"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserCircle, faTimes } from "@fortawesome/free-solid-svg-icons"
import Skeleton from "../page-layout/ui/skeleton"

const EventsFeed = props => {
  const filterDefaults = {
    range: "Today",
    category: "All",
    allday: true,
  }
  const [filterSettings, setFilterSettings] = useState(filterDefaults)
  const days = 30
  const currentOnly = true
  const queryVariables = {
    currentOnly: currentOnly,
    days: days,
  }

  return (
    <>
      <DataProvider queryName="swatcentralfeed" queryVariables={queryVariables}>
        <EventsFeedItems
          {...props}
          filterDefaults={filterDefaults}
          filterSettings={filterSettings}
          setFilterSettings={setFilterSettings}
        />
      </DataProvider>
    </>
  )
}

// TODO: Determine whether the format date as mocked up in wireframes is OK or whether we should use the formatteddate provided by the calendar. Currently, neither exactly matches the College style guide
const EventsFeedItems = props => {
  const {
    error,
    loading,
    data,
    filterDefaults,
    filterSettings,
    setFilterSettings,
    filterVisibility,
  } = props
  //Get all events
  const allEvents = !loading && !error ? data.result.data : []
  // Applies the current include/exclude "All Day Events" toggle value from `filterSettings`
  function filterAllDay(item) {
    return filterSettings.allday ? true : item.allday === filterSettings.allday
  }
  // Applies the current range value from `filterSettings`
  function filterRange(item) {
    const eventStartDate = DateTime.fromISO(item.startdate)
    switch (filterSettings.range) {
      case "Today":
        return eventStartDate.hasSame(DateTime.local(), "day")
      case "Week":
        return eventStartDate.hasSame(DateTime.local(), "week")
      default:
        return true
    }
  }
  // Builds the list of possible select options to be used in the categrory filter
  const selectOptions = allEvents.length
    ? allEvents
        .filter(filterAllDay)
        .filter(filterRange)
        .map(option => option.organization)
        .reduce((previous, current) => previous.concat(current), [])
        .filter(
          // Remove duplicate organizations/categories
          (v, i, a) => a.findIndex(t => t === v) === i
        )
        .map((option, i) => option)
    : []

  // Applies the current category from `filterSettings`.
  function filterDepartment(a) {
    return function (item, i) {
      if (filterSettings.category === "All") {
        return true
      } else {
        return item.organization.includes(filterSettings.category)
      }
    }
  }
  // Applies the filter functions to `allEvents` and returns the processed events
  const processedEvents = allEvents
    .filter(filterAllDay)
    .filter(filterRange)
    .filter(filterDepartment(selectOptions))
    .map(processedEvent => {
      const labelColor = "000000",
        rgbVal = hexToRGBA(labelColor, 0.1)
      const {
        title,
        startdate,
        enddate,
        description,
        id,
        url,
        eventactionurl,
        formatteddate,
        location,
        organization,
        allday,
      } = processedEvent

      let shouldDisplayDescription = false
      if (
        typeof HTMLElement !== "undefined" &&
        HTMLElement.prototype.hasOwnProperty("popover")
      ) {
        let sanitizedDescription = description
          ? sanitizeHtml(description, { allowedTags: [] })
              .toLowerCase()
              .replace(/[^\w\s](?=\s*$)/g, "")
          : false

        shouldDisplayDescription =
          description && title.toLowerCase() !== sanitizedDescription
      }

      return (
        <div key={id} className="flex w-full rounded-md">
          <div className="flex items-center justify-center bg-gray-100 font-bold uppercase text-primary dark:bg-gray-800 dark:text-primary-light mr-2 flex-col rounded-md p-3">
            <span className="text-xl">
              {DateTime.fromISO(startdate).toLocaleString({ day: "numeric" })}
            </span>
            <span>
              {DateTime.fromISO(startdate).toLocaleString({ month: "short" })}
            </span>
          </div>
          <div className="flex flex-col justify-center p-2">
            <header>
              <h2 className="text-md mb-1 font-bold">
                <a
                  href={url}
                  rel="noreferrer"
                  target="_blank"
                  className="hover:underline"
                >
                  {title}
                </a>
              </h2>
            </header>
            {formatteddate && (
              <div className="mb-1 text-sm font-semibold">
                <RangedTimeElement
                  start={DateTime.fromISO(startdate)}
                  end={DateTime.fromISO(enddate)}
                  rangeType="fulldate"
                  allDay={allday}
                />
                {allday && (
                  <span
                    style={{
                      backgroundColor: rgbVal,
                      borderColor: labelColor,
                      borderWidth: "1px",
                    }}
                    className="!dark:bg-opacity-30 ml-1 inline-block rounded-md py-0.5 px-1 text-center text-xs font-semibold capitalize text-black dark:text-white"
                  >
                    All Day
                  </span>
                )}
              </div>
            )}
            {location && (
              <div className="mb-1 text-sm text-gray-600 dark:text-gray-200">
                {location}
              </div>
            )}
            {organization.length > 0 && (
              <div
                className="mb-1 text-sm text-black dark:text-gray-100"
                title="Event Organizer"
              >
                <FontAwesomeIcon
                  icon={faUserCircle}
                  className="mr-1 inline-block"
                />
                {organization.map(loopOrgs)}
              </div>
            )}
            <div className="flex gap-2">
              {shouldDisplayDescription && (
                <>
                  <button
                    className="text-blue-700 text-sm transition hover:underline dark:text-blue-400"
                    id={`toggle-description-${id}`}
                    popovertarget={`toggle-description-body-${id}`}
                  >
                    Description{" "}
                    <span className="tw-sr-only">
                      {" "}
                      for {title} {"("}
                      {
                        <RangedTimeElement
                          start={DateTime.fromISO(startdate)}
                          end={DateTime.fromISO(enddate)}
                          rangeType="fulldate"
                        />
                      }
                      {")"}
                    </span>
                  </button>{" "}
                  <div
                    className="text-md max-h-[70%] sm-max:w-[90%] max-w-[65ch] rounded-md bg-white p-0 shadow-2xl dark:bg-gray-700 dark:text-white"
                    id={`toggle-description-body-${id}`}
                    popover="auto"
                  >
                    <header className="rounded-t-md bg-primary py-1 px-2 text-white sticky top-0">
                      <h1 className="inline-block font-bold w-[90%]">
                        {title} {"("}
                        {
                          <RangedTimeElement
                            start={DateTime.fromISO(startdate)}
                            end={DateTime.fromISO(enddate)}
                            rangeType="fulldate"
                          />
                        }
                        {")"}
                      </h1>
                      <button
                        data-context={`Event description popover for ${title}, ${DateTime.fromISO(
                          startdate
                        ).toLocaleString()}`}
                        data-action="close"
                        popovertarget={`toggle-description-body-${id}`}
                        popovertargetaction="hide"
                        className="float-right"
                      >
                        <span className="pointer-events-none">
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="inline-block"
                          />
                          <span className="tw-sr-only">Close Settings</span>
                        </span>
                      </button>
                    </header>
                    <div className="text-md h-content px-3 pb-3 prose dark:prose-dark">
                      <div
                        className="my-1"
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(description, {
                            allowedTags: [
                              "b",
                              "i",
                              "em",
                              "strong",
                              "a",
                              "p",
                              "br",
                              "ul",
                              "ol",
                              "li",
                              "h1",
                              "h2",
                              "h3",
                            ],
                            allowedAttributes: {
                              a: ["href"],
                            },
                            exclusiveFilter: function (frame) {
                              return frame.tag === "p" && !frame.text.trim()
                            },
                            transformTags: {
                              h1: sanitizeHtml.simpleTransform("strong"),
                              h2: sanitizeHtml.simpleTransform("strong"),
                              h3: sanitizeHtml.simpleTransform("strong"),
                            },
                          }),
                        }}
                      ></div>
                      <Button
                        color="primary"
                        url={url}
                        srText={`View full event details for ${title}, ${DateTime.fromISO(
                          startdate
                        ).toLocaleString()}`}
                        additionalAttr={{
                          "data-context": `Event description popover for ${title}, ${DateTime.fromISO(
                            startdate
                          ).toLocaleString()}`,
                          target: "__blank",
                          rel: "noopener",
                        }}
                        additionalClasses={[
                          "hover:no-underline",
                          "dark:hover:no-underline",
                        ]}
                      >
                        View All Details
                      </Button>
                    </div>
                  </div>
                  <span className="text-sm text-gray-300">&#8226;</span>
                </>
              )}
              {eventactionurl && (
                <a
                  href={eventactionurl}
                  rel="noreferrer"
                  target="_blank"
                  className="text-sm text-blue-700 transition hover:underline dark:text-blue-400"
                >
                  Add to Calendar
                  <span className="tw-sr-only">- {title}</span>
                </a>
              )}
            </div>
          </div>
        </div>
      )
    })

  const eventsSectionRef = useRef(null)
  const scrollToPrevious = () => {
    eventsSectionRef.current.scrollIntoView()
  }
  const defaultNumEventsToShow = 15
  const [sliceEnd, setSliceEnd] = useState(defaultNumEventsToShow)
  const hasMoreEvents =
    processedEvents.length > processedEvents.slice(0, sliceEnd).length

  if (!loading & !error) {
    return (
      <>
        <EventsFilters
          filterSettings={filterSettings}
          filterVisibility={filterVisibility}
          setFilterSettings={setFilterSettings}
          selectOptions={selectOptions}
          setSliceEnd={setSliceEnd}
          defaultNumEventsToShow={defaultNumEventsToShow}
        />
        <ResetFilterOptions
          filterDefaults={filterDefaults}
          filterSettings={filterSettings}
          setFilterSettings={setFilterSettings}
          sectionType="Events"
          sectionData={{ itemCount: processedEvents.length }}
        />

        {processedEvents.length ? (
          processedEvents.length > defaultNumEventsToShow ? (
            <>
              <div className="grid grid-cols-1 gap-3 " ref={eventsSectionRef}>
                {processedEvents.slice(0, sliceEnd)}
              </div>
              <div className="mt-5 flex w-full items-center justify-center">
                {hasMoreEvents ? (
                  <Button
                    onClick={() =>
                      setSliceEnd(
                        prevSliceEnd => prevSliceEnd + defaultNumEventsToShow
                      )
                    }
                    icon="arrowDown"
                  >
                    Show More Events for {filterSettings.range}
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setSliceEnd(defaultNumEventsToShow)
                      scrollToPrevious()
                    }}
                    icon="arrowUp"
                  >
                    Show Less
                  </Button>
                )}
              </div>
            </>
          ) : (
            <div className="grid grid-cols-1 gap-3">{processedEvents}</div>
          )
        ) : (
          <div className="my-5">
            <p>
              There are currently no events scheduled for today. Looking for
              something fun to do?{" "}
              <a
                href="https://www.swarthmore.edu/meet-swarthmore/swarthmore-bucket-list"
                target="_blank"
                rel="noreferrer"
                className="text-blue-700 hover:underline"
              >
                Check out our Swattie bucket list
              </a>
              !
            </p>
          </div>
        )}
      </>
    )
  } else {
    return <Skeleton />
  }
}

function loopOrgs(org, i, array) {
  let divider = i < array.length - 1 ? true : false
  return (
    <div className="inline-block" key={i}>
      <span>{org}</span>
      {divider && <span className="mx-0.5">/</span>}
    </div>
  )
}

export default EventsFeed
