import React, { useEffect } from "react"
import PropTypes from "prop-types"

import AnnouncementFeed from "./paragraphs/announcement-feed"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

const AnnouncementsSection = props => {
  const {
    activeAnnouncements,
    showAnnouncementsLink,
    setShowAnnouncementsLink,
    nodeData,
  } = props
  useEffect(() => {
    activeAnnouncements.length
      ? setShowAnnouncementsLink(true)
      : setShowAnnouncementsLink(false)
  })
  return (
    <section
      id={nodeData.title.replace(/\s+/g, "-")}
      className={
        "relative pb-4 " +
        (showAnnouncementsLink
          ? "border-b-2 border-gray-100 dark:border-gray-700"
          : "hidden")
      }
    >
      <div className="top-9 -left-4 hidden h-9 w-9 items-center justify-center rounded-full bg-white p-3 text-primary dark:bg-gray-900 sm:absolute sm:flex">
        <FontAwesomeIcon icon={faExclamationCircle} className="inline-block" />
      </div>
      <h1 className="mb-2 text-2xl font-bold">Announcements</h1>
      <div className="grid grid-cols-1 rounded-md rounded-l-sm border-l-4 border-primary bg-red-50 py-2 pr-2 pl-3 text-black dark:bg-gray-800 sm:pl-6">
        <div className="divide divide-y divide-gray-300">
          <AnnouncementsSectionData {...props} />
        </div>
      </div>
    </section>
  )
}

const AnnouncementsSectionData = ({ nodeData, setActiveAnnouncements }) => {
  if (nodeData.relationships.field_feed_sources.length >= 1) {
    return (
      <>
        {nodeData.relationships.field_feed_sources.map((paragraph, index) => {
          switch (paragraph.internal.type) {
            case "paragraph__calendar_feed":
              return (
                <AnnouncementFeed
                  nodeData={nodeData}
                  paragraphData={paragraph}
                  key={index}
                  setActiveAnnouncements={setActiveAnnouncements}
                />
              )
            default:
              return null
          }
        })}
      </>
    )
  } else {
    return null
  }
}

AnnouncementsSection.propTypes = {
  nodeData: PropTypes.object,
}

export default AnnouncementsSection
