import React from "react"
import PropTypes from "prop-types"

/**
 * The mobile version of the (circular) Dash logo used when top navigation is too short to
 * accommodate the circular logo. Text color can be customized to match theme.
 */
const DashLogoCircularMobile = props => {
  const { width, textColor } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="100%"
      viewBox="0 0 83.2238 39.2316"
      aria-hidden="true"
    >
      <title>Swarthmore College Dash Logo</title>
      <g id="a1a5c9d1-8e7f-4d4b-92b3-40ed1b5aae5c" data-name="Layer 2">
        <g id="b53ede29-584e-4989-ab6d-4dc5432e372a" data-name="Layer 1">
          <path
            d="M40.0067,0V.5264H36.5633V8.124h-1.6V.5264H31.5194V0Z"
            className={`fill-current ${textColor}`}
          />
          <path
            d="M43.17,4.2275V8.124h-1.6V0h1.6V3.7h4.7783V0h1.6V8.124h-1.6V4.2275Z"
            className={`fill-current ${textColor}`}
          />
          <path
            d="M58.7508,0V.5264H53.7362V3.4307h3.3574V3.958H53.7362V7.5967h5.0146V8.124H52.1366V0Z"
            className={`fill-current ${textColor}`}
          />
          <g id="a9ffaf24-74dd-460f-a5a6-7e3d5b5a58c2" data-name="Dash Logo">
            <g id="bafcf8e7-780a-43b6-857f-af5e2c087327" data-name="Group 176">
              <g
                id="ff707ff0-27b7-4ca6-a944-3446b4dc3c93"
                data-name="Dash"
                style={{ isolation: "isolate" }}
              >
                <g style={{ isolation: "isolate" }}>
                  <path
                    d="M6.84,9.6787c-.2149-.581-.1641-.9.5244-1.01,3.6533-.498,6.291-2.6318,9.7324-3.1816,3.3887-.55,6.0488,1.1846,8.0283,4.6182C30.0487,18.5576,20.703,34.083,6.5272,38.88a3.13,3.13,0,0,1-3.3936-.3516A11.4163,11.4163,0,0,1,.8953,36.2627C-.1193,34.89-.3888,34.0967.7039,30.6465c1.6153-4.9912,5.9668-13.2822,9.5928-18.7608A3.8317,3.8317,0,0,1,6.84,9.6787Zm4.916,7.2324C9.1326,21.6426,4.88,28.7139,3.3709,33.5986c-.6767,2.23-.3008,2.9688,1.2852,2.378,14.4365-5.5938,21.0273-20.7862,17.873-25.75C20.658,7.27,16.8045,10.1,13.1024,11.3389,13.3739,12.6631,13.0672,14.5723,11.7557,16.9111Z"
                    className={`fill-current ${textColor}`}
                  />
                  <path
                    d="M42.8182,17.7959q.71-1.0371,1.2705-.3242c.8.9492,1.2363,3.2246-.0205,5.8808-1.1514,2.3916-3.7793,6.3809-5.0352,9.1963-1.3086,2.9756-1.3486,5.2549.4532,5.1387,2.1718-.1182,5.0664-3.7393,6.9-7.4053.2626-.4785.6328-.5332.8476-.0576a4.7584,4.7584,0,0,1-.459,3.2881c-1.3613,2.9228-4.6826,5.8564-7.5986,5.7139-2.8623-.1436-4.3555-1.8311-4.3692-4.3213-1.6835,2.1826-4.0576,4.1562-6.6,4.1709-4.9287.0273-5.9737-6.6973-1.6114-13.1338,4.2569-6.2774,8.43-8.6846,11.0791-8.6992,2.2793-.0137,3.3487,1.7285,3.2539,3.7959C41.61,19.7637,42.24,18.6475,42.8182,17.7959Zm-4.2266,2.1953c-1.6972-.1474-5.5459,3.3184-8.4336,8.05-2.7832,4.5742-3.4453,9.2422-1.3789,9.2832,2.1729.04,4.91-3.3672,7.586-8.0449C39.6707,23.4307,40.1287,20.1426,38.5916,19.9912Z"
                    className={`fill-current ${textColor}`}
                  />
                  <path
                    d="M53.4871,20.3848a5.7382,5.7382,0,0,1,1.8848-4.0909c1.16-1.0654,2.3779-1.2841,2.8574-.8105.5332.5273.2715,1.1113-.4111,2.334-.21.32-.3672.6377-.5762,1.0107-.3437,4.876.6865,9.0039.5479,12.6621-.0948,2.0674-.4532,4.295-2.3535,5.5772,3.5429-1.3447,5.75-4.5371,7.06-7.4053.2608-.585.792-.43,1.0069-.0059a4.8012,4.8012,0,0,1-.7227,3.501,11.1638,11.1638,0,0,1-9.3467,5.8819c-3.0732.124-6.0449-.6543-7.8652-3.93-1.2852-2.378-1.1465-5.876.6475-7.37.6328-.5332,1.0576-.3233,1.1162.5234.0547.4238.0576.8477.1132,1.2715A105.0993,105.0993,0,0,0,53.4871,20.3848Zm-5.55,12.0088c.8125,3.0683,2.3614,5.2324,4.1075,4.6923,2.4335-.7548,1.5918-9.07,1.3554-13.3086A93.0389,93.0389,0,0,1,47.9373,32.3936Z"
                    className={`fill-current ${textColor}`}
                  />
                  <path
                    d="M74.7381,38.9189c-4.4512.0254-5.583-3.2011-4.0185-7.82,1.72-5.15,6.2988-10.6875,5.4482-11.16-.7441-.418-6.501,3.2168-9.5459,8.2685a69.4406,69.4406,0,0,0-5.083,10.0977c-.21.4785-.58.5869-.9.2178-.8525-.9493-1.3379-2.4307-.2432-5.6163C62.22,27.5957,73.1375,8.8818,77.4,3.5049c.4737-.6387,1.003-.5889,1.2715.0449.6982,1.5332.5537,4.1836-1.4414,7.5342-1.26,2.126-4.0439,6.4355-6.3027,9.998,2.1611-2.1318,4.9072-3.8965,6.3388-3.7451,1.8028.2022,3.46,2.8408,2.0977,5.5518-1.1524,2.3388-3.6748,6.2207-4.8779,8.9834-1.3077,3.081-1.0284,5.5713.9316,5.4531,2.1719-.1172,5.0654-3.9492,6.6895-7.3506a.5063.5063,0,0,1,.9531-.0586c.2685.5811.2744,1.6944-.5117,3.2891C81.0819,36.2344,77.8651,38.9014,74.7381,38.9189Z"
                    className={`fill-current ${textColor}`}
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

DashLogoCircularMobile.propTypes = {
  /** Sets logo width using passed unite (eg, `<svg width={"85px"} />)`. Height has been hardcoded to 100% to avoid scaling issues. */
  width: PropTypes.string,
  /** Set the fill color of the text via Tailwind's `text-[color]` class */
  textColor: PropTypes.string,
}

export default DashLogoCircularMobile
