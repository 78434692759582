import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { DateTime } from "luxon"
import truncate from "truncate-html"
import DataProvider from "../../utils/DataProvider"
import TimeSincePost from "../../utils/TimeSincePost"
import swatDatesUtil from "../../utils/swatDatesUtil"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons"

const AnnouncementFeed = ({
  paragraphData,
  nodeData,
  setActiveAnnouncements,
}) => {
  const timeWindow = "1 day"
  const calendarId =
    paragraphData.relationships.field_google_calendar.field_calendar_id
  const queryVariables = {
    calendarId: calendarId,
    // editAccess: paragraphData.editAccess,
    order: "DESC",
    // maxResults: paragraphData.maxResults,
    timeWindow: timeWindow,
  }

  return (
    <DataProvider queryName="googlecalfeed" queryVariables={queryVariables}>
      <AnnouncementFeedItems
        {...paragraphData}
        setActiveAnnouncements={setActiveAnnouncements}
        calendarId={calendarId}
        nodeData={nodeData}
      />
    </DataProvider>
  )
}

const AnnouncementFeedItem = props => {
  const { googleCalendarEvent } = props
  const [toggle, setToggle] = useState(false)
  const truncationLength = 120
  const shouldExpand =
    truncate(googleCalendarEvent.description, {
      length: truncationLength,
      stripTags: true,
    }).length >= truncationLength
  function createMarkup() {
    return (
      <>
        {shouldExpand ? (
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: toggle
                  ? googleCalendarEvent.description
                  : truncate(googleCalendarEvent.description, {
                      length: truncationLength,
                    }),
              }}
            />
            <button
              data-type="toggle"
              data-context="announcement feed item"
              data-action={toggle ? "collapse" : "expand"}
              data-label={googleCalendarEvent.title}
              onClick={() => setToggle(!toggle)}
              className={`transition ml-1 font-extrabold inline-block text-black hover:text-slate-700 dark:text-gray-100 dark:hover:text-gray-300`}
            >
              {toggle ? "Read Less" : "Read More"}
              <FontAwesomeIcon
                icon={toggle ? faCaretLeft : faCaretRight}
                className={"ml-1 inline-block text-xs"}
              />
            </button>
          </>
        ) : (
          <span
            dangerouslySetInnerHTML={{
              __html: googleCalendarEvent.description,
            }}
          />
        )}
      </>
    )
  }

  return (
    <>
      <h2 className="text-md prose mb-0.5 font-bold dark:prose-dark">
        {googleCalendarEvent.title}
      </h2>
      <p className="prose m-0.5 max-w-none dark:prose-dark">{createMarkup()}</p>

      <span className="text-xs text-gray-700 dark:text-gray-300">
        {typeof Intl.RelativeTimeFormat === "function" ? (
          <TimeSincePost timestamp={googleCalendarEvent.timestamp} />
        ) : (
          <>
            Last updated on{" "}
            <time dateTime={googleCalendarEvent.timestamp.toISO()}>
              {swatDatesUtil(
                googleCalendarEvent.timestamp.toFormat("MMM'.' d',' yyyy")
              )}{" "}
              at{" "}
              {swatDatesUtil(googleCalendarEvent.timestamp.toFormat("h:mm a"))}
            </time>
          </>
        )}
      </span>
    </>
  )
}

const AnnouncementFeedItems = props => {
  const { error, loading, data, calendarId, setActiveAnnouncements } = props
  const googleCalendarEvents =
    !loading && !error
      ? data.result.data
          .filter(item => {
            const currentTime = DateTime.now()
            const startDate = DateTime.fromISO(item.startdate)
            const endDate = DateTime.fromISO(item.enddate)

            if (
              item.allday ||
              (currentTime >= startDate && currentTime <= endDate)
            ) {
              return true
            } else {
              return false
            }
          })
          .map(googleCalendarEvents => {
            let timestamp
            let modified
            if (googleCalendarEvents.updated !== null) {
              if (
                DateTime.fromISO(googleCalendarEvents.startdate).toLocal() >
                DateTime.fromISO(googleCalendarEvents.updated).toLocal()
              ) {
                timestamp = DateTime.fromISO(
                  googleCalendarEvents.startdate
                ).toLocal()
                modified = false
              } else {
                timestamp = DateTime.fromISO(
                  googleCalendarEvents.updated
                ).toLocal()
                modified = true
              }
            } else {
              timestamp = DateTime.fromISO(
                googleCalendarEvents.startdate
              ).toLocal()
              modified = false
            }
            return {
              ...googleCalendarEvents,
              timestamp: timestamp,
              modified: modified,
            }
          })
          .sort((a, b) => {
            return b.timestamp < a.timestamp
              ? -1
              : b.timestamp > a.timestamp
              ? 1
              : 0
          })
          .map((googleCalendarEvents, index) => {
            return (
              <div
                id={googleCalendarEvents.id}
                key={index}
                className="py-2 last:pb-0"
              >
                <AnnouncementFeedItem
                  googleCalendarEvent={googleCalendarEvents}
                />
              </div>
            )
          })
      : []

  useEffect(() => {
    if (data !== undefined) {
      setActiveAnnouncements(prevState => {
        if (data.result.data.length && googleCalendarEvents.length) {
          if (!prevState.includes(calendarId)) {
            return [...prevState, calendarId]
          } else {
            return prevState
          }
        } else {
          if (prevState.includes(calendarId)) {
            const filteredPrevState = prevState.filter(e => {
              return e !== calendarId
            })
            return filteredPrevState
          } else {
            return prevState
          }
        }
      })
    }
  }, [data, calendarId, googleCalendarEvents.length, setActiveAnnouncements])

  if (googleCalendarEvents.length) {
    return googleCalendarEvents
  } else {
    return ""
  }
}

AnnouncementFeed.propTypes = {
  paragraphData: PropTypes.object,
}

export default AnnouncementFeed
