import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { DateTime } from "luxon"

// Combines two Luxon based approaches for updating a `time` element that prints a friendly "time since posted" message.
// See the original posts at:
// https://github.com/moment/luxon/issues/274#issuecomment-729126515
// https://gist.github.com/RaddishIoW/08c74444f5872495fe53a16e779a1fe5

const TimeSincePost = props => {
  const units = ["year", "month", "week", "day", "hour", "minute", "second"]
  const printRelativeTime = dt => {
    const diff = dt.diffNow().shiftTo(...units)
    const unit = units.find(unit => diff.get(unit) !== 0) || "second"
    const relativeFormatter = new Intl.RelativeTimeFormat("en", {
      numeric: "auto",
    })
    return relativeFormatter.format(Math.trunc(diff.as(unit)), unit)
  }
  const [relTime, setRelTime] = useState(printRelativeTime(props.timestamp))
  const [intervalID, setIntervalID] = useState()

  useEffect(() => {
    let mounted = true
    setIntervalID(
      setInterval(() => {
        if (mounted) {
          setRelTime(printRelativeTime(props.timestamp))
        }
      }, 60000)
    )
    return function cleanup() {
      mounted = false
      clearInterval(intervalID)
    }
  }, [])

  return <time dateTime={props.timestamp.toISO()}>{relTime}</time>
}

TimeSincePost.propTypes = {
  timestamp: PropTypes.instanceOf(DateTime),
}

export default TimeSincePost
