import * as React from "react"
// import { useStaticQuery, graphql } from 'gatsby'

import GarnetShuttleFeeds from "./transportation_garnet-shuttle"
import SeptaFeeds from "./transportation_septa"
import TriCoVanFeeds from "./transportation_trico-van"

const TransportationFeeds = () => {
  return (
    <>
      <SeptaFeeds />
      <GarnetShuttleFeeds />
      <TriCoVanFeeds />
    </>
  )
}

export default TransportationFeeds
