// function from https://stackoverflow.com/a/5624139/3695983
function hexToRgb(hex) {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

const hasRGBProperties = obj => {
  if (typeof obj !== "object") {
    return false
  }
  return (
    obj.hasOwnProperty("r") &&
    obj.hasOwnProperty("g") &&
    obj.hasOwnProperty("b")
  )
}

// function from https://stackoverflow.com/a/9733420/3695983
function luminance(r, g, b) {
  const a = [r, g, b].map(function (v) {
    v /= 255
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
  })
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
}

// Modified function from https://dev.to/alvaromontoro/building-your-own-color-contrast-checker-4j7o
function calculateRatio(color1, color2) {
  // read the colors and transform them into rgb format (note: color1 is the background and color2 is foreground in our case)
  const color1rgb = hasRGBProperties(color1) ? color1 : hexToRgb(color1)
  const color2rgb = hasRGBProperties(color2) ? color2 : hexToRgb(color2)

  // calculate the relative luminance
  const color1luminance = luminance(color1rgb.r, color1rgb.g, color1rgb.b)
  const color2luminance = luminance(color2rgb.r, color2rgb.g, color2rgb.b)

  // calculate the color contrast ratio
  const ratio =
    color1luminance > color2luminance
      ? (color2luminance + 0.05) / (color1luminance + 0.05)
      : (color1luminance + 0.05) / (color2luminance + 0.05)

  return ratio
}

// Recursive function to adjust color value to meet minimum WCAG contrast ratio
function adjustColor(backgroundColor, foregroundColor, darkMode) {
  const passesWcagContrastRatio =
    calculateRatio(backgroundColor, foregroundColor) < 1 / 4.5

  if (passesWcagContrastRatio) {
    return foregroundColor
  } else {
    let foregroundColorRgb = hasRGBProperties(foregroundColor)
      ? foregroundColor
      : hexToRgb(foregroundColor)

    if (darkMode) {
      foregroundColorRgb.g += 5
      foregroundColorRgb.b += 5
    } else {
      foregroundColorRgb.g -= 5
      foregroundColorRgb.b -= 5
    }

    return adjustColor(backgroundColor, foregroundColorRgb, darkMode)
  }
}

export default adjustColor
