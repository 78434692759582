import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { SettingsModalContext } from "../nav/settings"
import sortNavItems from "../../utils/sortNavItems"
import NavigationUtil from "../../utils/navigationUtil"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

const JumpMenuItemWrapper = ({ hidden, children }) => {
  const wrapperClasses = hidden
    ? ["hidden"]
    : [
        "px-2",
        "py-1",
        "border-t",
        "border-gray-100",
        "dark:border-gray-600",
        "hover:bg-gray-100",
        "dark:hover:bg-gray-800",
        "first:border-t-0",
      ]
  return (
    <li className={wrapperClasses.join(" ")} hidden={hidden === 1}>
      {children}
    </li>
  )
}

const JumpMenuItem = ({ node, jumpMenuDispatch }) => {
  const { title, hidden } = node
  const jumpHref = `#${title.replace(/\s/g, "-")}`
  return (
    <JumpMenuItemWrapper hidden={hidden}>
      {hidden ? (
        <a
          href={jumpHref}
          className="jump-menu-link"
          title={title + " (hidden)"}
        >
          {title} (hidden)
        </a>
      ) : (
        <a
          href={jumpHref}
          className="jump-menu-link block w-auto"
          onClick={e => {
            e.preventDefault()
            const { target } = e
            document
              .querySelectorAll(
                'a.section-link[href="' + target.attributes.href.value + '"]'
              )
              .forEach(link => {
                link.setAttribute("data-context", "jump menu")
                link.click()
                link.removeAttribute("data-context")
              })
            jumpMenuDispatch("hidden")
          }}
        >
          <span className="hidden">
            <FontAwesomeIcon
              icon={faCheck}
              className="pb-0.25 mr-1.5 max-h-3.5 text-primary"
            />
          </span>
          {title}
        </a>
      )}
    </JumpMenuItemWrapper>
  )
}

const JumpMenu = ({
  showAnnouncementsLink,
  jumpMenuState,
  jumpMenuDispatch,
}) => {
  const [settingsModalState, settingsModalDispatch] = React.useContext(
    SettingsModalContext
  )
  const { contentSort } = settingsModalState

  const jumpMenuData = useStaticQuery(graphql`
    query JumpMenuItems {
      allNodeContentSection(sort: { fields: field_weight, order: ASC }) {
        edges {
          node {
            id
            drupal_id
            title
            field_weight
            field_disable_hide_show
            field_section_type
          }
        }
      }
      allNodeAnnouncementSection {
        edges {
          node {
            id
            drupal_id
            title
          }
        }
      }
    }
  `)

  const announcementsSection = jumpMenuData.allNodeAnnouncementSection.edges.map(
    ({ node }, index) => ({
      id: node.id,
      drupal_id: node.drupal_id,
      title: node.title,
      type: "announcements",
    })
  )

  const sortableSections = sortNavItems(
    jumpMenuData.allNodeContentSection.edges,
    contentSort
  )

  return (
    <div
      id="jump-menu-body"
      className={`absolute left-1 top-10 m-0 w-80 rounded-md bg-white shadow-md dark:bg-gray-700 ${jumpMenuState}`}
    >
      <nav className="m-0 p-0" aria-labelledby="jump-to-section-heading">
        <header className="rounded-t-md bg-primary py-1 px-1 text-white">
          <h1
            id="jump-to-section-heading"
            className="ml-2 inline-block font-bold"
          >
            Jump to Section
          </h1>
        </header>
        <ul className="jump-nav">
          {showAnnouncementsLink &&
            announcementsSection.map(node => {
              return (
                <JumpMenuItem
                  key={node.id}
                  node={node}
                  jumpMenuDispatch={jumpMenuDispatch}
                />
              )
            })}
          {sortableSections.map((node, index) => {
            return (
              <JumpMenuItem
                key={node.id}
                node={node}
                jumpMenuDispatch={jumpMenuDispatch}
              />
            )
          })}
        </ul>
      </nav>
      {jumpMenuState === "show" ? (
        <NavigationUtil
          closeAction={() => {
            jumpMenuDispatch("hidden")
          }}
          menuSelector="#jump-menu-body"
          excludeElement="#jump-menu-toggle"
        />
      ) : null}
    </div>
  )
}

export default JumpMenu
