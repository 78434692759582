import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import SectionLink from "./section-link"
import MoreMenu from "./menus/more"
import ServicesMenu from "./menus/services"
import SiteHeader from "../header/site-header"
import SwatHomeButton from "../../buttons/swat-home-button"
import UserInfo from "./user-info"
import { Settings, SettingsModalContext } from "./settings"
import ImportantNumbersMenu from "./menus/important-numbers"
import numHiddenSections from "../../utils/numHiddenSections"
import { ScrollSpy } from "../../utils/ScrollSpy"
import sortNavItems from "../../utils/sortNavItems"
import { UserContext } from "../../utils/UserContext"
// Icons
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons"

const NavBar = props => {
  const [settingsModalState, settingsModalDispatch] = React.useContext(
    SettingsModalContext
  )
  const [userState, userStateUpdate] = React.useContext(UserContext)
  const { contentSort } = settingsModalState
  const {
    mobileNavOpen = false,
    setMobileNavOpen,
    showAnnouncementsLink,
    criticalAnnouncementOffset,
    setNavbarCollapsed,
    navbarCollapsed,
  } = props

  const updateLocalSort = !contentSort.length //triggers useEffect hook to populate the settings context

  const mainMenuData = useStaticQuery(graphql`
    query MainMenuItems {
      allNodeContentSection(sort: { fields: field_weight, order: ASC }) {
        edges {
          node {
            id
            drupal_id
            title
            field_weight
            field_disable_hide_show
            field_section_type
          }
        }
      }
      allNodeAnnouncementSection {
        edges {
          node {
            id
            drupal_id
            title
          }
        }
      }
      allNodeMindCandy {
        edges {
          node {
            id
          }
        }
      }
    }
  `)

  const sectionContent = mainMenuData.allNodeContentSection.edges.filter(
    element => {
      if (mainMenuData.allNodeMindCandy.edges.length !== 0) {
        return true
      } else if (element.node.field_section_type !== "mind_candy") {
        return true
      } else {
        return false
      }
    }
  )

  React.useEffect(() => {
    settingsModalDispatch({
      ...settingsModalState,
      contentSort: sortableSections.map(s => ({
        drupal_id: s.drupal_id,
        title: s.title,
        hidden: s.hidden,
        ...(s.prevPosition !== undefined && { prevPosition: s.prevPosition }),
      })),
    })
  }, [updateLocalSort])

  const announcementsSection = mainMenuData.allNodeAnnouncementSection.edges.map(
    ({ node }, index) => ({
      id: node.id,
      drupal_id: node.drupal_id,
      title: node.title,
      type: "announcements",
    })
  )

  const sortableSections = sortNavItems(sectionContent, contentSort)
  const allSectionIds = announcementsSection
    .concat(sortableSections)
    .map(s => s.title.replace(/\s/g, "-"))

  const [scrolledToBottom, setScrolledToBottom] = React.useState(false)
  const [scrolledPastTop, setScrolledPastTop] = React.useState(false)

  const handleScroll = e => {
    if (!e) {
      return
    }
    const hitBottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10
    const scrolledPastTop = e.target.scrollTop > 30

    hitBottom ? setScrolledToBottom(true) : setScrolledToBottom(false)
    scrolledPastTop ? setScrolledPastTop(true) : setScrolledPastTop(false)
  }

  return (
    <div
      className={`navbar no-scrollbar transition-all duration-75
        ${mobileNavOpen ? "navbar--open shadow-[0_25px_50px_-12px_rgba(0,0,0,0.25)]" : ""}
        ${navbarCollapsed ? "navbar--collapsed" : ""}
      `}
      id="navbar"
      onScroll={handleScroll}
    >
      <div
        className={`duration-300 top-0 block flex-none border-b border-transparent bg-primary dark:border-gray-900 dark:border-opacity-70 dark:bg-gray-800 md:h-28 transition
          ${mobileNavOpen ? "md:sticky md-max:static" : "sticky"}
          ${
            scrolledPastTop
              ? "md:shadow-[0_-8px_10px_10px_rgba(0,0,0,0.45)]"
              : "md:shadow-none"
          }
          `}
      >
        <SiteHeader
          mobileNavOpen={mobileNavOpen}
          setMobileNavOpen={setMobileNavOpen}
          navbarCollapsed={navbarCollapsed}
        />
      </div>
      <div className="bg-white dark:bg-gray-700">
        <SwatHomeButton navbarCollapsed={navbarCollapsed} />
      </div>
      <div className="flex min-h-full flex-col justify-between">
        <div className="pt-3 text-black dark:text-white">
          <nav
            id="primary-navigation"
            aria-labelledby="primary-navigation-heading"
          >
            <h1 id="primary-navigation-heading" className="tw-sr-only">
              Primary Navigation
            </h1>
            {/* Note: wrapping navigation <ul> rendered in ScrollSpy component */}
            <ScrollSpy
              scrollTargetIds={allSectionIds}
              wrapperClassName={"font-bold text-xl"}
              criticalAnnouncementOffset={criticalAnnouncementOffset}
            >
              {announcementsSection.map(node => {
                return (
                  <SectionLink
                    linkData={node}
                    key={-1}
                    showAnnouncementsLink={showAnnouncementsLink}
                    navbarCollapsed={navbarCollapsed}
                    setMobileNavOpen={setMobileNavOpen}
                  />
                )
              })}
              {sortableSections.map((node, index) => {
                return (
                  <SectionLink
                    linkData={node}
                    key={index}
                    navbarCollapsed={navbarCollapsed}
                    setMobileNavOpen={setMobileNavOpen}
                  />
                )
              })}
            </ScrollSpy>
            {numHiddenSections() > 0 ? (
              <div className="border-t border-gray-300 px-3 pb-2 dark:border-gray-700">
                <Settings
                  editAccess={userState?.editAccess || []}
                  icon={faEyeSlash}
                  displayStyle="primary-navigation"
                >
                  <span className={navbarCollapsed ? "md:tw-sr-only" : ""}>
                    Manage Hidden Sections
                  </span>
                </Settings>
              </div>
            ) : null}
          </nav>
          <ServicesMenu
            navbarCollapsed={navbarCollapsed}
            setMobileNavOpen={setMobileNavOpen}
          />
          <ImportantNumbersMenu navbarCollapsed={navbarCollapsed} />
          <MoreMenu navbarCollapsed={navbarCollapsed} />
        </div>
        <div
          className={`w-full sticky bottom-0 pointer-events-none transition duration-300 ${
            !scrolledToBottom
              ? "md:shadow-[0_12px_10px_10px_rgba(0,0,0,0.45)]"
              : "md:shadow-none"
          }`}
        >
          <UserInfo
            navbarCollapsed={navbarCollapsed}
            setNavbarCollapsed={setNavbarCollapsed}
          />
        </div>
      </div>
    </div>
  )
}

NavBar.propTypes = {
  mobileNavOpen: PropTypes.bool,
}

export default NavBar
