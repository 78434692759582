import React from "react"
import FocusTrap from "focus-trap-react"

import NavigationUtil from "../../utils/navigationUtil"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle, faTimes } from "@fortawesome/free-solid-svg-icons"
import {
  DashRearrangeGif,
  DashHideGif,
  DashJumpGif,
  DashThemeGif,
} from "../../../images/sections/help"
import Button from "../../buttons/button"

export const HelpModalContext = React.createContext()

export const HelpProvider = ({ children }) => {
  const [helpModalState, helpModalDispatch] = React.useState({
    show: false,
  })

  return (
    <HelpModalContext.Provider value={[helpModalState, helpModalDispatch]}>
      {children}
    </HelpModalContext.Provider>
  )
}

export const HelpButton = ({
  icon = faQuestionCircle,
  displayStyle,
  children,
}) => {
  const [helpModalState, helpModalDispatch] = React.useContext(HelpModalContext)
  let buttonClasses = null
  let innerMarkup = null
  switch (displayStyle) {
    case "fixed-circle":
      buttonClasses = [
        "fixed",
        "z-10",
        "bottom-3",
        "right-6",
        "px-2",
        "py-1",
        "rounded-full",
        "bg-primary",
        "text-white",
        "text-xl",
        "cursor-pointer",
        "hover:shadow-md",
        "transition",
        "focus:ring-2",
        "focus:ring-black",
        "dark:focus:ring-white",
      ]
      innerMarkup = (
        <>
          <FontAwesomeIcon icon={icon} className="inline-block" />
          <span className="tw-sr-only">View Help Options</span>
        </>
      )
      break
    case "inline-button":
      buttonClasses = [
        "bg-primary",
        "bg-opacity-10",
        "dark:bg-opacity-100",
        "text-primary",
        "dark:text-white",
        "hover:bg-opacity-30",
        "dark:hover:bg-opacity-90",
        "transition",
        "block",
        "flex",
        "gap-2",
        "items-center",
        "justify-center",
        "h-auto",
        "rounded",
        "font-semibold",
        "whitespace-nowrap",
        "p-2",
      ]
      innerMarkup = (
        <>
          <FontAwesomeIcon icon={icon} className="inline-block" />
          {children}
          <span className="tw-sr-only">View Help Options</span>
        </>
      )
      break
    default:
      innerMarkup = (
        <>
          {children}
          <span className="tw-sr-only">View Help Options</span>
        </>
      )
      break
  }
  return (
    <button
      data-type="call to action"
      data-context={displayStyle}
      data-action="view help"
      onClick={() =>
        helpModalDispatch({
          ...helpModalState,
          show: true,
        })
      }
      className={buttonClasses.join(" ")}
    >
      <span className="pointer-events-none">{innerMarkup}</span>
    </button>
  )
}

export const HelpModal = props => {
  const [helpModalState, helpModalDispatch] = React.useContext(HelpModalContext)
  const { show } = helpModalState

  if (show) {
    return (
      <FocusTrap>
        <div
          className="u-backdrop-filter fixed top-0 left-0 z-50 mx-auto h-full w-full overflow-y-auto py-8 px-4"
          role="dialog"
          aria-modal="true"
          aria-labelledby="help-modal-header"
          aria-describedby="help-modal-desc"
        >
          <div
            id="help-modal-body"
            className="mx-auto max-w-xl overflow-x-hidden rounded-xl bg-white shadow-2xl dark:bg-gray-800"
          >
            <header
              id="help-modal-header"
              className="bg-primary py-2 px-3 text-white"
            >
              <button
                data-type="toggle"
                data-context="help modal"
                data-action="close"
                onClick={() =>
                  helpModalDispatch({
                    ...helpModalState,
                    show: false,
                  })
                }
                className="float-right text-lg"
              >
                <span className="pointer-events-none">
                  <FontAwesomeIcon icon={faTimes} className="inline-block" />
                  <span className="tw-sr-only">Close</span>
                </span>
              </button>
              <h1 className="ml-2 inline-block text-lg font-bold">Help</h1>
            </header>
            <section className="tw-sr-only">
              <p id="help-modal-desc">Learn how to use the Dash here.</p>
            </section>
            <section className="prose p-5 dark:prose-dark">
              <h2>Welcome to the Dash!</h2>
              <p>
                The Dash makes it easy to access the day-to-day campus
                information you rely on. You can customize the layout of the
                Dash’s main sections, hide what content you see on the Dash,
                choose between light and dark mode, and much more. Let’s take a
                quick spin through each of the Dash’s main features.
              </p>
              <h3>Rearranging Sections</h3>
              <p>
                You can easily customize the layout of the Dash's default
                sections to meet your own needs and interests. To move a section
                from the default order, click the <strong>gear</strong> icon at
                the bottom of the lefthand navigation on desktop (or the top of
                the main navigation on mobile) to bring up the{" "}
                <strong>Settings</strong> panel. From there, click the{" "}
                <strong>up or down arrow</strong> next to a section to move it
                exactly where you’d like it.
              </p>
              <img
                src={DashRearrangeGif}
                alt="Screen recording demonstrating the rearrange sections functionality of the Dash"
                className="w-full"
              />
              <h3>Hiding Sections</h3>
              <p>
                Only interested in seeing Menus and Events on your Dash? Or
                maybe just Transportation and Hours? Click the{" "}
                <strong>Hide Section</strong> button located next to a section’s
                heading to hide it from your Dash. It’s that simple. To restore
                a section, click on the <strong>Manage Hidden Sections</strong>{" "}
                button on desktop (or the eye icon on mobile) to bring up the{" "}
                <strong>Settings</strong> panel where you can click the
                hide/show button to the right of the section title.
              </p>
              <img
                src={DashHideGif}
                alt="Screen recording demonstrating the hide sections functionality of the Dash"
                className="w-full"
              />
              <h3>Choosing a Theme</h3>
              <p>
                <span className="bg-black p-1 text-white">Dark mode</span>?
                We’ve got you covered. Click the <strong>gear</strong> icon at
                the bottom of the lefthand navigation on desktop (or the top of
                the main navigation on mobile) and toggle the{" "}
                <strong>theme switch</strong> to your heart’s content.
              </p>
              <img
                src={DashThemeGif}
                alt="Screen recording demonstrating the change theme functionality of the Dash"
                className="w-full"
              />
              <h3>Jumping to Content</h3>
              <p>
                There’s a lot of stuff packed into the Dash, which makes
                scrolling on your phone tiring on the thumbs. Use the{" "}
                <strong>jump menu</strong>, on the top left beneath the main
                navigation, to give them a rest.
              </p>
              <img
                src={DashJumpGif}
                alt="Screen recording demonstrating the jump to section functionality of the mobile sized Dash"
                className="w-full"
              />
            </section>
            <Button
              color="primary"
              url="https://kb.swarthmore.edu/display/SW/Customizing+The+Dash"
              additionalAttr={{
                target: "__blank",
                "data-context": "help modal",
              }}
              additionalClasses={["flex mx-auto mt-2 mb-4 w-4/5"]}
            >
              More About Customization
            </Button>
          </div>
          <NavigationUtil
            closeAction={() => {
              helpModalDispatch({
                ...helpModalState,
                show: false,
              })
            }}
            menuSelector="#help-modal-body"
            excludeElement="#help-modal-header"
          />
        </div>
      </FocusTrap>
    )
  }
  return null
}

export default HelpButton
