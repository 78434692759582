import React, { useState } from "react"
// import { DateTime } from "luxon"
import DataProvider from "../utils/DataProvider"
// import swatDatesUtil from "../utils/swatDatesUtil"

import ChevronToggle from "../page-layout/ui/chevron-toggle"
import Skeleton from "../page-layout/ui/skeleton"
import Button from "../buttons/button"

const GarnetShuttleFeeds = props => {
  const [garnetShuttleToggle, setGarnetShuttleToggle] = useState(true)
  const garnetShuttleVisibility = !garnetShuttleToggle ? "tw-sr-only" : ""

  return (
    <div className="mb-6">
      <h2>
        <button
          data-type="toggle"
          data-context="transportation section"
          data-action={garnetShuttleToggle ? "collapse" : "expand"}
          data-label="Garnet Shuttle"
          className="mb-3 text-xl font-bold"
          type="button"
          onClick={() => setGarnetShuttleToggle(!garnetShuttleToggle)}
        >
          <span className="pointer-events-none flex flex-row items-center justify-between">
            Garnet Shuttle
            <span className="text-base">
              <ChevronToggle
                chevronClasses={["ml-2"]}
                toggle={garnetShuttleToggle}
              />
            </span>
          </span>
        </button>
      </h2>
      <div className={garnetShuttleVisibility}>
        <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
          {/* TODO: add optional descriptive paragraph */}
          <DataProvider queryName="swatshuttle" queryVariables={{}}>
            <GarnetShuttleRoutes {...props} />
          </DataProvider>
        </div>
        <Button
          color="primary"
          url="https://www.swarthmore.edu/getting-around/garnet-shuttle"
          additionalAttr={{
            "data-element-location": "garnet shuttle",
            target: "_blank",
            rel: "noreferrer",
          }}
          additionalClasses={["mt-3", "w-full", "lg:w-1/2"]}
        >
          Weekly Garnet Shuttle Schedule
        </Button>
      </div>
    </div>
  )
}

const GarnetShuttleRoutes = props => {
  const { error, loading, data } = props
  if (!loading && !error) {
    return (
      <>
        {data.result.data.map(r => {
          return <GarnetShuttleRouteTimes route={r} key={r.routeId} />
        })}
      </>
    )
  } else {
    return <Skeleton />
  }
}

const GarnetShuttleRouteTimes = ({ route }) => {
  const { routeName, routeId, stops } = route
  const processedGarnetShuttleRouteTimes = stops.map(
    (processedGarnetShuttleRouteTimes, i) => {
      // TODO: resume "Next Arrival" if it becomes available in Transloc
      // const stopTime = processedGarnetShuttleRouteTimes.datetime ? (
      //   <time dateTime={processedGarnetShuttleRouteTimes.datetime}>
      //     {swatDatesUtil(
      //       DateTime.fromISO(
      //         processedGarnetShuttleRouteTimes.datetime
      //       ).toLocal().toFormat("h:mm a")
      //     )}
      //   </time>
      // ) : (
      //   "--"
      // )

      return (
        <li key={i}>
          {processedGarnetShuttleRouteTimes.stop}
          {/* TODO: resume "Next Arrival" if it becomes available in Transloc */}
          {/* <td className="border border-primary-light px-3 py-2">{stopTime}</td> */}
        </li>
      )
    }
  )

  const routeImg = `https://swarthmore.transloc.com/t/map/simple/${routeId}`
  const routeMapUrl = `https://swarthmore.transloc.com/?route=${routeId}`
  return (
    <div>
      {processedGarnetShuttleRouteTimes.length ? (
        <div className="flex h-full max-w-max flex-col overflow-hidden rounded-md border border-gray-100 dark:border-gray-800">
          <DataProvider
            queryName="swatshuttlemap"
            queryVariables={{ routeId: routeId }}
          >
            <GarnetShuttleMap routeImg={routeImg} />
          </DataProvider>

          <h2 className="px-3 py-2 text-lg font-bold">{routeName}</h2>
          <h3 className="text-md px-3 font-semibold text-gray-800 dark:text-gray-300">
            Route Stops
          </h3>
          <ul className="prose list-disc py-2 px-8 dark:prose-dark">
            {processedGarnetShuttleRouteTimes}
          </ul>
          <div className="mt-auto p-3">
            <Button
              color="primary"
              icon="map"
              url={routeMapUrl}
              additionalAttr={{
                "data-element-location": "garnet shuttle",
                target: "_blank",
                rel: "noreferrer",
              }}
            >
              <span className="tw-sr-only">{routeName}</span>Shuttle Live Map
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  )
}

const GarnetShuttleMap = props => {
  const { error, loading, data, routeImg } = props
  if (!error && !loading) {
    const { result } = data
    return (
      <figure className="relative">
        <img
          src={`data:${result.contentType};base64, ${result.img}`}
          width="100%"
          height="12rem"
          alt="A map of the Swarthmore College campus and surrounding area, which appears on the Transloc Garnet Shuttle site"
          className="object-cover"
        />
        <img
          src={routeImg}
          width="100%"
          height="12rem"
          alt="An outline of the shuttle route overlaying a map of the Swarthmore College campus, which appears on the Transloc Garnet Shuttle site"
          className="absolute top-0 left-0 rounded-md"
        />
      </figure>
    )
  } else {
    return <Skeleton />
  }
}

export default GarnetShuttleFeeds
