import React, { useState } from "react"
import PropTypes from "prop-types"
// Sections
import AroundCampus from "./around-campus"
import EventsFeeds from "./events"
import HoursFeeds from "./hours"
import { MenuFeeds } from "./menus"
import MindCandy from "./mind-candy"
import TransportationFeeds from "./transportation"
// Paragraphs
import HtmlHeader from "./paragraphs/html-header"
import HtmlBlock from "./paragraphs/html-block"
import LinkListing from "./paragraphs/link-listing"

// Settings
import { SettingsModalContext, hideSection } from "../page-layout/nav/settings"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons"
import { faCheckCircle, faCircle } from "@fortawesome/free-regular-svg-icons"
import SecondaryContent from "./secondary-content"

// Utils
import NavigationUtil from "../utils/navigationUtil"

const Section = ({ nodeData }) => {
  const [settingsModalState, settingsModalDispatch] = React.useContext(
    SettingsModalContext
  )
  const { contentSort } = settingsModalState
  const [showSectionSettings, setShowSectionSettings] = useState(false)

  let showAroundCampusDescriptionsLocalStorage = true
  let filterVisibilityLocalStorage = false
  if (typeof window !== "undefined") {
    showAroundCampusDescriptionsLocalStorage = JSON.parse(
      window.localStorage.getItem("showAroundCampusDescriptions")
    )
    filterVisibilityLocalStorage = JSON.parse(
      window.localStorage.getItem("filterVisibility")
    )
  }
  const [
    showAroundCampusDescriptions,
    setShowAroundCampusDescriptions,
  ] = useState(showAroundCampusDescriptionsLocalStorage)
  const [filterVisibility, setFilterVisibility] = useState(
    filterVisibilityLocalStorage || {
      around_campus: true,
      hours: true,
      events: true,
    }
  )

  const hasSecondaryContent =
    nodeData.relationships.field_secondary_content.length >= 1
  const conditionalGridClasses = hasSecondaryContent
    ? ["xl:grid-cols-section-aside"]
    : []
  const defaultSectionGridClasses = ["grid", "grid-rows-1", "grid-str", "gap-5"]
  const combinedSectionGridClasses = defaultSectionGridClasses
    .concat(conditionalGridClasses)
    .join(" ")

  const settingsMenuId = `${nodeData.title
    .toLowerCase()
    .replace(/\s+/g, "-")}-settings-menu`

  return (
    <section
      id={nodeData.title.replace(/\s+/g, "-")}
      className="content-section py-4"
    >
      <div className="mb-2 flex w-full flex-row items-center justify-between">
        <div className="mr-auto">
          {nodeData.title && (
            <h1 className="text-2xl font-bold">{nodeData.title}</h1>
          )}
          {nodeData.field_subtitle && (
            <p className="text-lg font-bold text-gray-600 dark:text-gray-300 xs-max:hidden">
              {nodeData.field_subtitle}
            </p>
          )}
        </div>
        <div className="flex items-center gap-1 relative">
          {/* Section settings menu */}
          <button
            href="/"
            id={`${settingsMenuId}-toggle`}
            data-type="toggle"
            data-context={`${nodeData.title.toLowerCase()} section settings`}
            data-action={`${
              filterVisibility[nodeData.field_section_type] ? "hide" : "show"
            } ${nodeData.title.toLowerCase()} filters`}
            className={`flex flex-none items-center rounded p-1.5 md-max:p-0.5 transition hover:bg-gray-200 dark:hover:bg-gray-700 ${
              showSectionSettings
                ? "bg-gray-300 dark:bg-gray-700"
                : "bg-transparent"
            }`}
            onClick={e => {
              e.preventDefault()
              setShowSectionSettings(() => !showSectionSettings)
            }}
          >
            <span className="pointer-events-none">
              <FontAwesomeIcon
                icon={faEllipsisH}
                className="mx-1 inline-block text-black dark:text-gray-100"
              />
              <span className="tw-sr-only md:inline text-sm font-bold">
                {nodeData.title.toLowerCase()} Section Settings
              </span>
            </span>
          </button>
          {showSectionSettings && (
            <>
              <div
                id={settingsMenuId}
                className={`absolute top-9 md:top-10 right-0 z-2 h-auto w-64 rounded-md bg-white p-0 text-sm shadow-md dark:bg-gray-700 dark:text-white`}
              >
                <nav
                  className="m-0 p-0"
                  aria-labelledby={`${settingsMenuId}-heading`}
                >
                  <header className="rounded-t-md bg-primary py-1 px-2 text-white">
                    <h1
                      id={`${settingsMenuId}-heading`}
                      className="inline-block font-bold"
                    >
                      {nodeData.title} Settings
                    </h1>
                  </header>
                  <ul
                    className="list-none"
                    aria-labelledby={`${settingsMenuId}-heading`}
                  >
                    {["around_campus", "hours", "events"].indexOf(
                      nodeData.field_section_type
                    ) >= 0 && (
                      <li className="border-b last:rounded-b-md border-gray-100 dark:border-gray-600 last:border-b-0 hover:bg-gray-100 dark:hover:bg-gray-800">
                        <button
                          href="/"
                          data-type="toggle"
                          data-context="section options"
                          data-action={`${
                            filterVisibility[nodeData.field_section_type]
                              ? "hide"
                              : "show"
                          } filters`}
                          className="w-full text-left gap-2 p-2"
                          onClick={e => {
                            e.preventDefault()
                            setFilterVisibility(prevState => ({
                              ...prevState,
                              [nodeData.field_section_type]: !prevState[
                                nodeData.field_section_type
                              ],
                            }))
                            if (typeof window !== "undefined") {
                              localStorage.setItem(
                                "filterVisibility",
                                JSON.stringify({
                                  ...filterVisibility,
                                  [nodeData.field_section_type]: !filterVisibility[
                                    nodeData.field_section_type
                                  ],
                                })
                              )
                            }
                          }}
                        >
                          <span className="text-md pointer-events-none flex content-center items-center">
                            <span>Show Filters</span>
                            <FontAwesomeIcon
                              icon={
                                filterVisibility[nodeData.field_section_type]
                                  ? faCheckCircle
                                  : faCircle
                              }
                              className="ml-auto text-gray-600 dark:text-gray-100"
                              style={{ fontSize: "1.075rem" }}
                            />
                          </span>
                        </button>
                      </li>
                    )}
                    {["around_campus"].indexOf(nodeData.field_section_type) >=
                      0 && (
                      <li className="border-b last:rounded-b-md border-gray-100 dark:border-gray-600 last:border-b-0 hover:bg-gray-100 dark:hover:bg-gray-800">
                        <button
                          href="/"
                          data-type="toggle"
                          data-context="toggle around campus descriptions"
                          data-action={`${
                            showAroundCampusDescriptions ? "hide" : "show"
                          } around campus descriptions`}
                          className="w-full text-left gap-2 p-2"
                          onClick={e => {
                            e.preventDefault()
                            setShowAroundCampusDescriptions(
                              () => !showAroundCampusDescriptions
                            )
                            if (typeof window !== "undefined") {
                              localStorage.setItem(
                                "showAroundCampusDescriptions",
                                JSON.stringify(!showAroundCampusDescriptions)
                              )
                            }
                          }}
                        >
                          <span className="text-md pointer-events-none flex items-center">
                            <span>Show Story Descriptions</span>
                            <FontAwesomeIcon
                              icon={
                                showAroundCampusDescriptions
                                  ? faCheckCircle
                                  : faCircle
                              }
                              className="ml-auto text-gray-600 dark:text-gray-100"
                              style={{ fontSize: "1.075rem" }}
                            />
                          </span>
                        </button>
                      </li>
                    )}
                    {!nodeData.field_disable_hide_show && (
                      <li className="border-b last:rounded-b-md border-gray-100 dark:border-gray-600 last:border-b-0 hover:bg-gray-100 dark:hover:bg-gray-800">
                        <button
                          href="/"
                          data-type="toggle"
                          data-context="section options"
                          data-action="hide section"
                          className="w-full text-left gap-2 p-2"
                          onClick={e => {
                            e.preventDefault()
                            settingsModalDispatch({
                              ...settingsModalState,
                              contentSort: hideSection(
                                contentSort,
                                nodeData.drupal_id
                              ),
                            })
                            setShowSectionSettings(false)
                          }}
                        >
                          <span className="pointer-events-none">
                            <span className="">Hide </span>
                            <span className="tw-sr-only">
                              {nodeData.title}{" "}
                            </span>
                            <span className="">Section</span>
                          </span>
                        </button>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
              <NavigationUtil
                closeAction={() => {
                  setShowSectionSettings(false)
                }}
                menuSelector={`#${settingsMenuId}`}
                excludeElement={`#${settingsMenuId}-toggle`}
              />
            </>
          )}
        </div>
      </div>
      <div
        data-type="primary content"
        data-context={`${nodeData.title
          .replace(/\s+/g, "-")
          .toLowerCase()} section`}
        className={combinedSectionGridClasses}
      >
        <div className="grid grid-cols-1">
          {nodeData.field_section_type === "around_campus" && (
            <AroundCampus
              filterVisibility={filterVisibility}
              showDescriptions={showAroundCampusDescriptions}
            />
          )}
          {nodeData.field_section_type === "events" && (
            <EventsFeeds filterVisibility={filterVisibility} />
          )}
          {nodeData.field_section_type === "hours" && (
            <HoursFeeds filterVisibility={filterVisibility} />
          )}
          {nodeData.field_section_type === "menus" && <MenuFeeds />}
          {nodeData.field_section_type === "mind_candy" && <MindCandy />}
          {nodeData.field_section_type === "transportation" && (
            <TransportationFeeds />
          )}
          {nodeData.relationships.field_content.length >= 1 && (
            <div className="divide-y text-black dark:text-white">
              {nodeData.relationships.field_content.map((paragraph, index) => {
                switch (paragraph.internal.type) {
                  case "paragraph__html_header":
                    return <HtmlHeader paragraphData={paragraph} key={index} />
                  case "paragraph__html_block":
                    return <HtmlBlock paragraphData={paragraph} key={index} />
                  case "paragraph__link_listing":
                    return <LinkListing paragraphData={paragraph} key={index} />
                  default:
                    return ""
                }
              })}
            </div>
          )}
        </div>
        {hasSecondaryContent ? (
          <div
            data-type="secondary content"
            data-context={`${nodeData.title
              .replace(/\s+/g, "-")
              .toLowerCase()} section`}
          >
            {hasSecondaryContent && (
              <div className="border rounded-md border-gray-200 p-3 dark:border-gray-700">
                <SecondaryContent
                  secondaryContentData={
                    nodeData.relationships.field_secondary_content
                  }
                />
              </div>
            )}
          </div>
        ) : null}
      </div>
    </section>
  )
}

Section.propTypes = {
  nodeData: PropTypes.object,
}

export default Section
