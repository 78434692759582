import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"

const ImportantNumbersMenu = ({ navbarCollapsed }) => {
  const [importantNumbersMenuToggle, setImportantNumbersMenuToggle] = useState(
    true
  )
  const importantNumbersVisibility = !importantNumbersMenuToggle
    ? "tw-sr-only"
    : ""

  const data = useStaticQuery(graphql`
    query ImportantNumbersMenuItems {
      allMenuItems(filter: { menu_name: { eq: "important-numbers" } }) {
        edges {
          node {
            id
            url
            title
            description
            options {
              external
            }
          }
        }
      }
    }
  `)

  return (
    <div
      className={`my-3 px-3.5 ${navbarCollapsed ? "md:hidden" : ""}`}
      id="important-numbers-menu"
    >
      <nav aria-labelledby="important-numbers-heading">
        <h2
          id="important-numbers-heading"
          className="mb-1 py-1 text-sm font-bold uppercase tracking-wider text-gray-600 dark:text-gray-300"
        >
          <span className="pointer-events-none flex items-center">
            <span>
              <FontAwesomeIcon
                icon={faPhone}
                className="text-md mr-2 inline-block"
              />
            </span>
            Important Numbers
          </span>
        </h2>
        <ul
          className={`list-outside list-disc pl-5 text-sm marker:text-gray-500 ${importantNumbersVisibility}`}
        >
          {data.allMenuItems.edges.map(({ node }, index) => {
            if (node.url && node.url.startsWith("tel:")) {
              const friendlyNumber = node.url.replace(/^tel:[1]-/, "")
              return (
                <li className="mb-1" key={index}>
                  {node.options.external === true ? (
                    <>
                      <strong>{node.title}:</strong>{" "}
                      <a
                        className="inline-block hover:underline"
                        href={node.url}
                        title={node.description ? node.description : ""}
                      >
                        {friendlyNumber}
                      </a>
                    </>
                  ) : (
                    <Link to={node.url}>{node.title}</Link>
                  )}
                </li>
              )
            } else {
              return null
            }
          })}
        </ul>
      </nav>
    </div>
  )
}

export default ImportantNumbersMenu
