import * as React from "react"
// Utils
import filterChange from "../../utils/filterChange"

const HoursFilters = props => {
  const {
    selectOptions,
    filterSettings,
    setFilterSettings,
    setHoursOffset,
    filterVisibility,
  } = props

  const categorySelect = selectOptions.map((option, i) => (
    <option key={i} value={option}>
      {option}
    </option>
  ))

  const handleFilterChange = ({ target }) => {
    const filterType = target.dataset.filter
    const newValue = target.value
    setFilterSettings(prevState => ({
      ...prevState,
      [filterType]: newValue,
    }))
    setHoursOffset([])
    filterChange(target)
  }

  return (
    <form
      id="hours-filters"
      className={filterVisibility.hours ? "block" : "hidden "}
    >
      <fieldset
        className="mt-3 mb-5 flex flex-row justify-start gap-1"
        aria-label="Filtering options for the Hours section"
      >
        <label htmlFor="hours-type" className="tw-sr-only">
          Category
        </label>
        <select
          id="hours-type"
          data-type="filter"
          data-context="hours filters"
          data-action="adjust category"
          className="filter-select"
          data-filter="category"
          onChange={handleFilterChange}
          value={filterSettings.category}
        >
          <option value="All">All</option>
          {categorySelect}
        </select>
        <label htmlFor="hours-sort-by" className="tw-sr-only">
          Sort By
        </label>
        <select
          id="hours-sort-by"
          data-type="filter"
          data-context="hours filters"
          data-action="select order"
          className="filter-select"
          data-filter="order"
          onChange={handleFilterChange}
          value={filterSettings.order}
        >
          <option value="A-Z">A-Z</option>
          <option value="Z-A">Z-A</option>
        </select>
      </fieldset>
    </form>
  )
}

export default HoursFilters
