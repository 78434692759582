import React, { useState, useRef, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { DateTime } from "luxon"
import sanitizeHtml from "sanitize-html"

import AroundCampusFilters from "./filters/around-campus-filters"
import ResetFilterOptions from "./filters/reset-filter-options"
import Button from "../../components/buttons/button"
import { SettingsModalContext } from "../page-layout/nav/settings"

// Utils
import DataProvider from "../utils/DataProvider"
import swatDatesUtil from "../utils/swatDatesUtil"
import adjustColor from "../utils/colorUtils"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faThumbtack } from "@fortawesome/free-solid-svg-icons"
import Skeleton from "../page-layout/ui/skeleton"

const AroundCampus = ({ filterVisibility, showDescriptions }) => {
  const filterDefaults = {
    feedSource: "All",
    range: "Past 3 Days",
    order: "Most Recent",
  }
  const [filterSettings, setFilterSettings] = useState(filterDefaults)
  const aroundCampusData = useStaticQuery(graphql`
    query AroundCampusFeeds {
      allNodeAroundCampusFeeds {
        edges {
          node {
            id
            drupal_id
            title
            field_label_color {
              color
            }
            field_feed_url {
              uri
            }
            sticky
            field_weight
          }
        }
      }
    }
  `)

  const feedData = aroundCampusData.allNodeAroundCampusFeeds.edges.map(
    (feedData, i) => ({
      id: feedData.node.id,
      title: feedData.node.title,
      url: feedData.node.field_feed_url.uri,
      labelColor: feedData.node.field_label_color.color,
      sticky: feedData.node.sticky,
      weight: feedData.node.field_weight,
    })
  )
  const feedUrls = feedData.map((feedUrls, i) => {
    return feedUrls.url
  })
  const feedStickyUrls = feedData
    .filter((feedStickyUrls, i) => {
      return feedStickyUrls.sticky
    })
    .map((feedUrls, i) => {
      return feedUrls.url
    })
  const queryVariables = {
    feedUrls: feedUrls,
    feedStickyUrls: feedStickyUrls,
    prevDaysToQuery: 14,
  }

  return (
    <>
      <DataProvider
        queryName="combinedrssnewsfeeds"
        queryVariables={queryVariables}
      >
        <AroundCampusWrapper
          feedData={feedData}
          filterDefaults={filterDefaults}
          filterSettings={filterSettings}
          setFilterSettings={setFilterSettings}
          filterVisibility={filterVisibility}
          showDescriptions={showDescriptions}
        />
      </DataProvider>
    </>
  )
}

export const AroundCampusWrapper = props => {
  const {
    error,
    loading,
    data,
    feedData,
    filterDefaults,
    filterSettings,
    setFilterSettings,
    filterVisibility,
    showDescriptions,
  } = props

  // Get all news items
  const allNewsItems =
    !loading && !error
      ? data.result.data.map((item, i) => {
          const sourceMeta = feedData
            .filter(source => {
              if (source.url === item.source) {
                return true
              } else {
                return false
              }
            })
            .map(sourceMeta => sourceMeta)
          return {
            ...item,
            sourceMeta: sourceMeta[0],
          }
        })
      : []
  // Filter on selected date range
  function filterRange(item, i) {
    const publishDate = DateTime.fromMillis(parseInt(item.published)).startOf(
      "day"
    )
    switch (filterSettings.range) {
      case "Past 3 Days":
        return (
          item.sticky ||
          publishDate >= DateTime.local().startOf("day").minus({ days: 2 })
        )
      case "Past Week":
        return (
          item.sticky ||
          publishDate >= DateTime.local().startOf("day").minus({ days: 6 })
        )
      case "Past 2 Weeks":
        return (
          item.sticky ||
          publishDate >= DateTime.local().startOf("day").minus({ days: 13 })
        )
      default:
        return true
    }
  }
  // Create the array of select options for use in ./filters/around-campus-filters.js
  const selectOptions = allNewsItems
    .filter(filterRange)
    .filter(
      // Remove duplicate categories
      (v, i, a) => a.findIndex(t => t.sourceMeta.id === v.sourceMeta.id) === i
    )
    .map(feed => feed.sourceMeta.title)

  // Applies the current feedSource from `filterSettings`.
  function filterDepartment(a) {
    return function (item, i) {
      if (filterSettings.feedSource === "All") {
        return true
      } else {
        return filterSettings.feedSource === item.sourceMeta.title
      }
    }
  }
  // Filter on preferred order settings
  function filterOrder(a, b) {
    if (filterSettings.order === "Source (A-Z)") {
      return a.sourceMeta.title.localeCompare(b.sourceMeta.title)
    } else {
      return 0
    }
  }
  // Filter the news posts
  const filteredNewsItems = allNewsItems
    .filter(filterRange)
    .filter(filterDepartment(selectOptions))
    .sort(filterOrder)
  // Isolate the sticky posts
  const stickyPosts = filteredNewsItems
    .filter((item, i) => {
      return item.sticky
    })
    .sort((a, b) => {
      // Sort the sticky posts by their weight
      function getSourceWeight(x) {
        return feedData
          .filter(source => {
            return source.url === x.source
          })
          .map(xWeight => xWeight.weight)
      }
      const aWeight = getSourceWeight(a)
      const bWeight = getSourceWeight(b)
      return aWeight - bWeight
    })
    .map((item, i) => {
      return item
    })
  // Isolate the non-sticky posts
  const standardPosts = filteredNewsItems
    .filter((item, i) => {
      return !item.sticky
    })
    .map(item => {
      return item
    })

  const aroundCampusSectionRef = useRef(null)
  const scrollToPrevious = () => {
    aroundCampusSectionRef.current.scrollIntoView()
  }
  const defaultNumStoriesToShow = 9
  const [sliceEnd, setSliceEnd] = useState(defaultNumStoriesToShow)
  const hasMoreStories =
    filteredNewsItems.length > filteredNewsItems.slice(0, sliceEnd).length

  if (!loading && !error) {
    return (
      <>
        <AroundCampusFilters
          feedData={feedData}
          selectOptions={selectOptions}
          filterSettings={filterSettings}
          setFilterSettings={setFilterSettings}
          filterVisibility={filterVisibility}
          setSliceEnd={setSliceEnd}
          defaultNumStoriesToShow={defaultNumStoriesToShow}
        />
        <ResetFilterOptions
          filterDefaults={filterDefaults}
          filterSettings={filterSettings}
          setFilterSettings={setFilterSettings}
          sectionType="Around Campus"
          sectionData={{
            stickyPosts: stickyPosts,
            standardPosts: standardPosts,
          }}
        />
        <div ref={aroundCampusSectionRef}>
          <AroundCampusPost
            posts={stickyPosts}
            showDescriptions={showDescriptions}
          />
          <AroundCampusPost
            posts={standardPosts.slice(0, sliceEnd)}
            showDescriptions={showDescriptions}
          />
        </div>
        {standardPosts.length > defaultNumStoriesToShow && (
          <div className="mt-5 flex w-full items-center justify-center">
            {hasMoreStories ? (
              <Button
                onClick={() =>
                  setSliceEnd(
                    prevSliceEnd => prevSliceEnd + defaultNumStoriesToShow
                  )
                }
                icon="arrowDown"
              >
                Show More Stories
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setSliceEnd(defaultNumStoriesToShow)
                  scrollToPrevious()
                }}
                icon="arrowUp"
              >
                Show Less
              </Button>
            )}
          </div>
        )}
      </>
    )
  } else {
    return <Skeleton />
  }
}

const AroundCampusPost = props => {
  const { posts, showDescriptions } = props
  const [settingsModalState] = useContext(SettingsModalContext)
  const { darkMode } = settingsModalState

  const processedPosts = posts.map((item, i) => {
    const publishDate = (
      <time dateTime={DateTime.fromMillis(parseInt(item.published)).toISO()}>
        {swatDatesUtil(
          DateTime.fromMillis(parseInt(item.published)).toFormat(
            "MMM'.' d',' yyyy"
          )
        )}
      </time>
    )
    const postDescription = item.description
      ? sanitizeHtml(item.description, {
          allowedTags: [],
        })
      : false

    return (
      <div className="mb-4" key={i}>
        <a
          href={item.url}
          className="w-max-content group inline-block"
          target="_blank"
          rel="noreferrer"
        >
          <div className="group-hover:underline">
            {item.sticky && (
              <FontAwesomeIcon
                icon={faThumbtack}
                className="mr-2 inline-block rotate-45 text-xs"
              />
            )}
            <h2
              className="text-md inline font-bold"
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
          </div>
          {/* Suppress descriptions from the Classifieds */}
          {showDescriptions &&
            item.sourceMeta.title.toLowerCase() !== "classifieds" &&
            postDescription && (
              <div className="text-sm mb-0.5 line-clamp-2">
                {postDescription}
              </div>
            )}
          <div className="text-sm">
            <span>
              <AroundCampusLabel
                feedSource={item.sourceMeta}
                darkMode={darkMode}
              />
              <span className="text-gray-700 dark:text-white text-xs">
                {publishDate}
              </span>
            </span>
          </div>
        </a>
      </div>
    )
  })
  return processedPosts
}

const AroundCampusLabel = ({ feedSource, darkMode }) => {
  if (feedSource) {
    const { title, labelColor } = feedSource
    const adjustedLabelColor = adjustColor(
      !darkMode ? "#fff" : "#111827",
      labelColor,
      darkMode
    )

    return (
      <span
        style={{
          color:
            typeof adjustedLabelColor === "object"
              ? `rgb(${adjustedLabelColor.r}, ${adjustedLabelColor.g}, ${adjustedLabelColor.b})`
              : adjustedLabelColor,
        }}
        className="mr-2 inline-block rounded-md text-center text-sm font-bold text-black dark:text-white"
      >
        {title}
      </span>
    )
  } else {
    return null
  }
}

export default AroundCampus
