import React, { useState } from "react"
import PropTypes from "prop-types"
import DataProvider from "../utils/DataProvider"

import Button from "../buttons/button"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGrin, faGrinTongue } from "@fortawesome/free-solid-svg-icons"

// Prepares the user provided abstract for use in Mind Candy section
function createMarkup(abstract) {
  return {
    __html: abstract,
  }
}

const MindCandy = ({ queryVariables }) => {
  return (
    <DataProvider
      queryName="mindcandyfeed"
      queryVariables={queryVariables}
      poll={10}
    >
      <RenderMindCandy />
    </DataProvider>
  )
}

const RenderMindCandy = ({ loading, error, data }) => {
  if (!loading && !error) {
    return (
      <>
        {data.result.data.map((mcData, i) => {
          return mcData.paragraphType === "paragraph--standard_mind_candy" ? (
            <StandardMindCandy mcData={mcData} key={i} />
          ) : (
            <ClickToRevealMindCandy mcData={mcData} key={i} />
          )
        })}
      </>
    )
  }
  return null
}

const StandardMindCandy = props => {
  const {
    id,
    mcImageUrl,
    mcImageAlt,
    title,
    mcAbstractText,
    mcLink,
    mcLinkTitle,
  } = props.mcData
  return (
    <div key={id}>
      <h2 className="tw-sr-only">{title}</h2>
      <div className="mt-3 mb-6 flex flex-col gap-5 lg:flex-row lg:items-center">
        {mcImageUrl && (
          <div>
            {mcImageUrl && (
              <img src={mcImageUrl} alt={mcImageAlt} className="rounded-md" />
            )}
          </div>
        )}
        <div>
          {mcAbstractText && (
            <div
              className="prose dark:prose-dark"
              dangerouslySetInnerHTML={createMarkup(mcAbstractText)}
            />
          )}
          {mcLink && (
            <Button
              url={mcLink}
              additionalAttr={{ "data-context": "mind candy" }}
              additionalClasses={["mt-2", "lg:max-w-xl"]}
            >
              {mcLinkTitle}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

const ClickToRevealMindCandy = props => {
  const [toggleReveal, setToggleReveal] = useState(false)
  const [animate, setAnimate] = useState(false) // For tracking animation state
  const {
    id,
    title,
    mcAbstractText,
    mcLink,
    mcLinkTitle,
    revealedText,
    mcTheme,
  } = props.mcData
  return (
    <div key={id}>
      <h2 className="tw-sr-only">{title}</h2>
      <div className="mt-3 mb-6 flex flex-col gap-5 lg:flex-row lg:items-center">
        <div className="z-2 overflow-hidden rounded-xl bg-cover lg:w-1/2">
          {" "}
          <div className={`mindcandy p-5 mindcandy--${mcTheme}`}>
            {mcAbstractText && (
              <div
                className="prose max-w-none dark:prose-dark"
                dangerouslySetInnerHTML={createMarkup(mcAbstractText)}
              />
            )}
            {(revealedText || mcLink) && (
              <button
                data-type="toggle"
                data-context="mind candy reveal"
                data-action={toggleReveal ? "hide" : "show"}
                className="mt-3 h-auto w-full whitespace-nowrap rounded bg-opacity-10 p-2 font-semibold transition dark:bg-opacity-100"
                onClick={() => {
                  setToggleReveal(!toggleReveal)
                  setAnimate(!animate)
                }}
              >
                <span className="pointer-events-none flex items-center justify-center gap-3">
                  <span className="text-lg text-secondary">
                    <FontAwesomeIcon
                      icon={toggleReveal ? faGrinTongue : faGrin}
                      className="inline-block"
                    />
                  </span>
                  {toggleReveal === false ? "Reveal Answer" : "Conceal Answer"}
                </span>
              </button>
            )}
          </div>
        </div>
        <div
          className={
            (toggleReveal === true
              ? "visible animate-mc-fade-in-down lg:animate-mc-fade-in-right"
              : animate
              ? "animate-mc-fade-out-up lg:animate-mc-fade-out-left"
              : "invisible") +
            " z-1 overflow-hidden rounded-xl bg-cover lg:w-1/2"
          }
          onAnimationEnd={e => {
            setAnimate(false)
          }}
        >
          <div className={`mindcandy-reveal p-5 mindcandy-reveal--${mcTheme}`}>
            {revealedText && (
              <div
                className="prose mb-3 max-w-none dark:prose-dark"
                dangerouslySetInnerHTML={createMarkup(revealedText)}
              />
            )}
            {mcLink && (
              <a
                data-type="call to action"
                data-context="mind candy reveal"
                href={mcLink}
                className="mindcandy--link mt-3 flex h-auto w-full items-center justify-center whitespace-nowrap rounded bg-opacity-10 p-2 font-semibold transition dark:bg-opacity-100"
              >
                {mcLinkTitle}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

StandardMindCandy.propTypes = {
  mcData: PropTypes.object,
}
ClickToRevealMindCandy.propTypes = {
  mcData: PropTypes.object,
}

export default MindCandy
