function filterChange(target) {
  if (typeof window !== "undefined" && window.dataLayer) {
    window.dataLayer.push({
      event: "filterChange",
      "gtm.element": target,
      "gtm.element.dataset.action": target.dataset.action,
      "gtm.element.dataset.context": target.dataset.context,
      "gtm.element.value": target.value || target.innerText,
    })
  }
}

export default filterChange
