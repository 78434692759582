import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/page-layout/layout"
import AwsRum from "../components/page-layout/aws-rum"
import Seo from "../components/page-layout/seo"
import AnnouncementsSection from "../components/sections/announcements-section"
import HiddenSection from "../components/sections/hidden-section"
import Section from "../components/sections/section"
import {
  CalendarModalProvider,
  CalendarModal,
} from "../components/calendar-modal/calendar-modal"
import {
  SettingsProvider,
  SettingsModal,
  SettingsModalContext,
} from "../components/page-layout/nav/settings"
import { UserProvider } from "../components/utils/UserContext"
import { HelpModal, HelpProvider } from "../components/page-layout/ui/help"
import { MenusProvider } from "../components/utils/MenusContext"

const IndexPage = () => {
  const [hasMounted, setHasMounted] = useState(false)
  const data = useStaticQuery(graphql`
    query ContentSections {
      allNodeContentSection(sort: { fields: field_weight, order: ASC }) {
        edges {
          node {
            id
            drupal_id
            title
            field_subtitle
            field_weight
            field_disable_hide_show
            field_section_type
            relationships {
              field_content {
                ... on paragraph__html_block {
                  id
                  drupal_id
                  internal {
                    type
                  }
                  field_html_text {
                    format
                    processed
                    value
                  }
                }
                ... on paragraph__html_header {
                  id
                  drupal_id
                  internal {
                    type
                  }
                  field_header_text {
                    format
                    processed
                    value
                  }
                  field_header_level
                }
                ... on paragraph__link_listing {
                  id
                  drupal_id
                  internal {
                    type
                  }
                  field_links {
                    title
                    uri
                  }
                }
              }
              field_secondary_content {
                ... on paragraph__html_header {
                  id
                  drupal_id
                  internal {
                    type
                  }
                  field_header_text {
                    format
                    processed
                    value
                  }
                  field_header_level
                }
                ... on paragraph__html_block {
                  id
                  drupal_id
                  internal {
                    type
                  }
                  field_html_text {
                    processed
                  }
                }
                ... on paragraph__link_listing {
                  id
                  drupal_id
                  internal {
                    type
                  }
                  field_links {
                    title
                    uri
                  }
                }
              }
            }
          }
        }
      }
      allNodeAnnouncementSection {
        edges {
          node {
            id
            drupal_id
            title
            relationships {
              field_feed_sources {
                ... on paragraph__calendar_feed {
                  id
                  drupal_id
                  internal {
                    type
                    content
                  }
                  relationships {
                    field_google_calendar {
                      field_calendar_id
                    }
                  }
                }
              }
            }
          }
        }
      }
      allNodeMindCandy {
        edges {
          node {
            id
          }
        }
      }
    }
  `)
  useEffect(() => {
    setHasMounted(true)
  }, [])
  if (!hasMounted) {
    return null
  }
  const sectionContent = data.allNodeContentSection.edges.filter(element => {
    if (data.allNodeMindCandy.edges.length !== 0) {
      return true
    } else if (element.node.field_section_type !== "mind_candy") {
      return true
    } else {
      return false
    }
  })

  return (
    <CalendarModalProvider>
      <UserProvider>
        <SettingsProvider>
          <HelpProvider>
            <MenusProvider>
              <Layout>
                <AwsRum />
                <Seo title="The Dash | Swarthmore College Dashboard" />

                {/* Render the Announcements Sections */}
                {data.allNodeAnnouncementSection.edges.map(
                  ({ node }, index) => {
                    return (
                      <AnnouncementsSection
                        nodeData={node}
                        key={"AnnouncementsSection_" + index}
                      />
                    )
                  }
                )}
                {/* Render the Content Sections */}
                <SortableSections sortableSections={sectionContent} />
                {/* Modal placement */}
                <SettingsModal />
                <CalendarModal />
                <HelpModal />
              </Layout>
            </MenusProvider>
          </HelpProvider>
        </SettingsProvider>
      </UserProvider>
    </CalendarModalProvider>
  )
}

const SortableSections = ({ sortableSections }) => {
  const [settingsModalState, settingsModalDispatch] = React.useContext(
    SettingsModalContext
  )
  const { contentSort } = settingsModalState

  //Apply any custom sort order to the sections
  const sortedSections = sortableSections
    .map(({ node }) => {
      let hidden = 0
      let weight = contentSort.findIndex(s => s.drupal_id === node.drupal_id)
      if (weight !== -1) {
        hidden =
          contentSort.find(s => s.drupal_id === node.drupal_id)?.hidden || 0
      } else {
        weight = node.field_weight
      }
      return {
        ...node,
        weight: weight,
        hidden: hidden,
      }
    })
    .sort((a, b) => {
      if (a.hidden === b.hidden) {
        return a.weight - b.weight
      }
      //puts hidden items at the bottom
      return a.hidden - b.hidden
    })

  return (
    <>
      {sortedSections
        .filter(e => !e.hidden)
        .map((node, index) => {
          return <Section nodeData={node} key={index} />
        })}
      {sortedSections
        .filter(e => e.hidden)
        .map((node, index) => {
          return <HiddenSection nodeData={node} key={index} />
        })}
    </>
  )
}
export default IndexPage
