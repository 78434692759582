import React, { useState } from "react"
import PropTypes from "prop-types"
import FocusTrap from "focus-trap-react"

import DirectorySearch from "./search-directory"
import MoreSearchOptions from "./search-more"

import PillToggle from "../ui/pill-toggle"

const Search = ({ pageTitle, mobileSearchOpen, midDesktopSearch }) => {
  const searches = [
    {
      id: "college-website",
      name: "College Website",
      placeholder: "Search Swarthmore.edu",
    },
    {
      id: "directory",
      name: "Directory",
      placeholder: "Search the Directory",
    },
    {
      id: "tripod",
      name: "Tripod",
      placeholder: "Search Tripod",
    },
  ]

  const initialActive = searches[0]
  const [active, setActive] = useState(initialActive)
  const [query, setQuery] = useState("")
  const [isTrapActive, setIsTrapActive] = useState(false)
  const [isDirectoryTrapActive, setIsDirectoryTrapActive] = useState(false)

  const toggleSearch = (e, search) => {
    const targetId = e.target.dataset.searchId

    if (targetId !== "directory") {
      setIsTrapActive(true)
    } else {
      setIsDirectoryTrapActive(true)
    }

    if (active.id !== targetId) {
      setActive(search)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    switch (active.id) {
      case "college-website":
        if (typeof window !== "undefined") {
          window.open(
            `https://swarthmore.edu/search?keys=${encodeURIComponent(
              query
            ).replace(/%20/g, "+")}`,
            "_blank"
          )
        }
        break
      case "tripod":
        if (typeof window !== "undefined") {
          window.open(
            `https://tricolib.primo.exlibrisgroup.com/discovery/search?vid=01TRI_INST:SC&offset=0&tab=Everything&search_scope=SC_All&query=any,contains,${encodeURIComponent(
              query
            ).replace(/%20/g, "+")}`,
            "_blank"
          )
        }
        break
      default:
        throw new Error("Search options not valid.")
    }
  }

  return (
    <section
      className={
        "lg:block " +
        (midDesktopSearch ? "md:block " : "md:hidden ") +
        (!mobileSearchOpen ? "hidden" : "")
      }
    >
      <h1 className="tw-sr-only">Search Options</h1>
      <div className="flex items-center justify-between gap-1 sm:justify-evenly md:justify-between">
        {searches.map(search => {
          return (
            <PillToggle
              isActive={active.id === search.id}
              onClick={e => toggleSearch(e, search)}
              additionalAttr={{
                "data-search-id": search.id,
                "data-type": "toggle",
                "data-context": "search options",
                "data-action": "select search",
              }}
              key={search.id}
            >
              {search.name}
            </PillToggle>
          )
        })}
        <MoreSearchOptions mobileSearchOpen={mobileSearchOpen} />
      </div>
      {active.id === "directory" ? (
        <DirectorySearch
          query={query}
          setQuery={setQuery}
          isDirectoryTrapActive={isDirectoryTrapActive}
          setIsDirectoryTrapActive={setIsDirectoryTrapActive}
        />
      ) : (
        <FocusTrap
          active={isTrapActive}
          focusTrapOptions={{
            allowOutsideClick: () => setIsTrapActive(false),
            escapeDeactivates: () => setIsTrapActive(false),
          }}
        >
          <form role="search" onSubmit={handleSubmit}>
            <label className="block">
              <span className="tw-sr-only text-gray-700">
                {active.placeholder}
              </span>
              <input
                type="search"
                className="mt-2 block w-full rounded-md border-gray-300 text-xs shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:border-none dark:bg-gray-700 dark:placeholder-gray-100"
                placeholder={active.placeholder}
                onChange={e => setQuery(e.target.value)}
                value={query}
              />
            </label>
          </form>
        </FocusTrap>
      )}
    </section>
  )
}

Search.propTypes = {
  pageTitle: PropTypes.string,
}

Search.defaultProps = {
  pageTitle: ``,
}

export default Search
