import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import IconLink from "../icon-link"

import ChevronToggle from "../../ui/chevron-toggle"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTh } from "@fortawesome/free-solid-svg-icons"

const ServicesMenu = ({ navbarCollapsed, setMobileNavOpen }) => {
  const [servicesMenuToggle, setServicesMenuToggle] = useState(true)
  const servicesVisibility = !servicesMenuToggle ? "tw-sr-only" : ""

  const data = useStaticQuery(graphql`
    query ServicesMenuItems {
      allMenuItems(filter: { menu_name: { eq: "services" } }) {
        edges {
          node {
            id
            url
            title
            description
            options {
              external
              attributes {
                icon
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="my-3" id="services-menu">
      <nav aria-labelledby="services-heading">
        <h2 id="services-heading" className="mb-1 py-1">
          <button
            aria-expanded={servicesMenuToggle}
            data-type="toggle"
            data-context="services menu"
            data-action={servicesMenuToggle ? "collapse" : "expand"}
            data-label="List of Services"
            className={`${
              navbarCollapsed ? "ml-3.5 md:ml-2.5" : "ml-3.5"
            } text-sm font-bold uppercase tracking-wider text-gray-600 dark:text-gray-300`}
            type="button"
            onClick={() => setServicesMenuToggle(!servicesMenuToggle)}
          >
            <span className="pointer-events-none flex items-center">
              <span className={navbarCollapsed ? "md:hidden" : ""}>
                <FontAwesomeIcon
                  icon={faTh}
                  className="mr-2 inline-block text-lg"
                />
              </span>
              <span className="tw-sr-only">List of </span>
              <span className={navbarCollapsed ? "md:text-xs" : ""}>
                Services
              </span>
              <span className={navbarCollapsed ? "md:hidden" : ""}>
                <ChevronToggle
                  chevronClasses={[
                    "inline-block",
                    "ml-1",
                    "text-gray-500",
                    "dark:text-gray-300",
                  ]}
                  toggle={servicesMenuToggle}
                />
              </span>
            </span>
          </button>
        </h2>
        <ul className={`text-sm ${servicesVisibility}`}>
          {data.allMenuItems.edges.map(({ node }, index) => {
            return (
              <li
                className="group py-1 transition hover:bg-gray-300 dark:hover:text-black"
                key={index}
              >
                <IconLink
                  linkData={node}
                  navbarCollapsed={navbarCollapsed}
                  setMobileNavOpen={setMobileNavOpen}
                />
              </li>
            )
          })}
        </ul>
      </nav>
    </div>
  )
}

export default ServicesMenu
