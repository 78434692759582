import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import NavigationUtil from "../utils/navigationUtil"
import getWindowWidth from "../utils/getWindowWidth"
import Footer from "./footer"
import MobileNavBar from "./nav/mobile-navbar"
import NavBar from "./nav/navbar"
import Search from "./search/search"
import { SettingsModalContext } from "./nav/settings"
import { TitleBar } from "./header/title-bar"
import CriticalAnnouncementFeed from "./header/critical-announcement"
// TODO: Replace hardcoded Help button content with Drupal provided content type
// import { HelpButton } from "./ui/help"
import ToggleCollapseBtn from "../buttons/nav-collapse-toggle"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons"

const Layout = ({ children }) => {
  const [settingsModalState, settingsModalDispatch] = useContext(
    SettingsModalContext
  )
  const { darkMode, show: settingsModalShown } = settingsModalState
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [navbarCollapsed, setNavbarCollapsed] = useState(false)
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false)
  const [midDesktopSearch, setmidDesktopSearch] = useState(false)
  const [showAnnouncementsLink, setShowAnnouncementsLink] = useState(false)
  const [activeAnnouncements, setActiveAnnouncements] = useState([])
  const [criticalAnnouncementOffset, setCriticalAnnouncementOffset] = useState(
    0
  )
  const darkClass = darkMode ? " dark" : ""
  const [showHeaderShadow, setShowHeaderShadow] = React.useState(null)

  const setScroll = () => {
    setShowHeaderShadow(window.scrollY > 20)
  }

  React.useEffect(() => {
    window.addEventListener("scroll", setScroll)
    return () => {
      window.removeEventListener("scroll", setScroll)
    }
  }, [])

  const childrenWithProps = React.Children.map(children, (child, i) => {
    const match = "AnnouncementsSection_"
    if (child.key && child.key.substr(0, match.length) === match) {
      return React.cloneElement(child, {
        showAnnouncementsLink,
        setShowAnnouncementsLink,
        activeAnnouncements,
        setActiveAnnouncements,
      })
    } else {
      return child
    }
  })

  // Updates <head> with darkmode dependent settings
  const updateHead = options => {
    const { mobileNavOpen, darkClass, settingsModalShown } = options
    let classes = darkClass
    classes += mobileNavOpen ? " md-max:overflow-hidden" : ""
    classes += settingsModalShown ? " overflow-hidden" : ""

    return (
      <Helmet
        bodyAttributes={{
          class: classes,
        }}
        meta={[
          {
            name: "theme-color",
            content: darkClass ? "#111827" : "#b43135",
          },
          {
            name: "background-color",
            content: darkClass ? "#111827" : "#b43135",
          },
        ]}
      />
    )
  }

  // Open mobile nav menu when window is within mobile width
  const handlePrimaryNavSkip = () => {
    if (typeof window !== "undefined") {
      if (!mobileNavOpen && getWindowWidth() < 768) {
        setMobileNavOpen(true)
      }
      window.location.href = "/#navbar"
    }
  }

  return (
    <div className="w-full font-sans antialiased">
      {updateHead({ mobileNavOpen, darkClass, settingsModalShown })}
      {mobileNavOpen ? (
        <>
          <div
            className="u-backdrop-filter md-max:fixed md-max:z-40 md-max:h-screen md-max:w-full"
            role="presentation"
          ></div>
          <NavigationUtil
            closeAction={() => setMobileNavOpen(false)}
            menuSelector="#navbar"
            excludeElement="#mobile-navigation-toggle"
          />
        </>
      ) : null}
      <a
        className="absolute left-1/2 z-999 h-auto -translate-y-full bg-white p-5 text-black duration-75 focus:translate-y-0 dark:bg-black dark:text-white"
        href="#main"
      >
        Skip to content
      </a>
      <button
        className="absolute left-1/2 z-999 h-auto -translate-y-full bg-white p-5 text-black duration-75 focus:translate-y-0 dark:bg-black dark:text-white"
        onClick={handlePrimaryNavSkip}
      >
        Skip to primary navigation
      </button>
      <MobileNavBar
        setMobileNavOpen={setMobileNavOpen}
        mobileNavOpen={mobileNavOpen}
        setMobileSearchOpen={setMobileSearchOpen}
        mobileSearchOpen={mobileSearchOpen}
        criticalAnnouncementOffset={criticalAnnouncementOffset}
        setCriticalAnnouncementOffset={setCriticalAnnouncementOffset}
      />
      <div
        className={`dark:bg-gray-900 ${
          navbarCollapsed ? "md:pl-20" : "md:pl-64"
        }`}
      >
        <header>
          <ToggleCollapseBtn
            handleClick={() => {
              setNavbarCollapsed(!navbarCollapsed)
            }}
            toggle={navbarCollapsed}
          />
          <NavBar
            mobileNavOpen={mobileNavOpen}
            setNavbarCollapsed={setNavbarCollapsed}
            navbarCollapsed={navbarCollapsed}
            setMobileNavOpen={setMobileNavOpen}
            showAnnouncementsLink={showAnnouncementsLink}
            criticalAnnouncementOffset={criticalAnnouncementOffset}
            setCriticalAnnouncementOffset={setCriticalAnnouncementOffset}
          />
        </header>
        <aside
          aria-labelledby="additional-info-and-tools-title"
          className={`top-mobile-nav-offset z-10 md:top-0 transition
            ${mobileNavOpen ? "md:sticky " : "sticky "}
            ${
              showHeaderShadow
                ? "md:dark:shadow-none md:shadow-[0_12px_15px_-10px_rgba(0,0,0,0.25)]"
                : "shadow-none"
            }
            `}
        >
          <h1 id="additional-info-and-tools-title" className="tw-sr-only">
            Additional Information and Search Tools
          </h1>
          <CriticalAnnouncementFeed
            location="desktop"
            criticalAnnouncementOffset={criticalAnnouncementOffset}
            setCriticalAnnouncementOffset={setCriticalAnnouncementOffset}
          />
          <div
            className={`md-max:dark:border-none dark:border-gray-700 dark:bg-gray-800 dark:border-b w-full bg-primary-light py-2 px-3 dark:text-white md:h-28 md:bg-white md:py-0 md:px-6 md:dark:bg-gray-900 ${
              showHeaderShadow
                ? `${!darkMode ? "border-none" : ""}`
                : "md:border-b md:border-gray-200 md:dark:border-gray-700 "
            }
              ${mobileSearchOpen ? "xs-max:px-1 " : ""}
              ${
                midDesktopSearch
                  ? "flex flex-row items-center md:block lg:flex"
                  : "flex flex-row items-center"
              }
           `}
          >
            <TitleBar
              showAnnouncementsLink={showAnnouncementsLink}
              mobileSearchOpen={mobileSearchOpen}
              midDesktopSearch={midDesktopSearch}
            />
            <div
              className={
                "flex-none lg:w-96 " +
                (mobileSearchOpen ? "md-max:w-full md-max:px-2 " : "")
              }
            >
              {/* Sets a search icon for the middle breakpoint so as to avoid title text bumping into search bar */}
              <div
                className={
                  midDesktopSearch
                    ? "flex items-center justify-end md:py-5"
                    : ""
                }
              >
                <div className="w-full">
                  <Search
                    mobileSearchOpen={mobileSearchOpen}
                    midDesktopSearch={midDesktopSearch}
                  />
                </div>

                <div className="hidden md:block lg:hidden">
                  <button
                    aria-label="Search options"
                    data-type="toggle"
                    data-context="mid-desktop search options"
                    data-action={midDesktopSearch ? "close" : "open"}
                    className={
                      "navbar-icon navbar-icon--circular h-full w-9 dark:bg-gray-900 dark:text-white dark:hover:bg-gray-600 " +
                      (midDesktopSearch ? "-mt-[0.2rem] ml-10" : "")
                    }
                    onClick={() => setmidDesktopSearch(!midDesktopSearch)}
                  >
                    <span className="pointer-events-none text-xl">
                      <FontAwesomeIcon
                        icon={midDesktopSearch ? faTimes : faSearch}
                        className="inline-block"
                      />
                      <span className="tw-sr-only">
                        {midDesktopSearch ? "Hide" : "Show"} Search Options
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </aside>
        <main id="main">
          <div className="mt-3 grid min-h-screen grid-cols-1 px-3 dark:bg-gray-900 dark:text-white md:px-6">
            {childrenWithProps}
          </div>
          {/* <HelpButton displayStyle="fixed-circle" /> */}
        </main>
        <Footer />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
