import React, { useState } from "react"

import DataProvider from "../utils/DataProvider"
import swatDatesUtil from "../utils/swatDatesUtil"
import ChevronToggle from "../page-layout/ui/chevron-toggle"
import Button from "../buttons/button"

// Icons
import TriangleIcon from "../icons/triangle-icon"
import Skeleton from "../page-layout/ui/skeleton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

const TriCoVanFeeds = () => {
  const [tricoShuttleToggle, setTricoShuttleToggle] = useState(true)
  const tricoShuttleVisibility = !tricoShuttleToggle ? "tw-sr-only" : ""

  // TODO: Implement a filter that only gets times for the current day
  const brynmawrToSwarthmore = {
    destination: "swarthmore",
    origin: "bryn mawr",
    currentOnly: true,
    maxResults: 5,
  }
  const swarthmoreToBrynmawr = {
    destination: "bryn mawr",
    origin: "swarthmore",
    currentOnly: true,
    maxResults: 5,
  }
  const swarthmoreToHaverford = {
    destination: "haverford",
    origin: "swarthmore",
    currentOnly: true,
    maxResults: 5,
  }
  const haverfordToSwarthmore = {
    destination: "swarthmore",
    origin: "haverford",
    currentOnly: true,
    maxResults: 5,
  }

  return (
    <div className="mb-6">
      <h2>
        <button
          data-type="toggle"
          data-context="transportation section"
          data-action={tricoShuttleToggle ? "collapse" : "expand"}
          data-label="Tri-College Van"
          className="mb-3 text-xl font-bold"
          type="button"
          onClick={() => setTricoShuttleToggle(!tricoShuttleToggle)}
        >
          <span className="pointer-events-none flex flex-row items-center justify-between">
            Tri-College Van
            <span className="text-base">
              <ChevronToggle
                chevronClasses={["ml-2"]}
                toggle={tricoShuttleToggle}
              />
            </span>
          </span>
        </button>
      </h2>
      <div className={tricoShuttleVisibility}>
        {/* TODO: add optional descriptive paragraph */}
        <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
          <TriCoVanRoute
            queryVariables={swarthmoreToBrynmawr}
            originName="Swarthmore"
            destinationName="Bryn Mawr"
          />
          <TriCoVanRoute
            queryVariables={brynmawrToSwarthmore}
            originName="Bryn Mawr"
            destinationName="Swarthmore"
          />
          <TriCoVanRoute
            queryVariables={swarthmoreToHaverford}
            originName="Swarthmore"
            destinationName="Haverford"
          />
          <TriCoVanRoute
            queryVariables={haverfordToSwarthmore}
            originName="Haverford"
            destinationName="Swarthmore"
          />
        </div>
        <div>
          <Button
            color="primary"
            url="https://www.brynmawr.edu/transportation/tri-co-van"
            additionalAttr={{
              "data-context": "tri-college van",
              target: "_blank",
              rel: "noreferrer",
            }}
            additionalClasses={["mt-3", "w-full", "lg:w-1/2"]}
          >
            Tri-College Van Information
          </Button>
        </div>
      </div>
    </div>
  )
}

const TriCoVanRoute = props => {
  const { queryVariables } = props

  return (
    <DataProvider queryName="tricoshuttle" queryVariables={queryVariables}>
      <TriCoVanRouteTimes {...props} />
    </DataProvider>
  )
}
const TriCoVanRouteTimes = props => {
  const { originName, destinationName, error, loading, data } = props
  const processedTricoShuttleTimes =
    !loading && !error
      ? data.result.data.map((processedTricoShuttleTimes, i) => {
          return (
            <li
              className={
                i === 0
                  ? "mb-2.5 flex items-center text-lg font-bold"
                  : "mb-2.5 ml-2.5"
              }
              key={i}
            >
              {i === 0 && (
                <TriangleIcon
                  iconClasses="mr-1"
                  textColor="text-gray-700 dark:text-gray-100"
                  width="5px"
                />
              )}
              <time dateTime={processedTricoShuttleTimes.datetime}>
                {swatDatesUtil(processedTricoShuttleTimes.time)}
              </time>
            </li>
          )
        })
      : ""
  if (!loading && !error && data.result.data.length > 0) {
    return (
      <div className="relative block overflow-hidden rounded-md border border-gray-100 dark:border-gray-800">
        <div className="bg-gray-100 dark:bg-gray-800">
          <h3 className="py-2 px-3 text-lg font-bold text-black dark:text-primary-light">
            {originName}
            <span className="mx-2 text-sm text-secondary dark:text-primary-light">
              <FontAwesomeIcon icon={faArrowRight} className="inline-block" />
              <span className="tw-sr-only">to</span>
            </span>
            {destinationName}
          </h3>
        </div>
        <div className="p-3">
          <ul>{processedTricoShuttleTimes}</ul>
        </div>
      </div>
    )
  } else if (!loading && !error && data.result.data.length < 0) {
    return ""
  }  else {
    return <Skeleton />
  }
}

export default TriCoVanFeeds
